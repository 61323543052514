import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { RootStore } from "../../../../core/stores/RootStore";
import { StoreService } from "../../../../core/service/StoreService";
import { IDropdownOption } from "office-ui-fabric-react";
import { UserService } from "../../../../core/service/UserService";
import Cookies from "universal-cookie/es6/Cookies";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import { SqtReportService } from "../../../../core/service/sqtReportService";

export class HomeStore {
  public StoreService: StoreService = null;
  public UserService: UserService = null;
  public SqtReportService:SqtReportService=null;
  @observable showDialog: boolean = false;
  public adminPass: any;
  @observable offers: Array<any>;
  @observable columnDef: Array<any>;
  RootStore: RootStore = null;
  @observable errorMessage: any = null;

  constructor(rootStore: RootStore, type: string, categoryId: number, moduleId: number) {
    this.StoreService = new StoreService();
    this.UserService = new UserService();
    this.SqtReportService= new SqtReportService();
    this.RootStore = rootStore;

    this.RootStore.GoToHomeFn = () => {
      if (window.location.href.toLowerCase().indexOf("changelog") == -1) {
        console.log("nogo")
      }
      else {
        this.RootStore.GoToHome(1);
      }
    };

    this.columnDef = [

      { field: '', headerName: 'Műveletek', filter: 'agSetColumnFilter', width: 145, resizable: true, sortable: true,
      cellRenderer: 'btnCellRenderer',
      cellRendererParams: {
          clicked: (data: any) => {
              this.navigateToOffer(data.Id);
          }
      }
    },
      { field: 'OfferNumber', headerName: 'Ajánlatszám', filter: 'agSetColumnFilter', width: 145, resizable: true, sortable: true },
      { field: 'RogzitesDatumaString', headerName: 'Rögzítés dátuma', filter: 'agSetColumnFilter', width: 380, resizable: true, sortable: true },
      { field: 'HatralevoNapokSzama', headerName: 'Határidő', filter: 'agSetColumnFilter', resizable: true, sortable: true },
      { field: 'OperatorAzonositoja', headerName: 'Operátor', filter: 'agSetColumnFilter', resizable: true, sortable: true },
      { field: 'SzaktanacsadoKodja', headerName: 'Ügyintéző', filter: 'agSetColumnFilter', resizable: true, sortable: true },
      { field: 'TeljesNev', headerName: 'Ügyfél', filter: 'agSetColumnFilter', resizable: true, sortable: true },
      { field: 'StatusString', headerName: 'Státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true },

    ]
    
  }
  navigateToOffer(Id: number) {
    //this.navigate(`/invoice-details/${Id}?t=${InvoiceType.H601}`);
    this.RootStore.RouterStore.gotToRoute("SqtReportDetails",{id:Id});
}

  adminLogin = flow(function* (this: HomeStore) {

    let result = yield this.UserService.adminLogin(this.adminPass);

    if (result) {
      this.RootStore.RouterStore.IsAdmin = true;

    }
    this.showDialog = false;

    //this.RootStore.IsLoading = false;
  });

  Init = flow(function* (this: HomeStore) {
    if (!this.RootStore.SelectedStore) {
      // this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
    } else {
      let name = "isAdmin";
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
      if (results && results.length > 1) {
        if (results[2] == 'true') {
          this.showDialog = true;
        }
      }

      if(this.RootStore.TelesalesMode){
        this.offers= yield this.SqtReportService.GetOffers();
      }
    }

    //this.RootStore.IsLoading = false;
  });
}
