import * as React from "react";
import "./SqtSwitch.scss";
import { observer, inject } from "mobx-react";
import { SqtSwitchStore } from "../core/stores/SqtSwitchStore";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { Checkbox, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogType, Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { DayPickerText } from "../../../core/contstants/datePickerText";
import { ISqtItem } from "../../../core/models/ISqtItem";

interface ISqtSwitchProps {
  SqtSwitchStore?: SqtSwitchStore;
}

@inject("SqtSwitchStore")
@observer
export class SqtSwitch extends React.Component<ISqtSwitchProps, {}> {
  private transferSalesSrc = String(require("../assets/time-is-money.png"));
  private transferSalesSrc2 = String(require("../assets/subscription-fee.png"));
  constructor(props: ISqtSwitchProps) {
    super(props);
  }

  public render() {
    if (this.props.SqtSwitchStore.RootStore.loadingCount > 0) {
      return <LoaderOverlay />;
    }

    return (
      <div className="SqtSwitchComponent">
        <div className="mainHeader">Garancia Plusz választó </div>
        <div className="darkerBcground">
          {/* Felhasználó */}
          {this.renderDialogs()}
          {this.props.SqtSwitchStore.RootStore.renderHeader()}
          <div className='mainPanel'>
            {this.renderQueryButton()}
          </div>
        </div>

      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.SqtSwitchStore.CodeInput) {
      this.props.SqtSwitchStore.CodeInput.focus();
    }
  }

  public renderDialogs() {
    return (<div>
      {/* <Dialog
        hidden={!this.props.SqtSwitchStore.showWelcomeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Állandó, beégetett információ",
          showCloseButton: false
        }}
      >
        <div style={{ maxWidth: '400px' }}>A bejelentkezést követően a biztosítási ajánlat összeállításának felülete megjelenik, de a használat engedélyezése előtt egy felugró ablakban állandó, beégetett információ jelenik meg a felhasználók számára. Ennek jóváhagyása esetén válik elérhetővé használatra az összeállítás felülete.</div>

        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Jóváhagyás"
            onClick={() => this.props.SqtSwitchStore.hideDialog('showWelcomeDialog')}
          />
        </div>
      </Dialog> */}
      <Dialog
        hidden={!this.props.SqtSwitchStore.showDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Dokumentumok",
          showCloseButton: false
        }}
      >
        <div style={{ marginBottom: '10px', fontSize: '18px', fontWeight: 'bold' }}>
          Árlista
        </div>
        <div style={{ cursor: 'pointer', textDecoration: 'underline', color: 'red' }} onClick={() => this.props.SqtSwitchStore.GetUploadedDocumentByType(1)}>
          Havidíjas Garancia Plusz biztosítások
        </div>
        <div style={{ marginTop: '30px', marginBottom: '10px', fontSize: '18px', fontWeight: 'bold' }}>
          Előzetes dokumentumok
        </div>
        <div style={{ marginBottom: '10px', color: 'green', fontWeight: 'bold', width: '400px' }}>
          Rögzítés előtt nyomtasd ki és add át a választott havidíjas G+ csomag
          (Gold vagy Platina) előzetes dokumentumát!

        </div>
        <div style={{ cursor: 'pointer', textDecoration: 'underline', color: 'red' }} onClick={() => this.props.SqtSwitchStore.GetUploadedDocumentByType(2)}>
          GOLD Smart Garancia Plusz előzetes dokumentumok
        </div>
        <div style={{ cursor: 'pointer', textDecoration: 'underline', color: 'red' }} onClick={() => this.props.SqtSwitchStore.GetUploadedDocumentByType(3)}>
          PLATINA Smart Garancia Plusz előzetes dokumentumok
        </div>
        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            disabled={!this.props.SqtSwitchStore.downlodedDocument}
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Havidíjas G+ SMART rögzítése"
            onClick={() => {
              window.open("https://www.squaretrade.com/frontend/psb/#/mmhu");
              // this.props.SqtSwitchStore.onValueChanged('Vezeteknev', '', this.props.SqtSwitchStore.sqtData);
              // this.props.SqtSwitchStore.onValueChanged('Keresztnev', '', this.props.SqtSwitchStore.sqtData);
              // this.props.SqtSwitchStore.onValueChanged('TeljesNev', '', this.props.SqtSwitchStore.sqtData);
              // this.props.SqtSwitchStore.hideDialog('showMaganSzemelyDialog')
            }}
          />

          <DefaultButton
            className="ui button"
            text="Mégse"
            onClick={() => this.props.SqtSwitchStore.showDialog = false}
          />
        </div>
      </Dialog>
      <Dialog
        hidden={!this.props.SqtSwitchStore.showAdminDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Admin",
          showCloseButton: false
        }}
      >
        <TextField
          label="Jelszó"
          value={this.props.SqtSwitchStore.adminPass}

          onChange={(ev, value) => {
            this.props.SqtSwitchStore.adminPass = value;
          }}
        />

        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Belépés"
            onClick={() => this.props.SqtSwitchStore.adminLogin()}
          />
        </div>
      </Dialog>

    </div>)
  }

  public renderOriginalQueryTable() {
    const entries = Object.keys(this.props.SqtSwitchStore.sqtData.OriginalQueryResult[0]);

    return (<table style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>
      <tr>{entries.map(s => <th style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s}</th>)}</tr>
      {this.props.SqtSwitchStore.sqtData.OriginalQueryResult.map(s => <tr>{entries.map(z => <td style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s[z]}</td>)}</tr>)}
    </table>)

  }

  public renderQueryButton() {
    let debug = process.env.MODE;
    return (<div className='container'>
      <div className='mainContainer'>

        <div>
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <div className={"tile"} style={{ background: 'rgb(219 219 219)' }} onClick={() => { this.props.SqtSwitchStore.RootStore.GoToHome(1) }} >
              <div className="titleText" style={{ fontWeight: 'bold', color: 'black' }}>
                Egyszeri díjas Garancia Plusz biztosítás
              </div>
              <img style={{ width: '70px', marginTop: '10px' }} src={this.transferSalesSrc} alt="" />
            </div>
            <div className={"tile"} style={{ background: 'rgb(219 219 219)' }} onClick={() => { this.props.SqtSwitchStore.showDialog = true }} >
              <div className="titleText" style={{ fontWeight: 'bold', color: 'black' }}>
                Havi díjas Garancia Plusz biztosítás
              </div>
              <img src={this.transferSalesSrc2} style={{ width: '96px', marginTop: '-6px' }} alt="" />
            </div>
          </div>
        </div>
        {/* <div style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '20px' }} onClick={() => this.props.SqtSwitchStore.RootStore.GoToHome(1)}>
          Egyszeri díjas Garancia Plusz biztosítás
        </div>
        <div style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '20px' }} onClick={() => this.props.SqtSwitchStore.showDialog = true}>
          Havi díjas Garancia Plusz biztosítás

        </div> */}
        {/* <div>
        árlista a havidíjas Garancia Plusz biztosításokról
        </div>
        <div>
        GOLD Smart Garancia Plusz előzetes dokumentumok
        </div>
        <div>
        PLATINA Smart Garancia Plusz előzetes dokumentumok
        </div> */}
      </div>


    </div>)
  }

  public renderSqtSwitch() {
    return (<div className='container'>
      <div className='mainContainer'>


        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtSwitchStore.onClosePanelClicked("AllandoLakcim")}>
            Biztosítandó termék adatai
          </div>

          <div className="panelContainer">
            <div className="row">
              <div className="inputContainer half inline">
                <TextField
                  label="Cikkszám"
                  value={this.props.SqtSwitchStore.sqtData.ArticleNumber}
                  disabled={true}
                  type="number"
                  onChange={(ev, value) => {
                    this.props.SqtSwitchStore.onValueChanged('ArticleNumber', value, this.props.SqtSwitchStore.sqtData);
                  }}
                />
              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Gyártó, típus"
                  value={this.props.SqtSwitchStore.sqtData.ManufacturerAndName}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtSwitchStore.onValueChanged('ManufacturerAndName', value, this.props.SqtSwitchStore.sqtData);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputContainer half inline">
                <TextField
                  label="Áruházi készlet"
                  value={this.props.SqtSwitchStore.sqtData.Quantity}
                  disabled={true}
                  type="number"
                  onChange={(ev, value) => {
                    this.props.SqtSwitchStore.onValueChanged('Quantity', value, this.props.SqtSwitchStore.sqtData);
                  }}
                />
              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Árucsoport"
                  value={this.props.SqtSwitchStore.sqtData.ProductGroupNameAndId}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtSwitchStore.onValueChanged('ProductGroupNameAndId', value, this.props.SqtSwitchStore.sqtData);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputContainer half inline">
                <table style={{ width: '35%' }}>
                  <tr>
                    <td>
                      <TextField
                        label="Vételár"
                        value={this.props.SqtSwitchStore.sqtData.GrossSalesPrice}
                        disabled={false}
                        type="number"
                        onChange={(ev, value) => {
                          this.props.SqtSwitchStore.onValueChanged('GrossSalesPrice', value, this.props.SqtSwitchStore.sqtData);
                        }}
                      />
                    </td>
                    <td style={{ paddingTop: '31px', fontSize: '14px' }}>
                      Ft
                    </td>
                  </tr>
                </table>

              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Jótállás tartama"
                  value={this.props.SqtSwitchStore.sqtData.GuaranteeTimeString}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtSwitchStore.onValueChanged('GuaranteeTimeString', value, this.props.SqtSwitchStore.sqtData);
                  }}
                />
              </div>
            </div>


            <div className='panel'>
              <div className='title' style={{ background: '#a51716' }} >
                Ajánlattételi táblázat
              </div>
              {this.props.SqtSwitchStore.isMobile() &&
                <div style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                  {this.props.SqtSwitchStore.sqtData.Insurances.map(s =>
                    <div style={{ border: '1px solid #a51716', marginTop: '10px' }}>
                      <div style={{ background: '#a51716', color: 'white', textAlign: 'center', padding: '4px' }}>
                        {s.Name}
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Csomag ára"
                          value={s.PriceString}
                          disabled={true}
                          type="text"

                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Jótállás tartama"
                          value={s.GuaranteeTimeString}
                          disabled={true}
                          type="text"

                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Műszaki meghibásodás"
                          value={s.TechnicalFailureString}
                          disabled={true}
                          type="text"

                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Baleseti meghibásodás"
                          value={s.AccidentalFailureString}
                          disabled={true}
                          type="text"

                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Lopás/rablás"
                          value={s.StoleString}
                          disabled={true}
                          type="text"

                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Cikkszám"
                          value={s.ArticleNumber}
                          disabled={true}
                          type="text"

                        />
                      </div>
                    </div>)}
                </div>



              }
              {!this.props.SqtSwitchStore.isMobile() &&
                <table style={{ border: '1px solid grey', borderCollapse: 'collapse', width: '97%', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>
                  <tr>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Garanciacsomag neve
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Csomag ára
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Jótállás tartama
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Műszaki meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Baleseti meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Lopás/rablás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Cikkszám
                    </th>
                  </tr>
                  {this.props.SqtSwitchStore.sqtData.Insurances.map(s =>
                    <tr>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.Name}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.PriceString}

                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.GuaranteeTimeString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.TechnicalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.AccidentalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.StoleString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.ArticleNumber}
                      </td>
                    </tr>

                  )}
                </table>}



            </div>

          </div>
        </div>


      </div>

      {(this.props.SqtSwitchStore.isSubmitted && (this.props.SqtSwitchStore.errors.length > 0)) && <MessageBar className="errorMessageBar" messageBarType={MessageBarType.error}>
        <ul>
          {this.props.SqtSwitchStore.errors.map(s => <li className="errorLi" key={s.key}>{s.value}</li>)}
        </ul>
      </MessageBar>}

      <div className='mainButtons'>
        <PrimaryButton
          className="ui button"
          style={{ backgroundColor: 'grey' }}
          text='Vissza a főoldalra'
          onClick={() => this.props.SqtSwitchStore.RootStore.GoToHome(1)} />
        <PrimaryButton
          className="ui button"
          text='Új cikkszám keresése'
          onClick={() => this.props.SqtSwitchStore.newArticleNumber()} />
        <PrimaryButton
          className="ui button"
          text='Újra kalkulálás'
          onClick={() => this.props.SqtSwitchStore.onNewSubmit()} />
      </div>
    </div>)
  }

  public renderSqtItems(item: ISqtItem, index: number) {
    return (<div className='panel' key={index}>
      <div className='title' onClick={() => this.props.SqtSwitchStore.onClosePanelClicked(`BiztositottTermekAdatai-${index}`)}>
        Biztosított termék adatai ({item.Gyarto_Tipus})
      </div>
      {this.props.SqtSwitchStore.closedPanels.indexOf(`BiztositottTermekAdatai-${index}`) == -1 &&
        <div className="panelContainer">
          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Cikkszám"
                value={item.Cikkszam}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('Cikkszam', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Árucsoport"
                value={item.Arucsoport}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('Arucsoport', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Gyártó, típus"
                value={item.Gyarto_Tipus}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('Gyarto_Tipus', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label={item.IsMobile ? "IMEI szám" : "Gyártási szám"}
                value={item.GyartasiSzam}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('GyartasiSzam', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Vásárlás helye"
                value={item.VasarlasHelye}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('VasarlasHelye', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Vásárlás dátuma"
                value={new Date(item.VasarlasDatuma).toLocaleDateString('hu-HU')}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('VasarlasDatuma', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">

              <TextField
                label="Vételár"
                value={item.Vetelar ? `${item.Vetelar.toString()} Ft` : ''}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('Vetelar', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Gyári garancia tartama"
                value={`${item.GyartoiGaranciaTartama.toString()} hó`}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('GyartoiGaranciaTartama', value, item);
                }}
              />
              <Dropdown
                label="Gyári garancia tartama"
                selectedKey={item.GyartoiGaranciaTartama}
                options={this.props.SqtSwitchStore.guaranteeTimes
                  .filter(s => s.GuaranteePriceMaxHUF > item.Vetelar)
                  .map(s => { return { key: s.GuaranteeMonths, text: `${s.GuaranteeMonths} hó` } })}
                onChange={(ev, value) => {
                  this.props.SqtSwitchStore.onValueChanged('GyartoiGaranciaTartama', value, item);
                }}
              ></Dropdown>
            </div>
          </div>

          <div className='panel'>
            <div className='title' onClick={() => this.props.SqtSwitchStore.onClosePanelClicked(`BiztositottTermekAdatai-biztositas-${index}`)}>
              Biztosítás adatai ({item.Gyarto_Tipus})
            </div>
            {this.props.SqtSwitchStore.closedPanels.indexOf(`BiztositottTermekAdatai-biztositas-${index}`) == -1 &&
              <div className="panelContainer">
                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Fedezet (biztosítási csomag)"
                      value={item.Fedezet_BiztositasiCsomag}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtSwitchStore.onValueChanged('Fedezet_BiztositasiCsomag', value, item);
                      }}
                    />
                  </div>

                  <div className="inputContainer half inline">
                    <TextField
                      label="Ajánlatszám"
                      value={item.Ajanlatszam}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtSwitchStore.onValueChanged('Ajanlatszam', value, item);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Biztosítási díj"
                      value={item.BiztositasiDij ? `${item.BiztositasiDij.toString()} Ft` : ''}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtSwitchStore.onValueChanged('BiztositasiDij', value, item);
                      }}
                    />
                  </div>

                  <div className="inputContainer half inline">
                    <TextField
                      label="Biztosítási garancia tartama"
                      value={item.BiztositasiGaranciaTartama ? `${item.BiztositasiGaranciaTartama.toString()} hó` : ''}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtSwitchStore.onValueChanged('BiztositasiGaranciaTartama', value, item);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Szaktanácsadó kódja"
                      value={item.SzaktanacsadoKodja?.toString()}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtSwitchStore.onValueChanged('SzaktanacsadoKodja', value, item);
                      }}
                    />
                  </div>
                </div>
              </div>}
          </div>
        </div>}
    </div>)
  }
}
