import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { IDropdownOption } from "office-ui-fabric-react";
import Cookies from "universal-cookie/es6/Cookies";
import { UserService } from "../../../../core/service/UserService";
import { RootStore } from "../../../../core/stores/RootStore";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { AdminService } from "../../../../core/service/AdminService";


export class OfferTablesStore {
    public UserService: UserService = null;
    public AdminService: AdminService = null;

    RootStore: RootStore = null;
    @observable errorMessage: any = null;
    @observable file: any = null;
    @observable productGroups: Array<any>;
    @observable columnDef: Array<any>;
    @observable showUploadDialog: boolean = false;


    constructor(rootStore: RootStore) {
        this.UserService = new UserService();
        this.AdminService = new AdminService();
        this.RootStore = rootStore;

        this.RootStore.GoToHomeFn = () => {
            if (window.location.href.toLowerCase().indexOf("changelog") == -1) {
                console.log("nogo")
            }
            else {
                this.RootStore.GoToHome(1);
            }
        };


        this.columnDef = [

            { field: 'CategorySign', headerName: 'Biztosítási kat. jel', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'Silver', headerName: 'SILVER max', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'Gold', headerName: 'GOLD max', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'Platina', headerName: 'PLATINA max', filter: 'agSetColumnFilter', resizable: true, sortable: true },

        ]
    }
    getProductGroups = flow(function* (this: OfferTablesStore) {
    this.RootStore.addloading('getProductGroups')
       
       let temp = yield this.AdminService.GetOfferTables();
       let debug = 1;
       this.productGroups =temp;
    this.RootStore.removeLoading('getProductGroups')

        //this.RootStore.IsLoading = false;
    });
    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById('upload')).files[0];
        console.log('Kiválasztott file:', this.file)
    }
    showdialog() {
        this.showUploadDialog = true;
    }
    hideDialog() {
        this.showUploadDialog = false;
    }
    confirmUpload = flow(function* (this: OfferTablesStore) {
    this.RootStore.addloading('uploadProductGroups')
    let formData: FormData = new FormData();
        this.file = (document.getElementById('upload') as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        let retVAl: any = null;
        retVAl = yield this.AdminService.UploadOfferTables(formData);
        this.showUploadDialog = false;
        this.RootStore.removeLoading('uploadProductGroups')
        if(retVAl.result){
           this.getProductGroups();
       }
       else{
           alert('A betöltés sikertelen. Hibás Excel fájl!');
       }

        //this.RootStore.IsLoading = false;
    });
    GetAdminTablesExcel= flow(function* (this: OfferTablesStore) {
       
        this.RootStore.addloading('GetAdminTablesExcel')

        let file = yield this.AdminService.GetAdminTablesExcel(3);
    
        const fileName = `OfferTables-${+new Date()}.xlsx`;
        this.AdminService.downloadFile(file, fileName)
    
    
    
        this.RootStore.removeLoading('GetAdminTablesExcel')
    });
    Init = flow(function* (this: OfferTablesStore) {
        // if (!this.RootStore.SelectedStore) {
        //     this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
        // }

        //this.RootStore.IsLoading = false;
    });
}
