import { BaseService } from "./base.service";

export class OfferService extends BaseService {
 
    constructor() {
        super("Offer");
      }

 
  public async getOfferById(id: string): Promise<any>{
    return await this.get(`getOfferById?id=${id}`);
  }
//   public async GetOffers(): Promise<ISqtData>{
//     return await this.get(`GetOffers`);
//   }
//   public async GetofferDocuments(id:number): Promise<ISqtData>{
//     return await this.get(`GetofferDocuments?id=${id}`);
//   }
//   public async setInactive(sqtData: ISqtItem): Promise<any>{
//     return await this.post("setInactive", sqtData);
//   }
  
//   public async sendNextStep(sqtData: ISqtData): Promise<any>{
//     return await this.post("sendNextStep", sqtData);
//   }
  public async SaveofferDecline(sqtData: any): Promise<any>{
    return await this.postVoid("SaveofferDecline", sqtData);
  }
  public async SaveofferAccept(sqtData: any): Promise<any>{
    return await this.postVoid("SaveofferAccept", sqtData);
  }
//   public async GetArticleDataTelesales(code: string,vetalar:number): Promise<ISqtData>{
//     return await this.get(`GetArticleDataTelesales?code=${code}&vetalar=${vetalar}`);
//   }
//   public async GetOfferPdf(sqtData: ISqtData): Promise<any>{
//     const response: string = await this.post("GetOfferPdf", sqtData);

//     return this.base64ToArrayBuffer(response);
//   }
//   public async getIpidDocument(value:any): Promise<any>{
//     const response: string = await this.get("getIpidDocument?ipidType="+value);

//     return this.base64ToArrayBuffer(response);
//   }
  
//   public async GetOfferDocumentById(value:any): Promise<any>{
//     const response: string = await this.get("GetOfferDocumentById?id="+value);

//     return this.base64ToArrayBuffer(response);
//   }
//   public async GetETDocument(value:any): Promise<any>{
//     const response: string = await this.get("GetETDocument?type="+value);

//     return this.base64ToArrayBuffer(response);
//   }
//   public async GetASZFDocument(value:any): Promise<any>{
//     const response: string = await this.get("GetASZFDocument?type="+value);

//     return this.base64ToArrayBuffer(response);
//   }
  

  
 
}