
import { GPlusDocumentsPage } from "../../modules/Admin/GPlusDocuments/GPlusDocumentsPage";
import { InsurancePackagePage } from "../../modules/Admin/InsurancePackage/InsurancePackagePage";
import { InsurancepackageDeductiblePage } from "../../modules/Admin/InsurancePackageDeductible/InsurancePackageDeductiblePage";
import { LoginPage } from "../../modules/Admin/Login/LoginPage";
import { OfferTablesPage } from "../../modules/Admin/OfferTables/OfferTablesPage";
import { ProductGroupPage } from "../../modules/Admin/ProductGoups/ProductGroupPage";
import { CustomerPage } from "../../modules/Customer/CustomerPage";

import { HomePage } from "../../modules/Home/HomePage";
import { SqtOfferPage } from "../../modules/SqtOffer/SqtOfferPage";
import { SqtReportPage } from "../../modules/SqtReport/SqtReportPage";
import { SqtSwitchPage } from "../../modules/SqtSwitch/SqtSwitchPage";
import { IRoute, IRouteBase, IRouteObj } from "../models/IRoute";

export const RoutesObj = {
  Home: {
    id: "Home",
    path: "/Home/",
    pathUrl: "/Home",
    title: "",
    exact: true,
    mode: "Both",
    hiddenFromMenu: true,
    component: HomePage,
  },
  Customer: {
    id: "Customer",
    path: "/Customer/",
    pathUrl: "/Customer",
    title: "",
    exact: true,
    mode: "Both",
    hiddenFromMenu: true,
    component: CustomerPage,
  },
  SqtReport: {
    id: "SqtReport",
    path: "/SqtReport/",
    pathUrl: "/SqtReport",
    title: "SqT biztosítási ajánlat",
    exact: true,
    mode: "Both",
    component: SqtReportPage,
    hiddenFromMenu: true,
  },
  SqtReportDetails: {
    id: "SqtReportDetails",
    path: "/SqtReportDetails/:id",
    pathUrl: "/SqtReportDetails/:id",
    title: "SqT biztosítási ajánlat",
    exact: true,
    mode: "Both",
    component: SqtReportPage,
    hiddenFromMenu: true,
  },
  SqtOfferPage: {
    id: "SqtOfferPage",
    path: "/SqtOffer/",
    pathUrl: "/SqtOffer",
    title: "SqT ajánlat",
    exact: true,
    mode: "Both",
    component: SqtOfferPage,
    hiddenFromMenu: true,
  },
  SqtSwitchPage: {
    id: "SqtSwitchPage",
    path: "/SqtSelect/",
    pathUrl: "/SqtSelect",
    title: "Főoldal",
    exact: true,
    mode: "Both",
    component: SqtSwitchPage,
  },
  Report: {
    id: "Report",
    path: "/report/",
    pathUrl: "/report",
    title: "Riport",
    exact: true,
    mode: "Both",
    component: null,
  },
  Admin: {
    id: "Admin",
    path: "",
    pathUrl: "",
    title: "Adminisztráció",
    exact: true,
    mode: "Both",
    component: null,

    childrends:
      {
        Test: {
          id: "ProductGroup",
          path: "/productgroups/",
          pathUrl: "/productgroups",
          title: "Árucsoport - biztosítási kategória megfeleltetés",
          exact: true,
          mode: "Both",
           component: ProductGroupPage,
        },
        
        InsurancePackage: {
          id: "InsurancePackage",
          path: "/insurancepackages/",
          pathUrl: "/insurancepackages",
          title: "Biztosítási csomagok",
          exact: true,
          mode: "Both",
           component: InsurancePackagePage,
        },
        // InsurancePackageeductible: {
        //   id: "InsurancePackageeductible",
        //   path: "/insurancepackageseductible/",
        //   pathUrl: "/insurancepackageseductible",
        //   title: "Biztosítási csomag – Önrész megfeleltetés",
        //   exact: true,
        //   mode: "Both",
        //    component: InsurancepackageDeductiblePage,
        // },
        OfferTables: {
          id: "OfferTables",
          path: "/offertables/",
          pathUrl: "/offertables",
          title: "Ajánlattételi tábla paraméterezése",
          exact: true,
          mode: "Both",
           component: OfferTablesPage,
        },
        GPlusDocuments: {
          id: "GPlusDocuments",
          path: "/GPlusDocuments/",
          pathUrl: "/GPlusDocuments",
          title: "Havidíjas G+ dokumentumok feltöltése",
          exact: true,
          mode: "Both",
           component: GPlusDocumentsPage,
        },
      }
    
  },
  Login: {
    id: "Login",
    path: "/Login/:returnUrl?",
    title: "Login",
    exact: true,
    mode: "Parking",
    component: LoginPage,
    hiddenFromMenu: true,
  },
  NoMatch: {
    id: "noMatch",
    path: "*",
    title: "No match",
    exact: false,
    hiddenFromMenu: true,
    component: SqtSwitchPage,
  },
};

const RouteObj2Route = (routeObj: Record<any, IRouteObj>): IRoute[] => {
  let routes: IRoute[] = [];
  for (let key in routeObj) {
    routes.push({ ...routeObj[key], childrends: RouteObj2Route(routeObj[key].childrends) });
  }
  return routes;
};

let Routes: IRoute[] = RouteObj2Route(RoutesObj);

export default Routes;
