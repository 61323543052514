import { IGuarantee } from "../models/IGuarantee";
import { IStore } from "../models/IStore";
import { BaseService } from "./base.service";

export class GuaranteeService extends BaseService {
 
    constructor() {
        super("guarantee");
      }

  public async GetGuaranteeTimes(): Promise<IGuarantee[]> {
     return await this.get("GetGuaranteeTimes");
  }  
}