import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { IDropdownOption } from "office-ui-fabric-react";
import Cookies from "universal-cookie/es6/Cookies";
import { UserService } from "../../../../core/service/UserService";
import { RootStore } from "../../../../core/stores/RootStore";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { AdminService } from "../../../../core/service/AdminService";


export class InsurancePackageStore {
    public UserService: UserService = null;
    public AdminService: AdminService = null;

    RootStore: RootStore = null;
    @observable errorMessage: any = null;
    @observable file: any = null;
    @observable productGroups: Array<any>;
    @observable columnDef: Array<any>;
    @observable showUploadDialog: boolean = false;


    constructor(rootStore: RootStore) {
        this.UserService = new UserService();
        this.AdminService = new AdminService();
        this.RootStore = rootStore;

        this.RootStore.GoToHomeFn = () => {
            if (window.location.href.toLowerCase().indexOf("changelog") == -1) {
                console.log("nogo")
            }
            else {
                this.RootStore.GoToHome(1);
            }
        };


        this.columnDef = [

            { field: 'ArticleNumber', headerName: 'Cikkszám', filter: 'agSetColumnFilter', width:145, resizable: true, sortable: true },
            { field: 'Name', headerName: 'Név', filter: 'agSetColumnFilter', width:380,resizable: true, sortable: true },
            { field: 'PartnerName', headerName: 'Biztosító partner elnevezése', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'Category', headerName: 'Biztosítási termékkategória', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'ValueRangeMinString', headerName: 'Támogatott készülékérték -tól', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'ValueRangeMaxString', headerName: 'Támogatott készülékérték -ig', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'InsuranceType', headerName: 'Biztosítási csomagtípus', filter: 'agSetColumnFilter', resizable: true, sortable: true },
            { field: 'Period', headerName: 'Biztosítási időtartam', filter: 'agSetColumnFilter', resizable: true, sortable: true },

        ]
    }
    getProductGroups = flow(function* (this: InsurancePackageStore) {
    this.RootStore.addloading('getProductGroups')
       
       let temp = yield this.AdminService.GetInsurancePackages();
       let debug = 1;
       this.productGroups =temp;
    this.RootStore.removeLoading('getProductGroups')

        //this.RootStore.IsLoading = false;
    });
    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById('upload')).files[0];
        console.log('Kiválasztott file:', this.file)
    }
    showdialog() {
        this.showUploadDialog = true;
    }
    hideDialog() {
        this.showUploadDialog = false;
    }
    confirmUpload = flow(function* (this: InsurancePackageStore) {
    this.RootStore.addloading('uploadProductGroups')
    let formData: FormData = new FormData();
        this.file = (document.getElementById('upload') as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        let retVAl: any = null;
        retVAl = yield this.AdminService.UploadInsurancePackage(formData);
        this.getProductGroups();
        this.showUploadDialog = false;
        this.RootStore.removeLoading('uploadProductGroups')

        alert("Figyelem: A betöltés eredményét a visszakapott Excel táblázat tartalmazza, amennyiben van a táblázatban legalább egy sor, amelyhez tartozóan a jelzés NEM 'OK', abban az esetben az adatok tényleges betöltés NEM történt meg. Kizárólag teljesen hibátlanul kitöltött Excel táblázat esetén kerülnek az adatok a rendszerbe ténylegsen beolvasásra.");
    //     if(retVAl.result){
    //        this.getProductGroups();
    //    }
    //    else{
    //        alert('Hibás Excel fájl!');
    //    }

        //this.RootStore.IsLoading = false;
    });
    GetAdminTablesExcel= flow(function* (this: InsurancePackageStore) {
       
        this.RootStore.addloading('GetAdminTablesExcel')

        let file = yield this.AdminService.GetAdminTablesExcel(4);
    
        const fileName = `InsurancePackage-${+new Date()}.xlsx`;
        this.AdminService.downloadFile(file, fileName)
    
    
    
        this.RootStore.removeLoading('GetAdminTablesExcel')
    });
    Init = flow(function* (this: InsurancePackageStore) {
        // if (!this.RootStore.SelectedStore) {
        //     this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
        // }

        //this.RootStore.IsLoading = false;
    });
}
