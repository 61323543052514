import * as React from "react";
import "./Customer.scss";
import { observer, inject } from "mobx-react";
import "./Customer.scss";
import { CustomerStore } from "../core/stores/CustomerStore";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { RoutesObj } from "../../../core/contstants/Routes";
import { Checkbox, DatePicker, DayOfWeek, Dialog, DialogType, Dropdown, Label, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { AgGridReact } from "@ag-grid-community/react";
import { AgGridText } from "../../../core/contstants/agGridText";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import OpenItem from "../../../core/components/OpenItem";
import { DayPickerText } from "../../../core/contstants/datePickerText";

interface ICustomerProps {
  CustomerStore?: CustomerStore;
}

@inject("CustomerStore")
@observer
export class Customer extends React.Component<ICustomerProps, {}> {
  private stock1Src = String(require("../assets/stock1.png"));
  private stock2Src = String(require("../assets/stock2.png"));
  private transferSalesSrc = String(require("../assets/magnifying-glass.png"));
  private transferSalesSrc2 = String(require("../assets/writing.png"));


  constructor(props: ICustomerProps) {
    super(props);
  }

  componentDidMount() {
    let guid = window.location.href.slice(window.location.href.indexOf('=') + 1);
    this.props.CustomerStore.getOfferById(guid);

  }
  handleGridReady(event: GridReadyEvent) {
    event.api.sizeColumnsToFit();
  }
  public renderDialogs() {
    return (<div>


      <Dialog
        hidden={!this.props.CustomerStore.showDeclinePopup}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Köszönjük!",
          showCloseButton: false
        }}
      >
        <div>
          Kérését tudomásul vettük és a biztosítási ajánlatát érvénytelenítettük.
        </div>
        <div style={{ margin: "20px",textAlign:'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'grey', marginRight: '20px' }}
            text='Ok'
            onClick={() => this.props.CustomerStore.done = true} />
          
        </div>

      </Dialog>
      <Dialog
        hidden={!this.props.CustomerStore.showDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Admin",
          showCloseButton: false
        }}
      >
        <TextField
          label="Jelszó"
          value={this.props.CustomerStore.adminPass}

          onChange={(ev, value) => {
            this.props.CustomerStore.adminPass = value;
          }}
        />


      </Dialog>

    </div>)
  }

  public renderSqtItems(item: any, index: number) {
    return (<div className='panel' key={index}>
      <div className='title' onClick={() => this.props.CustomerStore.onClosePanelClicked(`BiztositottTermekAdatai-${index}`)}>
        {index + 1}. Biztosított eszköz adatai ({item.Gyarto_Tipus})
      </div>


      {this.props.CustomerStore.closedPanels.indexOf(`BiztositottTermekAdatai-${index}`) == -1 &&
        <div className="panelContainer">

          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Cikkszám
            </div>
            <div className="inputContainer half inline">
              {item.Cikkszam}

            </div>
          </div>
          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Biztosított eszköz vételára
            </div>
            <div className="inputContainer half inline">
              {item.Vetelar ? `${item.Vetelar.toString()}` : ''}

            </div>
          </div>
          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Vásárlás dátuma
            </div>
            <div className="inputContainer half inline">
              <table>
                <tr>
                  <td>
                    {item.VasarlasDatumaString}
                  </td>
                  <td style={{ paddingLeft: '55px' }}>
                    A biztosítási szerződést a biztosított készülék megvásárlásával egyidejűleg vagy legkésőbb a készülék megvásárlását követő 30 napon belül kell megkötni
                  </td>
                </tr>


              </table>


            </div>
          </div>
          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Gyártási/IMEI szám
            </div>
            <div className="inputContainer half inline">
              {item.GyartasiSzam}

            </div>
          </div>
          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Gyártó, típus
            </div>
            <div className="inputContainer half inline">
              {item.Gyarto_Tipus}

            </div>
          </div>
          <div className="row">
            <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
              Biztosított eszköz árkategóriája
            </div>
            <div className="inputContainer half inline">
              {item.Arkategoria}

            </div>
          </div>








          {item.SelectedInsurace &&
            <div className='panel'>
              <div className='title' style={{ background: '#a51716' }} onClick={() => this.props.CustomerStore.onClosePanelClicked(`BiztositottTermekAdatai-biztositas-${index}`)}>
                {index + 1}. Biztosítás részletei ({item.SelectedInsurace?.Name})
              </div>
              {this.props.CustomerStore.closedPanels.indexOf(`BiztositottTermekAdatai-biztositas-${index}`) == -1 &&
                <div className="panelContainer">

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Biztosítási időszak kezdete műszaki meghibásodás esetén
                    </div>
                    <div className="inputContainer half inline">
                      {item.IdoszakKezdeteMeghibasodas}
                    </div>
                  </div>
                  { item.SelectedInsurace?.InsuranceType !="SILVER" &&  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Biztosítási időszak kezdete baleseti meghibásodás esetén
                    </div>
                    <div className="inputContainer half inline">
                      {item.IdoszakKezdeteMeghibasodasBaleseti}
                    </div>
                  </div>}
                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Biztosítási időszak vége
                    </div>
                    <div className="inputContainer half inline">
                      {item.IdoszakVege}
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Biztosítási módozat megnevezése
                    </div>
                    <div className="inputContainer half inline">
                      <table>
                        <tr>
                          <td>
                            {item.ModozatMegnevezese}
                          </td>
                         { item.SelectedInsurace?.InsuranceType !="SILVER" && <td style={{ paddingLeft: '55px' }}>
                            Baleseti kár (a biztosítás létrejöttét követően), és elektromos vagy mechanikai meghibásodás (a kötelező jótállási időszak lejárta után)
                          </td>}
                          { item.SelectedInsurace?.InsuranceType =="SILVER" && <td style={{ paddingLeft: '55px' }}>
                          Elektromos vagy mechanikai meghibásodás (a kötelező jótállási időszak lejárta után)
                          </td>}
                        </tr>


                      </table>


                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Ajánlat dátuma
                    </div>
                    <div className="inputContainer half inline">
                      {item.AjanlatDatuma}

                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Ajánlatszám
                    </div>
                    <div className="inputContainer half inline">
                      {item.Ajanlatszam}

                    </div>
                  </div>
                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Biztosítási Módozat díja
                    </div>
                    <div className="inputContainer half inline">
                      {item.SelectedInsurace?.PriceString}

                    </div>
                  </div>




                </div>}



            </div>


          }


        </div>}
    </div>)
  }


  public render() {
    if (this.props.CustomerStore.RootStore.loadingCount > 0) {
      return <LoaderOverlay />;
    }


    return (
      <div className="sqtReportComponent">
        <div className="mainHeader">Media Markt Garancia Plusz</div>
        <div className="darkerBcground">
          {/* Felhasználó */}
          {!this.props.CustomerStore.sqtData &&
            <div style={{ marginTop: '30px', color: 'red', fontWeight: "bold", textAlign: 'center' }}>
              Nem található ezen a linken ajánlat!
            </div>

          }
          {this.props.CustomerStore.done &&
            <div style={{ marginTop: '30px', color: 'green', fontWeight: "bold", textAlign: 'center' }}>
              Minden adatát rögzítettük! Köszönjük!
            </div>

          }
          {this.props.CustomerStore.reject &&
            <div style={{ marginTop: '30px', color: 'green', fontWeight: "bold", textAlign: 'center' }}>
             Kérését tudomásul vettük és a biztosítási ajánlatát érvénytelenítettük.
            </div>

          }

          {this.props.CustomerStore.sqtData && !this.props.CustomerStore.done && !this.props.CustomerStore.reject && <div className='mainPanel'>
            <div className='panel'>
              <div className='title' onClick={() => this.props.CustomerStore.onClosePanelClicked("SzerzodoUgyfelAdatai")}>
                Ügyfél adatok
              </div>

              {this.props.CustomerStore.closedPanels.indexOf('SzerzodoUgyfelAdatai') == -1 &&
                <div className="panelContainer">

                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="Vezetéknév"
                        value={this.props.CustomerStore.sqtData.Vezeteknev}
                        required={true}
                        disabled={this.props.CustomerStore.intrest}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Vezeteknev', "Vezetéknév", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Vezeteknev', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('TeljesNev', `${this.props.CustomerStore.sqtData.Vezeteknev} ${this.props.CustomerStore.sqtData.Keresztnev}`, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                    <div className="inputContainer half inline">
                      <TextField
                        label="Utónév"

                        value={this.props.CustomerStore.sqtData.Keresztnev}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Keresztnev', "Utónév", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Keresztnev', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('TeljesNev', `${this.props.CustomerStore.sqtData.Vezeteknev} ${this.props.CustomerStore.sqtData.Keresztnev}`, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer full">
                      <TextField
                        label="Teljes név"
                        value={this.props.CustomerStore.sqtData.TeljesNev}
                        disabled={true}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Vezeteknev', value, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">

                    <div className="inputContainer half inline">


                      <Checkbox
                        label="Az Ügyfél az ajánlattétel napján a 18. életévét betöltötte"
                        disabled={this.props.CustomerStore.intrest}
                        checked={this.props.CustomerStore.sqtData.Betoltott18}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onSelectChanged('Betoltott18', value, this.props.CustomerStore.sqtData);


                        }}
                      />

                      {this.props.CustomerStore.getErrorMessage('Betoltott18', "Betöltött 18. életév", this.props.CustomerStore.sqtData) && <Label className="errorMessageLabel">{this.props.CustomerStore.getErrorMessage('Betoltott18', "Betöltött 18. életév", this.props.CustomerStore.sqtData)}</Label>}
                    </div>
                    <div className="inputContainer half inline">
                      <TextField
                        label="Kapcsolattartó telefonszáma (nemzetközi előhívóval)"
                        disabled={this.props.CustomerStore.intrest}
                        value={this.props.CustomerStore.sqtData.MobilTelefonszam}
                        required={true}
                        type='number'
                        errorMessage={this.props.CustomerStore.getErrorMessage('MobilTelefonszam', "Kapcsolattartó telefonszáma", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('MobilTelefonszam', value, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                  </div>



                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="E-mail cím"
                        disabled={this.props.CustomerStore.intrest}
                        value={this.props.CustomerStore.sqtData.Email}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Email', value, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                  </div>
                </div>}
            </div>
            <div className='panel'>
              <div className='title' onClick={() => this.props.CustomerStore.onClosePanelClicked("AllandoLakcim")}>
                Lakcím
              </div>
              {this.props.CustomerStore.closedPanels.indexOf('AllandoLakcim') == -1 &&
                <div className="panelContainer">
                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="Irányítószám"
                        value={this.props.CustomerStore.sqtData.Allando_Iranyitoszam}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        type="number"
                        errorMessage={this.props.CustomerStore.getErrorMessage('Allando_Iranyitoszam', "Állandó irányítószám", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_Iranyitoszam', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', this.props.CustomerStore.getFullAddress(true), this.props.CustomerStore.sqtData);

                          if (this.props.CustomerStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                            this.props.CustomerStore.onValueChanged('Levelezesi_Iranyitoszam', value, this.props.CustomerStore.sqtData);
                            this.props.CustomerStore.onValueChanged('Levelezesi_TeljesCim', this.props.CustomerStore.getFullAddress(false), this.props.CustomerStore.sqtData);
                          }
                        }}
                      />
                    </div>
                    <div className="inputContainer half inline">
                      <TextField
                        label="Város"
                        value={this.props.CustomerStore.sqtData.Allando_Varos}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Allando_Varos', "Állandó város", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_Varos', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', this.props.CustomerStore.getFullAddress(true), this.props.CustomerStore.sqtData);

                          if (this.props.CustomerStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                            this.props.CustomerStore.onValueChanged('Levelezesi_Varos', value, this.props.CustomerStore.sqtData);
                            this.props.CustomerStore.onValueChanged('Levelezesi_TeljesCim', this.props.CustomerStore.getFullAddress(false), this.props.CustomerStore.sqtData);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="Közterület neve"
                        value={this.props.CustomerStore.sqtData.Allando_KozteruletNeve}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Allando_KozteruletNeve', "Állandó közterület neve", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_KozteruletNeve', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', this.props.CustomerStore.getFullAddress(true), this.props.CustomerStore.sqtData);

                          if (this.props.CustomerStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                            this.props.CustomerStore.onValueChanged('Levelezesi_KozteruletNeve', value, this.props.CustomerStore.sqtData);
                            this.props.CustomerStore.onValueChanged('Levelezesi_TeljesCim', this.props.CustomerStore.getFullAddress(false), this.props.CustomerStore.sqtData);
                          }
                        }}
                      />
                    </div>
                    <div className="inputContainer half inline">
                      <TextField
                        label="Közterület jellege"
                        value={this.props.CustomerStore.sqtData.Allando_KozteruletJellege}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Allando_KozteruletJellege', "Állandó közterület jellege", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_KozteruletJellege', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', this.props.CustomerStore.getFullAddress(true), this.props.CustomerStore.sqtData);

                          if (this.props.CustomerStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                            this.props.CustomerStore.onValueChanged('Levelezesi_KozteruletJellege', value, this.props.CustomerStore.sqtData);
                            this.props.CustomerStore.onValueChanged('Levelezesi_TeljesCim', this.props.CustomerStore.getFullAddress(false), this.props.CustomerStore.sqtData);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="Házszám"
                        value={this.props.CustomerStore.sqtData.Allando_Hazszam}
                        disabled={this.props.CustomerStore.intrest}
                        required={true}
                        errorMessage={this.props.CustomerStore.getErrorMessage('Allando_Hazszam', "Állandó házszám", this.props.CustomerStore.sqtData)}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_Hazszam', value, this.props.CustomerStore.sqtData);
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', this.props.CustomerStore.getFullAddress(true), this.props.CustomerStore.sqtData);

                          if (this.props.CustomerStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                            this.props.CustomerStore.onValueChanged('Levelezesi_Hazszam', value, this.props.CustomerStore.sqtData);
                            this.props.CustomerStore.onValueChanged('Levelezesi_TeljesCim', this.props.CustomerStore.getFullAddress(false), this.props.CustomerStore.sqtData);
                          }
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="inputContainer full">
                      <TextField
                        label="Ajánlatba kerülő cím"
                        value={this.props.CustomerStore.sqtData.Allando_TeljesCim}
                        disabled={true}
                        onChange={(ev, value) => {
                          this.props.CustomerStore.onValueChanged('Allando_TeljesCim', value, this.props.CustomerStore.sqtData);
                        }}
                      />
                    </div>
                  </div> */}
                </div>}
            </div>
            {/* <div style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center", color: "red", fontWeight: "bold", padding: "20px" }}>
              Tisztelt Ügyfelünk! Ha az alábbiakban több biztosítási ajánlat esetében valamelyik biztosításra mégsem tart igényt, kérjük elfogadás előtt vegye fel a kapcsolatot Okosvonalunk munkatársaival az alábbi telefonszámon: +36 1 358-6600 (5-ös menüpont)!
            </div> */}
            {this.props.CustomerStore.sqtData?.Termekek?.map((item, index) => { return this.renderSqtItems(item, index) })}
            {this.props.CustomerStore.intrest &&
              <div className='panel'>
                <div className='title' onClick={() => this.props.CustomerStore.onClosePanelClicked("SzerzodoUgyfelAdatai")}>
                  Biztosító legfőbb adatai
                </div>

                <div style={{ fontWeight: 'bold' }}>
                  Starr Europe Insurance Limited Zrt., mint szolgáltató adatai:
                </div>

                <div className="panelContainer">
                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Cégneve:
                    </div>
                    <div className="inputContainer half inline">
                      Starr Europe Insurance Limited Zrt.
                    </div>
                  </div>
                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Székhelye:
                    </div>
                    <div className="inputContainer half inline">
                      Dragonara Business Centre, 5th Floor, Dragonara Road, St. Julians, STJ 3141 Malta (Málta)
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Telefonszáma:
                    </div>
                    <div className="inputContainer half inline">

                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Fő tevékenységi köre:
                    </div>
                    <div className="inputContainer half inline">
                      biztosítási tevékenység
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Cégnyilvántartási száma:
                    </div>
                    <div className="inputContainer half inline">
                      C85380
                    </div>
                  </div>

                  <div className="row">
                    <div className="inputContainer half inline" style={{ fontWeight: 'bold' }}>
                      Felügyeleti szerv:
                    </div>
                    <div className="inputContainer half inline">
                      Magyar Nemzeti Bank, 1054 Budapest, Szabadság tér 8-9.
                    </div>
                  </div>
                </div>
              </div>

            }

            {this.props.CustomerStore.intrest &&




              <div style={{ marginTop: "30px", marginBottom: "30px", color: 'red' }}>
                <div style={{ fontWeight: "bold", marginTop: "10px", marginBottom: "10px" }}>
                  Kérjük, hogy a szerződés megkötéséhez szükséges nyilatkozatokat tegye meg:
                </div>
                <div>
                  <Checkbox
                    label="Ajánlati adataimat ellenőriztem, és az ezzel kapcsolatos nyilatkozatokat megteszem."
                    checked={this.props.CustomerStore.statement1}
                    onChange={(ev, value) => {
                      this.props.CustomerStore.statement1 = value;


                    }}
                    styles={{
                      text: { color: "red" }

                    }}
                  />

                </div>

                <div style={{ marginTop: "20px" }}>
                  <Checkbox
                    label="Igazolom, hogy a Starr Europe Insurance Limited Zrt. (nyilvántartási száma: C85380; székhelye: Dragonara Business Centre, 5th Florr, Dragonara Road, St. Julians, STJ 3141 Málta, a továbbiakban: Biztosító) adatait, valamint a biztosítási szerződés jellemzőit tartalmazó Biztosítási Terméktájékoztatót is magába foglaló Biztosítási Feltételeket, valamint a személyes adataim kezeléséről szóló Adatvédelmi tájékoztatót, a biztosítási ajánlat megtétele előtt megkaptam, azokat megismertem és elfogadom. Kifejezetten elfogadom, hogy I) a biztosítási szerződésből eredő igények 1 év alatt évülnek el, II) hogy a biztosítási időszak a biztosítás tartamával megegyező időszak, valamint III) hogy jelen biztosítás a termék gyártói garancia vagy jelen szerződés alapján történő cseréje esetén nem terjed ki az eredeti termék helyébe lépő termékre."
                    checked={this.props.CustomerStore.statement2}
                    onChange={(ev, value) => {
                      this.props.CustomerStore.statement2 = value;



                    }}
                    styles={{
                      text: { color: "red" }

                    }}
                  />

                </div>

                <div style={{ marginTop: "20px" }}>

                  <Checkbox
                    label="Tudomásul veszem és megértettem, hogy a biztosítási szerződés abban az esetben jön létre, ha a biztosítási díj 10 napon belül beérkezik a Media Markt Unicredit Bank Zrt-nél vezetett 10918001-00000002-19850399 számú bankszámlájára, és a Starr Eurpe Insurance Limited Zrt. az ajánlatot nem utasítja vissza. A 10 napos határidő akkor kezdődik, amikor az ajánlatot a Media Markt részére elektronikusan visszaigazolom a „Megkötöm” gomb megnyomásával."
                    checked={this.props.CustomerStore.statement3}
                    onChange={(ev, value) => {
                      this.props.CustomerStore.statement3 = value;



                    }}
                    styles={{
                      text: { color: "red", fontWeight: 'bold' }

                    }}
                  />
                </div>

                <div style={{ marginTop: "20px" }}>

                  <Checkbox
                    label="Jelen Biztosítási ajánlat alapján a biztosítási tevékenységet a STARR Europe (nyilvántartási száma: C85380; székhelye: Dragonara Business Centre, 5th Floor, Dragonara Road, St. Julians, STJ 3141 Málta; a továbbiakban: „Biztosító”) végzi, amelynek a megbízásából a SquareTrade Europe B.V. Limited (nyilvántartási szám: 0786.464.518 székhely: Avenue du Port 86C (box 204), 1000, Belgium; a továbbiakban: „Biztosításközvetítő”) a biztosításforgalmazásról szóló irányelvnek megfelelően biztosításközvetítői tevékenységet végez."
                    checked={this.props.CustomerStore.statement4}
                    onChange={(ev, value) => {
                      this.props.CustomerStore.statement4 = value;



                    }}
                    styles={{
                      text: { color: "red" }

                    }}
                  />
                </div>


              </div>


            }
            {(this.props.CustomerStore.isSubmitted && (this.props.CustomerStore.errors.length > 0)) && <MessageBar className="errorMessageBar" messageBarType={MessageBarType.error}>
              <ul>
                {this.props.CustomerStore.errors.map(s => <li className="errorLi" key={s.key}>{s.value}</li>)}
              </ul>
            </MessageBar>}
            {!this.props.CustomerStore.intrest && <div style={{ marginTop: "20px" }}>
              <PrimaryButton
                className="ui button"
                text='Nem érdekel az ajánlat'
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                }}
                onClick={() => this.props.CustomerStore.SaveofferDecline()}
              />

              <PrimaryButton
                className="ui button"
                text='Érdekel az ajánlat'
                style={{
                  backgroundColor: '#21ba45',
                  color: 'white',
                  marginLeft: '50px'
                }}
                onClick={() =>

                  this.props.CustomerStore.intrestOffer()
                }
              />


            </div>}
            {this.props.CustomerStore.intrest && <div style={{ marginTop: "20px" }}>
              <PrimaryButton
                className="ui button"
                text='< Vissza'

                onClick={() =>
                  this.props.CustomerStore.cancelIntrest()
                }

              />

              <PrimaryButton
                className="ui button"
                text='Megkötöm >'
                style={{
                  backgroundColor: '#21ba45',
                  color: 'white',
                  marginLeft: '50px'

                }}
                onClick={() => this.props.CustomerStore.SaveofferAccept()}

              />


            </div>}
          </div>}
          {this.renderDialogs()}
        </div>
      </div>
    );
  }
}
