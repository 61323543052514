import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
// import { IResourceType } from "../../../../../core/models/IResourceType";

export class LoginService {

  public async Login(UserName, Password): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ UserName, Password }),
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/user/AuthenticateUser`, options)
    return returnValue.json();
  }
  public async logOut(): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/user/SignOut`, options)
    return returnValue;
  }


}
