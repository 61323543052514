import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { inject, observer } from 'mobx-react';
import { ButtonType, Dialog, DialogType, Dropdown, IDropdownOption, MessageBar, MessageBarType, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React = require('react');
import { RouteComponentProps } from 'react-router-dom';
// import React from 'react';
// import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import LoaderOverlay from '../../../components/controls/LoaderOverlay/LoaderOverlay';
import { AgGridText } from '../../../core/contstants/agGridText';
import { RootStore } from '../../../core/stores/RootStore';
import { AdminStore } from '../core/AdminStore';
import { GPlusDocumentsStore } from './core/GPlusDocumentsStore';

import "./GPlusDocuments.scss";

// import logo from '../../assets/logo.png';
// import withNavigation from '../../hocs/withNavigation';
// import { RootStore } from '../../stores/RootStore';

interface IGPlusDocumentsProps {
    GPlusDocumentsStore?: GPlusDocumentsStore;
}
@inject("GPlusDocumentsStore")
@observer
export class GPlusDocuments extends React.Component<IGPlusDocumentsProps, {}> {
    private readonly returnUrl: string = "";
    private logo: string = String(require("../../../assets/mm-logo.svg"));
    constructor(props: IGPlusDocumentsProps) {
        super(props);
    }
    handleGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    }

    componentDidMount(): void {

        this.props.GPlusDocumentsStore.getUploadedDocuments()


    }
    public renderDialogs() {
        return (<div>
            <Dialog
                hidden={!this.props.GPlusDocumentsStore.showUploadDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Biztosítási csomagok betöltése",
                    showCloseButton: false
                }}
            >
                <div style={{ maxWidth: '400px' }}>

                    <Input id={'upload'} onChange={(event) => { this.props.GPlusDocumentsStore.setFile(event) }} type="file" />

                </div>

                <div style={{ margin: "20px", textAlign: 'center' }}>
                    <PrimaryButton
                        className="ui button"
                        style={{ backgroundColor: 'red' }}
                        text="Betöltés"
                        onClick={() => this.props.GPlusDocumentsStore.confirmUpload()}
                    />
                    <PrimaryButton
                        className="ui button"
                        style={{ backgroundColor: 'grey', marginLeft: '10px' }}
                        text="Mégse"
                        onClick={() => this.props.GPlusDocumentsStore.showUploadDialog = false}
                    />
                </div>
            </Dialog>

        </div>)
    }
    public render() {
        if (this.props.GPlusDocumentsStore.RootStore.loadingCount > 0) {
            return <LoaderOverlay />;
        }

        return (
            <div className="sqtReportComponent">
                <div className="mainHeader">Havidíjas G+ dokumentumok feltöltése</div>
                <div className="darkerBcground">
                    {/* Felhasználó */}
                    {this.props.GPlusDocumentsStore.RootStore.renderHeader()}
                    <div className='container'>
                        <div className='mainPanel'>
                            {/* {this.props.SqtReportStore.sqtData ? this.renderSqtOffer() : this.renderQueryButton()} */}


                            <table>
                                <tr>
                                    <td>
                                        <Dropdown
                                            key='SelectedStore'
                                            label='Dokumentum kiválasztása'
                                            options={this.props.GPlusDocumentsStore.options}
                                            selectedKey={this.props.GPlusDocumentsStore.SelectedDocumentType}
                                            onChanged={(option: IDropdownOption) => {
                                                this.props.GPlusDocumentsStore.SelectedDocumentType = option.key;
                                            }}
                                            style={{ width: '400px' }}
                                        />
                                    </td>
                                    <td>
                                        <div style={{ maxWidth: '400px', paddingTop: '24px' }}>

                                            <Input id={'upload'} onChange={(event) => { this.props.GPlusDocumentsStore.setFile(event) }} type="file" />

                                        </div>
                                    </td>
                                    <td>
                                        <div className='mainButtons' style={{ textAlign: 'left', paddingTop: '27px' }}>
                                            <PrimaryButton
                                                className="ui button"
                                                style={{ backgroundColor: 'red' }}
                                                text='Dokumentum feltöltése'
                                                onClick={() => this.props.GPlusDocumentsStore.confirmUpload()} />

                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div style={{textAlign:'left',marginTop:'20px',marginLeft:'6px'}}>

                                <div style={{ cursor: 'pointer',textDecoration:'underline',color:'red' }} onClick={() => this.props.GPlusDocumentsStore.GetUploadedDocumentByType(1)}>
                                    árlista a havidíjas Garancia Plusz biztosításokról
                                </div>
                                <div style={{ cursor: 'pointer',textDecoration:'underline',color:'red',marginTop:'10px' }} onClick={() => this.props.GPlusDocumentsStore.GetUploadedDocumentByType(2)}>
                                    GOLD Smart Garancia Plusz előzetes dokumentumok
                                </div>
                                <div style={{ cursor: 'pointer',textDecoration:'underline',color:'red',marginTop:'10px' }} onClick={() => this.props.GPlusDocumentsStore.GetUploadedDocumentByType(3)}> 
                                    PLATINA Smart Garancia Plusz előzetes dokumentumok
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                {this.renderDialogs()}
            </div>
        );
    }
}


