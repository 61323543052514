import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { RootStore } from "../../../../core/stores/RootStore";
import { StoreService } from "../../../../core/service/StoreService";
import { IDropdownOption, ITextField } from "office-ui-fabric-react";
import { UserService } from "../../../../core/service/UserService";
import Cookies from "universal-cookie/es6/Cookies";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { SqtOfferService } from "../../../../core/service/SqtOfferService";
import { ISqtData } from "../../../../core/models/ISqtData";
import { IErrorModel } from "../../../../core/models/IErrorModel";
import { GuaranteeService } from "../../../../core/service/GuaranteeService";
import { IGuarantee } from "../../../../core/models/IGuarantee";
import { AdminService } from "../../../../core/service/AdminService";

export class SqtSwitchStore {
  public StoreService: StoreService = null;
  public UserService: UserService = null;
  public SqtOfferService: SqtOfferService = null;
  public GuaranteeService: GuaranteeService = null;
  public AdminService: AdminService = null;
  

  public Code: string = null;
  public CodeInput: ITextField = undefined;

  RootStore: RootStore = null;

  public guaranteeTimes: Array<IGuarantee> = [];

  @observable errorMessage: any = null;
  @observable errors: Array<IErrorModel> = [];

  @observable sqtData: any = null;

  @observable isSubmitted: boolean = false;
  @observable showBizonylatError: boolean = false;
  @observable showDialog: boolean = false;
  @observable downlodedDocument: boolean = false;
  @observable showAdminDialog: boolean = false;
  @observable adminPass: any;
  
  
  @observable closedPanels: Array<string> = [];

  @observable showWelcomeDialog: boolean = !localStorage['hideWelcomeDialog'];
  @observable showMaganSzemelyDialog: boolean = false;
  @observable showGyartasiSzamDialog: boolean = false;
  @observable showAjanlatGeneralasa: boolean = false;
  @observable showWawiData: boolean = false;

  @observable gyartasiSzamIndex: number = null;

  constructor(rootStore: RootStore) {
    this.StoreService = new StoreService();
    this.GuaranteeService = new GuaranteeService();
    this.UserService = new UserService();
    this.SqtOfferService = new SqtOfferService();
    this.AdminService= new AdminService();
    this.RootStore = rootStore;
  }

  Init = flow(function* (this: SqtSwitchStore) {
    let name = "isAdmin";
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
      if (results && results.length > 1) {
        if (results[2] == 'true') {
          this.showAdminDialog = true;
        }
      }
    // if (!this.RootStore.CurrentUser) {
    //   this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {returnUrl:window.location.href}, null, false);
    // } else {
      
    // }
  });
  GetUploadedDocumentByType = flow(function* (this: SqtSwitchStore, value: any) {


    this.RootStore.addloading('sqtReportStore_GetETDocument')

    let file = yield this.AdminService.GetUploadedDocumentByType(value);

    let fileName = '';
    if(value==1){
        fileName="árlista a havidíjas Garancia Plusz biztosításokról.pdf";
    }else if(value==2){
        fileName="GOLD Smart Garancia Plusz előzetes dokumentumok.pdf";
    }else if(value==3){
        fileName="PLATINA Smart Garancia Plusz előzetes dokumentumok.pdf";

    }
    this.AdminService.downloadFile(file, fileName);
    if(value==2 || value==3){
      this.downlodedDocument=true;
    }



    this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
});
  adminLogin= flow(function* (this: SqtSwitchStore) {
  
    let result= yield this.UserService.adminLogin(this.adminPass);
  
    if(result){
      this.RootStore.RouterStore.IsAdmin=true;
      
    }
    this.showAdminDialog=false;
  
    //this.RootStore.IsLoading = false;
  });
  isMobile() {
    // let routeParts = this.router.url.split('/');
    // let routeUrl = routeParts[1] + '/' + routeParts[2];
    // let routerConfig = _.find(this.router.config, (conf: any) => { return conf.path == routeUrl });
    // let isMobile = routerConfig != null ? routerConfig.data.isMobileCompatible : false;
 //
     return (document && document.body && document.body.offsetWidth < 800);
   }

  onSubmit = flow(function* (this: SqtSwitchStore) {
    this.RootStore.addloading('SqtSwitchStore_onSubmit')

    this.isSubmitted = false;

    try {
      this.sqtData = yield this.SqtOfferService.GetArticleData(this.RootStore.SelectedStore, this.Code);

      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      this.showBizonylatError = true;
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('SqtSwitchStore_onSubmit')
  });
  newArticleNumber() {
    this.sqtData = null;
    this.Code = "";
  }

  onNewSubmit = flow(function* (this: SqtSwitchStore) {

    // if (this.errors.length) {
    //   this.isSubmitted = true;
    //   return;
    // }

    // const gyartasiSzamIndex = this.sqtData.Termekek.findIndex(s => !s.GyartasiSzam);

    // if (gyartasiSzamIndex > -1) {
    //   this.gyartasiSzamIndex = gyartasiSzamIndex;
    //   this.showGyartasiSzamDialog = true;
    //   return;
    // }

    // if (!download) {
    //   this.showAjanlatGeneralasa = true;
    //   return;
    // } else {
    //   this.showAjanlatGeneralasa = false;
    // }

    this.RootStore.addloading('SqtSwitchStore_onReportSubmit')

    this.sqtData = yield this.SqtOfferService.GetRecalculatedInsurances(this.sqtData, this.RootStore.SelectedStore);

    this.isSubmitted = false;


    this.RootStore.removeLoading('SqtSwitchStore_onReportSubmit')
  });

  public getFullAddress(isAllando: boolean) {
    const addressParts = ['Iranyitoszam', 'Varos', 'KozteruletNeve', 'KozteruletJellege', 'Hazszam'];

    let address = '';
    addressParts.forEach(element => {
      const property = (isAllando ? 'Allando_' : 'Levelezesi_') + element;
      if (this.sqtData[property]) {
        address += this.sqtData[property] + " ";
      }
    });

    return address.trim();
  }

  @action hideDialog = (dialog: string) => {
    this[dialog] = false;

    if (dialog == 'showWelcomeDialog') {
      localStorage['hideWelcomeDialog'] = 'true';
    }
  }

  @action onNewQuery = () => {
    this.Code = '';
    this.sqtData = null;
    this.errors = [];
  }

  @action getErrorMessage = (property: string, label: string) => {
    const index = this.errors.findIndex(p => p.key == property);
    let message = `${label} kitöltése kötelező!`;

    switch (property) {
      case 'Email':
        if (!this.sqtData[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (!this.sqtData[property]
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            message = `${label} formátuma nem megfelelő!`;
            if (index == -1) {
              this.errors.push({ key: property, value: message });
            } else {
              this.errors[index].value = message;
            }

            return this.isSubmitted ? message : null;
          } else {
            if (index > -1) {
              let errors = [...this.errors];
              errors.splice(index, 1);

              this.errors = errors;

              return null;
            }
          }
        }
        break;
      default:
        if (!this.sqtData[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (index > -1) {
            let errors = [...this.errors];
            errors.splice(index, 1);

            this.errors = errors;

            return null;
          }
        }
        break;
    }
  }

  @action onClosePanelClicked = (panelId: string) => {
    const index = this.closedPanels.indexOf(panelId);
    if (index == -1) {
      this.closedPanels.push(panelId);
    } else {
      const closedPanels = [...this.closedPanels];
      closedPanels.splice(index, 1);
      this.closedPanels = closedPanels;
    }
  }

  @action onValueChanged = (property: string, value: any, object?: any) => {
    if (object) {
      object[property] = value;
    } else {
      this[property] = value;
    }
  }

  @action onSelectChanged = (property: string, isChecked: boolean, object?: any) => {
    if (object) {
      object[property] = isChecked;
    } else {
      this[property] = isChecked;
    }
  }

  @action onCheckBoxSelectChanged = (property: string, code: string, isChecked: boolean) => {
    const index = this[property].indexOf(code);

    if (isChecked) {
      if (index == -1) {
        this[property].push(code);
      }
    } else {
      if (index > -1) {
        this[property].splice(index, 1);
      }
    }
  }

  @action onSelectAll = (property, values, key) => {
    if (this[property].length) {
      this[property] = [];
    } else {
      this[property] = values.map(s => s[key]);
    }
  }
}
