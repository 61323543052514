import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";
import { SqtOffer } from "./components/SqtOffer";
import { SqtOfferStore } from "./core/stores/SqtOfferStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  SqtOfferStore: SqtOfferStore;
}

@inject("RootStore")
@observer
export class SqtOfferPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);

    let store = new SqtOfferStore(props.RootStore);
    store.Code = (this.props.match.params as any).code;

    this.stores = {
      SqtOfferStore: store
    };

  }

  async componentDidMount() {
    this.stores.SqtOfferStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <SqtOffer />
      </Provider>
    );
  }
}
