import { inject, observer } from "mobx-react";
import { CommandBar } from "office-ui-fabric-react";
import React = require("react");
import { Route, Switch, HashRouter, withRouter, RouteComponentProps } from "react-router-dom";
import { RoutesObj } from "../../core/contstants/Routes";
import { RootStore } from "../../core/stores/RootStore";

interface IProps extends RouteComponentProps<{}> {
  RootStore?: RootStore;
}

@inject("RootStore")
@observer
class TopMenu extends React.Component<IProps> {
  private logo: string = String(require("../../assets/mm-logo.svg"));

  constructor(props: IProps) {
    super(props);
    this.props.RootStore.RouterStore.onLocationUpdated(this.props.location);
    this.props.RootStore.RouterStore.updateHistory(this.props.history);
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.props.RootStore.RouterStore.updateHistory(this.props.history);

    if (nextProps.location !== this.props.location) {
      this.props.RootStore.RouterStore.onLocationUpdated(nextProps.location, this.props.location);
    }
  }

  public render() {
    const { RootStore } = this.props;

    return (
      <div id="menu" style={{ display: "flex", height: 56 }}>
        <img src={this.logo} id="logo" style={{ height: "30px", position: "relative", top: 5, cursor: 'pointer' }}  onClick={() => { this.props.RootStore.RouterStore.gotToRoute(RoutesObj.SqtSwitchPage.id, {}, null, false); }}/>
        {this.props.RootStore.isUserAuthed && <CommandBar items={RootStore.RouterStore.getNavLinks} onReduceData={() => undefined}  />}
      </div>
    );
  }
}

export default withRouter(TopMenu);
