import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";
import { Home } from "./components/Home";
import { HomeStore } from "./core/stores/HomeStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  HomeStore: HomeStore;
}

@inject("RootStore")
@observer
export class HomePage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);
    console.log("this.props.match.params.id", this.props.match.params);

    let params: any = this.props.match.params;
    this.stores = {
      HomeStore: new HomeStore(props.RootStore,params.type, params.categoryId, params.moduleId),

    };
  }

  async componentDidMount() {
    this.stores.HomeStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <Home />
      </Provider>
    );
  }
}
