import * as React from "react";
import "./LoaderOverlay.module.scss";

export type ILoaderOverlayProps = {};

export default class LoaderOverlay extends React.Component<ILoaderOverlayProps, {}> {
  private logo: string = String(require('../../../assets/logo.png'));

  public render(): React.ReactElement<ILoaderOverlayProps> {
    return (
      <div>
        <div className="blur"></div>
        <div className="progress" style={{ padding: '5px' }}>
          <div style={{ background: 'white' }}>
            <img src={this.logo} style={{ width: '289px', cursor: 'pointer' }} />
          </div>
        </div>
      </div>
    );
  }
}
