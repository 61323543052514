import * as React from "react";
import "./SqtReport.scss";
import { observer, inject } from "mobx-react";
import { SqtReportStore } from "../core/stores/sqtReportStore";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { Checkbox, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogType, Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { DayPickerText } from "../../../core/contstants/datePickerText";
import { ISqtItem } from "../../../core/models/ISqtItem";
import { Radio } from "semantic-ui-react";
import { withRouter } from "../../../core/components/withRouter";

interface ISqtReportProps {
  SqtReportStore?: SqtReportStore;
  params?: any;
}

@inject("SqtReportStore")
@observer
class SqtReport extends React.Component<ISqtReportProps, {}> {
  private transferSalesSrc = String(require("../assets/pen.png"));

  constructor(props: ISqtReportProps) {
    super(props);
  }

  public render() {

    if (this.props.SqtReportStore.RootStore.loadingCount > 0) {
      return <LoaderOverlay />;
    }

    return (
      <div className="sqtReportComponent">
        <div className="mainHeader">SqT biztosítási ajánlat</div>
        <div className="darkerBcground">
          {/* Felhasználó */}
          {this.props.SqtReportStore.RootStore.renderHeader()}
          <div className='mainPanel'>
            {this.props.SqtReportStore.sqtData ? this.renderSqtOffer() : this.renderQueryButton()}
          </div>
        </div>
        {this.renderDialogs()}
      </div>
    );
  }
  componentDidMount() {
    this.props.SqtReportStore.id = this.props.params.id;
    if (this.props.SqtReportStore.id) {
      this.props.SqtReportStore.getOfferById(this.props.SqtReportStore.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.SqtReportStore.CodeInput) {
      this.props.SqtReportStore.CodeInput.focus();
    }
  }

  private _onFormatDate = (date: Date): string => {
    return date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
  };


  public renderDialogs() {
    return (<div>
      <Dialog
        hidden={!this.props.SqtReportStore.showWelcomeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Figyelem",
          showCloseButton: false
        }}
      >
        <div style={{ maxWidth: '400px' }}>

          <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>
            Media Markt Garancia Plusz biztosítás jelenleg csak fogyasztó
            által vásárolt készülékekre, magánszemély szerződőként vehető igénybe

          </div>
          <div>
            <div>
              Termék származásának beazonosítása:
            </div>
            <div style={{ paddingLeft: '10px' }}>
              - <span style={{ color: 'green', fontWeight: 'bold' }}>Saját áruházban vásárolt termék:</span> G+ ajánlat rögzítése saját áruházban
              megvásárolt termékre vásárláskor vagy azt követő 30 napon belül

            </div>
            <div style={{ paddingLeft: '10px' }}>
              - <span style={{ color: 'green', fontWeight: 'bold' }}>Másik áruházban vásárolt termék:</span> G+ ajánlat rögzítése az elmúlt 30
              napban másik áruházban vásárolt termékre (áruházi vásárlás vagy online vásárlás áruházi átvétellel)


            </div>
            <div style={{ paddingLeft: '10px' }}>
              - <span style={{ color: 'green', fontWeight: 'bold' }}>Online vásárolt termék saját áruházi átvétellel:</span> G+ ajánlat rögzítése az
              elmúlt 30 online vásárolt és saját áruházban átvett termékre


            </div>
          </div>

          <div style={{ color: 'red', fontWeight: 'bold', marginTop: '20px' }}>
            G+ ajánlat csak a kötelező személyes adatokat (név, cím, telefon, e-mail, illetve
            telefonok esetében IMEI szám) tartalmazó bizonylat alapján rögzíthető!


          </div>
        </div>

        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Tovább"
            onClick={() => this.props.SqtReportStore.hideDialog('showWelcomeDialog')}
          />
        </div>
      </Dialog>
      <Dialog
        hidden={!this.props.SqtReportStore.showMaganSzemelyDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Adószám",
          showCloseButton: false
        }}
      >
        <div>Biztosítási ajánlat csak magánszemély részére állítható ki!</div>

        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Magánszemély megadása"
            onClick={() => {
              this.props.SqtReportStore.onValueChanged('Vezeteknev', '', this.props.SqtReportStore.sqtData);
              this.props.SqtReportStore.onValueChanged('Keresztnev', '', this.props.SqtReportStore.sqtData);
              this.props.SqtReportStore.onValueChanged('TeljesNev', '', this.props.SqtReportStore.sqtData);
              this.props.SqtReportStore.hideDialog('showMaganSzemelyDialog')
            }}
          />

          <DefaultButton
            className="ui button"
            text="A szerződő magánszemély"
            onClick={() => this.props.SqtReportStore.hideDialog('showMaganSzemelyDialog')}
          />
        </div>
      </Dialog>
      <Dialog
        hidden={!this.props.SqtReportStore.showGyartasiSzamDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Gyártási szám",
          showCloseButton: false
        }}
      >
        {this.props.SqtReportStore.showGyartasiSzamDialog && <div>A {this.props.SqtReportStore.sqtData.Termekek[this.props.SqtReportStore.gyartasiSzamIndex].Gyarto_Tipus} terméknél a Gyártási szám nincs kitöltve!</div>}

        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Gyártási szám megadása"
            onClick={() => {
              this.props.SqtReportStore.onValueChanged('gyartasiSzamIndex', null);
              this.props.SqtReportStore.hideDialog('showGyartasiSzamDialog')
            }}
          />

          <DefaultButton
            className="ui button"
            text="Értékesítéskor NEM ismert"
            onClick={() => {
              this.props.SqtReportStore.onValueChanged('GyartasiSzam', 'nem ismert', this.props.SqtReportStore.sqtData.Termekek[this.props.SqtReportStore.gyartasiSzamIndex])
              this.props.SqtReportStore.onValueChanged('gyartasiSzamIndex', null);
              this.props.SqtReportStore.hideDialog('showGyartasiSzamDialog');
            }}
          />
        </div>
      </Dialog>

      <Dialog
        hidden={!this.props.SqtReportStore.showAjanlatGeneralasa}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Teendők",
          showCloseButton: false
        }}
      >
        <div style={{ marginBottom: "20px", textAlign: 'center' }}>
          {!this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Összes dokumentum letöltése"
            onClick={() => this.props.SqtReportStore.getAllDocuments()}
          />}
          {
            this.props.SqtReportStore.RootStore.TelesalesMode &&
            <div style={{ margin: "20px 0", width: '400px' }}> <b>Az ajánlathoz kapcsolódóan az alábbi dokumentumok kerülnek kiküldésre.
            </b></div>
          }
        </div>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          Biztosítási termék tájékoztató (IPID)

        </div>
        <div>
          {!this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.ipidDocuments.map(s =>

            <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
              s.Downloaded = true;
              this.props.SqtReportStore.getIpidDocument(s.Value, s.Name);
            }}> {s.Name}
            </div>

          )}
          {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.ipidDocuments.map(s =>

            <div > {s.Name}
            </div>

          )}

        </div>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          Előzetes tájékoztató

        </div>
        <div>
          {!this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.etDocuments.map(s =>

            <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
              s.Downloaded = true;
              this.props.SqtReportStore.GetETDocument(s.Value, s.Name);
            }}> {s.Name}
            </div>

          )}
          {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.etDocuments.map(s =>

            <div > {s.Name}
            </div>

          )}

        </div>
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
          Általános Szerződési feltételek

        </div>
        <div>
          {!this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.aszfDocuments.map(s =>

            <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => {
              s.Downloaded = true;
              this.props.SqtReportStore.GetASZFDocument(s.Value, s.Name);
            }}> {s.Name}
            </div>

          )}
          {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.aszfDocuments.map(s =>

            <div > {s.Name}
            </div>

          )}

        </div>


        <div style={{ margin: "20px 0", fontWeight: 'bold', marginBottom: '5px' }}>Milyen nyelven szeretné a dokumentumot?</div>
        <div>
          <div className="inputContainer half inline">
            <TextField
              label=""
              value={'Magyar'}
              disabled={true}
              onChange={(ev, value) => {
              }}
            />
          </div>
        </div>

        {!this.props.SqtReportStore.RootStore.TelesalesMode && <div style={{ margin: "20px 0", color: 'red', width: '400px' }}> <b>A G+ ajánlat létrehozása előtt KÖTELEZŐ a három előzetes
          dokumentum kinyomtatása és átadása a vásárló számára!
        </b></div>}

        <div style={{ margin: "20px" }}>
          {!this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Ajánlati dokumentum létrehozása"
            disabled={this.props.SqtReportStore.ipidDocuments.findIndex(s => !s.Downloaded) > -1 || this.props.SqtReportStore.etDocuments.findIndex(s => !s.Downloaded) > -1 || this.props.SqtReportStore.aszfDocuments.findIndex(s => !s.Downloaded) > -1}
            onClick={() => {
              this.props.SqtReportStore.onReportSubmit(true);
            }}
          />}
          {this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
            className="ui button"
            text='Ajánlat küldése jóváhagyásra'
            disabled={!this.props.SqtReportStore.getSelectedInsurance}
            onClick={() => this.props.SqtReportStore.sendNextStep(200, true)} />}
          <DefaultButton
            className="ui button"
            text="Mégsem"
            onClick={() => {
              this.props.SqtReportStore.hideDialog('showAjanlatGeneralasa');
            }}
          />
        </div>
      </Dialog>
      {!this.props.SqtReportStore.RootStore.TelesalesMode && <Dialog
        hidden={!this.props.SqtReportStore.showWawiData}
        dialogContentProps={{
          type: DialogType.normal,
          title: "WAWI adatok",
          showCloseButton: false
        }}
      >
        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
          {(this.props.SqtReportStore.sqtData && (this.props.SqtReportStore.sqtData.OriginalQueryResult.length > 0)) && this.renderOriginalQueryTable()}
        </div>
        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Bezárás"
            onClick={() => this.props.SqtReportStore.hideDialog('showWawiData')}
          />
        </div>
      </Dialog>}

      <Dialog
        hidden={!this.props.SqtReportStore.showAjanlatGeneralasaVege}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Ajánlat generálása sikeres",
          showCloseButton: false
        }}
      >

        <div style={{ marginBottom: '5px' }}>
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            Teendők:
          </div>
          <div>
            -	a G+ ajánlat két példányát írasd alá a vásárlóval
          </div>
          <div>
            -	add át a példányokat a vásárlónak és jelezd, hogy a fizetéskor adja
            át a dokumentumokat a pénztáros munkatársadnak

          </div>
        </div>


        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'grey', marginRight: '20px' }}
            text='Vissza a főoldalra'
            onClick={() => this.props.SqtReportStore.RootStore.GoToHome(1)} />
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Ajánlat újranyomtatása"
            onClick={() => {
              this.props.SqtReportStore.onReportSubmit(true);
            }}
          />

          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Új ajánlat készítése"
            onClick={() => {
              this.props.SqtReportStore.hideDialog('showAjanlatGeneralasaVege');
              this.props.SqtReportStore.PurchaseOption = null;
              this.props.SqtReportStore.SelectedStore = null;
              this.props.SqtReportStore.Code = null;
              this.props.SqtReportStore.sqtData = null;
              this.props.SqtReportStore.showWelcomeDialog = true;

            }}
          />
        </div>
      </Dialog>
    </div>)
  }

  public renderOriginalQueryTable() {
    const entries = Object.keys(this.props.SqtReportStore.sqtData.OriginalQueryResult[0]);

    return (<table style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>
      <tr>{entries.map(s => <th style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s}</th>)}</tr>
      {this.props.SqtReportStore.sqtData.OriginalQueryResult.map(s => <tr>{entries.map(z => <td style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s[z]}</td>)}</tr>)}
    </table>)

  }

  public renderQueryButton() {
    return (<div className='container'>
      <div className='mainContainer'>

        <div>
          <div className="inputContainer half inline">
            <Dropdown
              label="Termék származásának beazonosítása"
              selectedKey={this.props.SqtReportStore.PurchaseOption}

              options={this.props.SqtReportStore.purchaseOptions.map(s => {
                return {
                  key: s.value,
                  text: s.text
                }
              })}
              onChange={(ev, value) => {
                this.props.SqtReportStore.onValueChanged('PurchaseOption', value.key);
                if (value.key == 3) {
                  this.props.SqtReportStore.SelectedStore = null;
                  this.props.SqtReportStore.SelectedStoreSapCode = null;
                }
              }}
            ></Dropdown>
          </div>
        </div>
        {this.props.SqtReportStore.PurchaseOption == 2 && <div>
          <div className="inputContainer half inline">
            <Dropdown
              key='SelectedStore'
              label='Áruház kiválasztása'
              options={this.props.SqtReportStore.RootStore.Stores
                .filter(s => s.Code != this.props.SqtReportStore.RootStore.SelectedStore && s.Code != '726')
                .map(s => {
                  return {
                    key: s.Code,
                    text: `(${s.SapCode}) ${s.Name}`
                  }
                })}
              selectedKey={this.props.SqtReportStore.SelectedStore}
              onChanged={(option: IDropdownOption) => {
                this.props.SqtReportStore.onValueChanged('SelectedStore', option.key);
                let store = this.props.SqtReportStore.RootStore.Stores.find(s => s.Code == option.key);
                if (store) {
                  this.props.SqtReportStore.SelectedStoreSapCode = store.SapCode;
                }
              }}
            />
          </div>
        </div>}

        <div className="inputContainer half inline">
          <TextField
            label="Bizonylat száma"
            value={this.props.SqtReportStore.Code}
            componentRef={(ref) => this.props.SqtReportStore.CodeInput = ref}
            onKeyDown={(event) => {
              if (event.keyCode === 13 && this.props.SqtReportStore.Code) {
                this.props.SqtReportStore.onSubmit();
              }
            }}
            onChange={(ev, value) => {
              this.props.SqtReportStore.onValueChanged('Code', value);
            }}
          />
          {this.props.SqtReportStore.showBizonylatError && <MessageBar messageBarType={MessageBarType.error}>{this.props.SqtReportStore.Code} számú bizonylat nem található!</MessageBar>}
        </div>
      </div>

      <div className='mainButtons' style={{ textAlign: 'center' }}>
        <PrimaryButton
          className="ui button"
          text='Lekérdezés'
          onClick={() => this.props.SqtReportStore.onSubmit()} />
      </div>
    </div>)
  }

  public renderSqtOffer() {
    return (<div className='container'>
      <div className='mainContainer'>
        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("Osszesito")}>
            {!this.props.SqtReportStore.RootStore.TelesalesMode && <span>Összesítő</span>}
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('Osszesito') == -1 &&
            <div className="panelContainer">
              <div className="row">
                {this.props.SqtReportStore.RootStore.TelesalesMode && <div className="inputContainer half inline">
                  <TextField
                    label="Státusz"
                    value={this.props.SqtReportStore.sqtData.StatusString}
                    disabled={true}
                    onChange={(ev, value) => {
                    }}
                  />
                </div>}
                {!this.props.SqtReportStore.RootStore.TelesalesMode && <div className="inputContainer half inline">
                  <TextField
                    label="Bizonylatszám"
                    value={this.props.SqtReportStore.sqtData.Bizonylatszam}
                    disabled={true}
                    onChange={(ev, value) => {
                    }}
                  />
                </div>}
                <div className="inputContainer half inline" style={{ verticalAlign: 'bottom' }}>
                  {!this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
                    className="ui button"
                    text='Új bizonylatszám megadása'
                    onClick={() => this.props.SqtReportStore.onNewQuery()} />}
                  {!this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
                    className="ui button"
                    style={{ marginLeft: '20px' }}
                    text='WAWI'
                    onClick={() => this.props.SqtReportStore.onValueChanged("showWawiData", true)} />}
                </div>
              </div>
            </div>}
        </div>
        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("Osszesito2")}>
            Biztosítás értékesítő azonosító
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('Osszesito2') == -1 &&
            <div className="panelContainer">
              <div className="row">
                <div className="inputContainer half inline">

                  {this.props.SqtReportStore.RootStore.TelesalesMode &&

                    <table>
                      <tr>
                        <td>
                          <TextField
                            maxLength={5}
                            required={true}

                            label="Operátor azonosító"
                            value={this.props.SqtReportStore.sqtData.OperatorAzonositoja}
                            disabled={true}

                          />
                        </td>
                        <td>
                          <TextField
                            maxLength={5}
                            required={true}
                            errorMessage={this.props.SqtReportStore.getErrorMessage('SzaktanacsadoKodja', "Értékesítő azonosító", this.props.SqtReportStore.sqtData)}
                            disabled={this.props.SqtReportStore.sqtData.Status > 100}
                            label="Ügyintéző azonosító"
                            value={this.props.SqtReportStore.sqtData.SzaktanacsadoKodja}
                            onChange={(ev, value) => {
                              this.props.SqtReportStore.onValueChanged('SzaktanacsadoKodja', value, this.props.SqtReportStore.sqtData);
                              this.props.SqtReportStore.generateOfferNumber();
                            }}
                          />
                        </td>
                      </tr>
                    </table>
                  }
                  {!this.props.SqtReportStore.RootStore.TelesalesMode && <table>
                    <tr>
                      <td>
                        {!this.props.SqtReportStore.selectedUserEdit && <Dropdown
                          key='SelectedStores'
                          label='Értékesítő azonosító'
                          options={this.props.SqtReportStore.wawiUsers
                            .map(s => {
                              return {
                                key: s.WawiCode,
                                text: `${s.UserName} (${s.WawiCode})`
                              }
                            })}
                          required={true}
                          errorMessage={this.props.SqtReportStore.getErrorMessage('SzaktanacsadoKodja', "Értékesítő azonosító", this.props.SqtReportStore.sqtData)}
                          selectedKey={this.props.SqtReportStore.sqtData.SzaktanacsadoKodja}
                          onChanged={(option: IDropdownOption) => {
                            this.props.SqtReportStore.onValueChanged('SzaktanacsadoKodja', option.key, this.props.SqtReportStore.sqtData);
                            this.props.SqtReportStore.generateOfferNumber();

                          }}
                        />}
                        {
                          this.props.SqtReportStore.selectedUserEdit &&
                          <TextField
                            maxLength={5}
                            required={true}
                            errorMessage={this.props.SqtReportStore.getErrorMessage('SzaktanacsadoKodja', "Értékesítő azonosító", this.props.SqtReportStore.sqtData)}

                            label="Értékesítő azonosító"
                            value={this.props.SqtReportStore.sqtData.SzaktanacsadoKodja}
                            onChange={(ev, value) => {
                              this.props.SqtReportStore.onValueChanged('SzaktanacsadoKodja', value, this.props.SqtReportStore.sqtData);
                              this.props.SqtReportStore.generateOfferNumber();
                            }}
                          />
                        }
                      </td>
                      <td>
                        <img src={this.transferSalesSrc} onClick={() => this.props.SqtReportStore.selectedUserEdit = !this.props.SqtReportStore.selectedUserEdit} style={{ width: '22px', marginTop: '28px', cursor: 'pointer' }} alt="" />

                      </td>
                    </tr>
                  </table>}

                </div>
                <div className="inputContainer half inline" style={{ verticalAlign: 'bottom' }}>

                </div>
              </div>
            </div>}
        </div>
        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("SzerzodoUgyfelAdatai")}>
            Ügyfél adatok
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('SzerzodoUgyfelAdatai') == -1 &&
            <div className="panelContainer">
              {!this.props.SqtReportStore.sqtData.Vezeteknev && !this.props.SqtReportStore.RootStore.TelesalesMode &&
                <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
                  Figyelem! Vezetéknév hiányában ajánlat nem készíthető!

                </div>
              }
              {!this.props.SqtReportStore.sqtData.Keresztnev && !this.props.SqtReportStore.RootStore.TelesalesMode &&
                <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
                  Figyelem! Utónév hiányában ajánlat nem készíthető!

                </div>
              }
              {!this.props.SqtReportStore.sqtData.MobilTelefonszam && !this.props.SqtReportStore.RootStore.TelesalesMode &&
                <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
                  Figyelem! Kapcsolattartó telefonszáma hiányában ajánlat nem készíthető!

                </div>
              }
              {!this.props.SqtReportStore.sqtData.Email && !this.props.SqtReportStore.RootStore.TelesalesMode &&
                <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
                  Figyelem! E-mail cím hiányában ajánlat nem készíthető!

                </div>
              }
              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Vezetéknév"
                    value={this.props.SqtReportStore.sqtData.Vezeteknev}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Vezeteknev', "Vezetéknév", this.props.SqtReportStore.sqtData)}
                    disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Vezeteknev', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('TeljesNev', `${this.props.SqtReportStore.sqtData.Vezeteknev} ${this.props.SqtReportStore.sqtData.Keresztnev}`, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Utónév"
                    disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}

                    value={this.props.SqtReportStore.sqtData.Keresztnev}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Keresztnev', "Utónév", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Keresztnev', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('TeljesNev', `${this.props.SqtReportStore.sqtData.Vezeteknev} ${this.props.SqtReportStore.sqtData.Keresztnev}`, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer full">
                  <TextField
                    label="Teljes név"
                    value={this.props.SqtReportStore.sqtData.TeljesNev}
                    disabled={true}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Vezeteknev', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                {/* <div className="inputContainer half inline">
                  <TextField
                    label="Születési hely"
                    value={this.props.SqtReportStore.sqtData.SzuletesiHely}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('SzuletesiHely', "Születési hely")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('SzuletesiHely', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div> */}
                <div className="inputContainer half inline">
                  {!this.props.SqtReportStore.RootStore.TelesalesMode && <DatePicker
                    label="Születési idő"
                    allowTextInput
                    value={this.props.SqtReportStore.sqtData.SzuletesiIdo}
                    placeholder={"YYYY.MM.DD."}
                    isRequired={true}
                    onSelectDate={(date: Date) => { this.props.SqtReportStore.onValueChanged('SzuletesiIdo', date, this.props.SqtReportStore.sqtData) }}
                    formatDate={(date: Date) => { return date.toLocaleDateString('hu-HU') }}
                    //  textField={{maskFormat:{'/^\d{4}-\d{2}-\d{2}$/'}}}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={DayPickerText}
                    isMonthPickerVisible={true}
                    maxDate={new Date()}
                    parseDateFromString={
                      (date: string) => {
                        let debug = 1;
                        return new Date(date)
                      }}

                  />}
                  {
                    this.props.SqtReportStore.RootStore.TelesalesMode &&
                    <Checkbox
                      label="Betöltött 18. életév"
                      checked={this.props.SqtReportStore.sqtData.Betoltott18}
                      disabled={this.props.SqtReportStore.sqtData.Status > 100}
                      onChange={(ev, value) => {
                        this.props.SqtReportStore.onSelectChanged('Betoltott18', value, this.props.SqtReportStore.sqtData);


                      }}
                    />
                  }
                  {this.props.SqtReportStore.getErrorMessage('Betoltott18', "Betöltött 18. életév", this.props.SqtReportStore.sqtData) && <Label className="errorMessageLabel">{this.props.SqtReportStore.getErrorMessage('Betoltott18', "Betöltött 18. életév", this.props.SqtReportStore.sqtData)}</Label>}
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Kapcsolattartó telefonszáma (nemzetközi előhívóval)"
                    value={this.props.SqtReportStore.sqtData.MobilTelefonszam}
                    required={true}
                    disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}

                    errorMessage={this.props.SqtReportStore.getErrorMessage('MobilTelefonszam', "Kapcsolattartó telefonszáma", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('MobilTelefonszam', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              {/* <div className="row">
                <div className="inputContainer full inline">
                  <TextField
                    label="Anyja neve"
                    value={this.props.SqtReportStore.sqtData.AnyjaNeve}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('AnyjaNeve', "Anyja neve")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('AnyjaNeve', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div> */}

              {/* <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Telefonszám"
                    value={this.props.SqtReportStore.sqtData.Telefonszam}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Telefonszam', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Telefonszám (mobil)"
                    value={this.props.SqtReportStore.sqtData.MobilTelefonszam}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('MobilTelefonszam', "Telefonszám (mobil)")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('MobilTelefonszam', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div> */}

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="E-mail cím"
                    value={this.props.SqtReportStore.sqtData.Email}
                    required={true}
                    disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Email', "E-mail cím", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Email', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>
            </div>}
        </div>

        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("AllandoLakcim")}>
            Lakcím
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('AllandoLakcim') == -1 &&
            <div className="panelContainer">
              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Irányítószám"
                    value={this.props.SqtReportStore.sqtData.Allando_Iranyitoszam}
                    required={true}
                    disabled={this.props.SqtReportStore.sqtData.Status > 100}
                    type="number"
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Allando_Iranyitoszam', "Állandó irányítószám", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_Iranyitoszam', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', this.props.SqtReportStore.getFullAddress(true), this.props.SqtReportStore.sqtData);

                      if (this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Iranyitoszam', value, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Város"
                    value={this.props.SqtReportStore.sqtData.Allando_Varos}
                    required={true}
                    disabled={this.props.SqtReportStore.sqtData.Status > 100}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Allando_Varos', "Állandó város", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_Varos', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', this.props.SqtReportStore.getFullAddress(true), this.props.SqtReportStore.sqtData);

                      if (this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Varos', value, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Közterület neve"
                    value={this.props.SqtReportStore.sqtData.Allando_KozteruletNeve}
                    required={true}
                    disabled={this.props.SqtReportStore.sqtData.Status > 100}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Allando_KozteruletNeve', "Állandó közterület neve", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_KozteruletNeve', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', this.props.SqtReportStore.getFullAddress(true), this.props.SqtReportStore.sqtData);

                      if (this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletNeve', value, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Közterület jellege"
                    value={this.props.SqtReportStore.sqtData.Allando_KozteruletJellege}
                    required={true}
                    disabled={this.props.SqtReportStore.sqtData.Status > 100}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Allando_KozteruletJellege', "Állandó közterület jellege", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_KozteruletJellege', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', this.props.SqtReportStore.getFullAddress(true), this.props.SqtReportStore.sqtData);

                      if (this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletJellege', value, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Házszám"
                    value={this.props.SqtReportStore.sqtData.Allando_Hazszam}
                    disabled={this.props.SqtReportStore.sqtData.Status > 100}
                    required={true}
                    type="number"
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Allando_Hazszam', "Állandó házszám", this.props.SqtReportStore.sqtData)}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_Hazszam', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', this.props.SqtReportStore.getFullAddress(true), this.props.SqtReportStore.sqtData);

                      if (this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Hazszam', value, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer full">
                  <TextField
                    label="Ajánlatba kerülő cím"
                    value={this.props.SqtReportStore.sqtData.Allando_TeljesCim}
                    disabled={true}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Allando_TeljesCim', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>
            </div>}
        </div>

        {/* <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("LevelezesiLakcim")}>
            Levelezési cím
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('LevelezesiLakcim') == -1 &&
            <div className="panelContainer">
              <div className="row">
                <div className="inputContainer full">
                  <Checkbox
                    label="Megegyezik az Állandó lakcímmel"
                    checked={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onSelectChanged('AllandoCim_LevelezesiCim_Megegyezik', value, this.props.SqtReportStore.sqtData);

                      if (value) {
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Iranyitoszam', this.props.SqtReportStore.sqtData.Allando_Iranyitoszam, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Varos', this.props.SqtReportStore.sqtData.Allando_Varos, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletNeve', this.props.SqtReportStore.sqtData.Allando_KozteruletNeve, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletJellege', this.props.SqtReportStore.sqtData.Allando_KozteruletJellege, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_Hazszam', this.props.SqtReportStore.sqtData.Allando_Hazszam, this.props.SqtReportStore.sqtData);
                        this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Irányítószám"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_Iranyitoszam}
                    disabled={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Levelezesi_Iranyitoszam', "Levelezési irányítószám")}
                    type="number"
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_Iranyitoszam', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Város"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_Varos}
                    disabled={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Levelezesi_Varos', "Levelezési város")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_Varos', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Közterület neve"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_KozteruletNeve}
                    disabled={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Levelezesi_KozteruletNeve', "Levelezési közterület neve")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletNeve', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
                <div className="inputContainer half inline">
                  <TextField
                    label="Közterület jellege"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_KozteruletJellege}
                    disabled={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Levelezesi_KozteruletJellege', "Levelezési közterület jellege")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_KozteruletJellege', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer half inline">
                  <TextField
                    label="Házszám"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_Hazszam}
                    disabled={this.props.SqtReportStore.sqtData.AllandoCim_LevelezesiCim_Megegyezik}
                    required={true}
                    errorMessage={this.props.SqtReportStore.getErrorMessage('Levelezesi_Hazszam', "Levelezési házszám")}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_Hazszam', value, this.props.SqtReportStore.sqtData);
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', this.props.SqtReportStore.getFullAddress(false), this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputContainer full">
                  <TextField
                    label="Ajánlatba kerülő cím"
                    value={this.props.SqtReportStore.sqtData.Levelezesi_TeljesCim}
                    disabled={true}
                    onChange={(ev, value) => {
                      this.props.SqtReportStore.onValueChanged('Levelezesi_TeljesCim', value, this.props.SqtReportStore.sqtData);
                    }}
                  />
                </div>
              </div>
            </div>}
        </div> */}

        {this.props.SqtReportStore.sqtData.Termekek.map((item, index) => { return this.renderSqtItems(item, index) })}


        {this.props.SqtReportStore.RootStore.TelesalesMode && <div className='panel'>
          <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked("Dokumentumok")}>
            Dokumentumok
          </div>
          {this.props.SqtReportStore.closedPanels.indexOf('Dokumentumok') == -1 &&
            <div className="panelContainer">

              <table style={{ border: '1px solid grey', borderCollapse: 'collapse', width: '97%', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>
                <tr>

                  <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>

                  </th>
                  <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                    Dokumentum neve
                  </th>

                </tr>
                {this.props.SqtReportStore.documents.map((s, insuranceIndex) =>
                  <tr >
                    <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                      <PrimaryButton
                        className="ui button"
                        style={{
                          backgroundColor: 'red', height: '20px',
                          padding: '0px',
                          fontSize: '10px',
                          width: '92px',
                          minWidth: '20px'
                        }}
                        text="Letöltés"
                        onClick={() => this.props.SqtReportStore.GetOfferDocumentById(s.Id)}
                      />
                    </td>
                    <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                      {s.Name}
                    </td>

                  </tr>

                )}
              </table>





            </div>}
        </div>}


      </div>

      {(this.props.SqtReportStore.isSubmitted && (this.props.SqtReportStore.errors.length > 0)) && <MessageBar className="errorMessageBar" messageBarType={MessageBarType.error}>
        <ul>
          {this.props.SqtReportStore.errors.map(s => <li className="errorLi" key={s.key}>{s.value}</li>)}
        </ul>
      </MessageBar>}

      <div className='mainButtons'>
        {!this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
          className="ui button"
          text='Ajánlati dokumentum'
          disabled={!this.props.SqtReportStore.getSelectedInsurance}
          onClick={() => this.props.SqtReportStore.onReportSubmit(false)} />}
        {/* {this.props.SqtReportStore.RootStore.TelesalesMode && <PrimaryButton
          className="ui button"
          text='Mentés'
          disabled={!this.props.SqtReportStore.getSelectedInsurance}
          onClick={() => this.props.SqtReportStore.saveOffer()} />} */}
        {this.props.SqtReportStore.RootStore.TelesalesMode && (this.props.SqtReportStore.sqtData.Status == 0 || this.props.SqtReportStore.sqtData.Status == 100) && <PrimaryButton
          className="ui button"
          text='Ajánlat küldése jóváhagyásra'
          disabled={!this.props.SqtReportStore.getSelectedInsurance}
          onClick={() => this.props.SqtReportStore.sendNextStep(200, false)} />}

        {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.sqtData.Status == 400 && <PrimaryButton
          className="ui button"
          text='Fizetett ajánlat'
          disabled={!this.props.SqtReportStore.getSelectedInsurance}
          onClick={() => this.props.SqtReportStore.sendNextStep(1000, true)} />}
        {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.sqtData.Status > 100 && <PrimaryButton
          className="ui button"
          text='Törlés'
          disabled={!this.props.SqtReportStore.getSelectedInsurance}
          onClick={() => this.props.SqtReportStore.sendNextStep(2500, true)} />}
      </div>
    </div>)
  }

  public renderSqtItems(item: ISqtItem, index: number) {
    return (<div className='panel' key={index}>
      <div className='title' onClick={() => this.props.SqtReportStore.onClosePanelClicked(`BiztositottTermekAdatai-${index}`)}>
        {index + 1}. Biztosított eszköz adatai ({item.Gyarto_Tipus})
      </div>
      {item.IsMobile && !item.GyartasiSzam &&
        <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
          Figyelem! IMEI szám hiányában ajánlat nem készíthető erre a termékre!

        </div>
      }
      {item.Inactive &&
        <div style={{ padding: '10px', textAlign: 'center', color: 'red' }}>
          Inaktív

        </div>
      }

      {this.props.SqtReportStore.closedPanels.indexOf(`BiztositottTermekAdatai-${index}`) == -1 &&
        <div className="panelContainer">
          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Cikkszám"
                value={item.Cikkszam}
                disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('Cikkszam', value, item);
                }}
              />
            </div>
            <div className="inputContainer half inline">
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label="EAN"
                value={item.EAN}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('EAN', value, item);
                }}
              />}
              {this.props.SqtReportStore.RootStore.TelesalesMode &&
                <TextField
                  label="Biztosított eszköz vételára"
                  type="number"
                  value={item.Vetelar ? `${item.Vetelar.toString()}` : ''}
                  disabled={false || this.props.SqtReportStore.sqtData.Status > 100}
                  onChange={(ev, value) => {
                    this.props.SqtReportStore.onValueChanged('Vetelar', value, item);
                  }}
                />
              }
            </div>
            {/* <div className="inputContainer half inline">
              <TextField
                label="Árucsoport"
                value={item.Arucsoport}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('Arucsoport', value, item);
                }}
              />
            </div> */}
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label="Gyártó, típus"
                value={item.Gyarto_Tipus}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('Gyarto_Tipus', value, item);
                }}
              />}
              {this.props.SqtReportStore.RootStore.TelesalesMode &&
                <DatePicker
                  label="Vásárlás dátuma"
                  allowTextInput
                  value={item.VasarlasDatuma}
                  placeholder={"YYYY.MM.DD."}
                  isRequired={true}
                  onSelectDate={(date: Date) => {

                    this.props.SqtReportStore.onValueChanged('VasarlasDatuma', date, item);

                    // let date2 = new Date(date.getTime());
                    // let date1 = new Date();
                    // date2.setDate(date2.getDate() + 30);
                    // let diffTime = Math.abs(date2.getTime() - date1.getTime());
                    // let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    var date2 = new Date(date.getTime());
                    var date1 = new Date();
                    date2.setDate(date2.getDate() + 31);
                    // To calculate the time difference of two dates
                    var Difference_In_Time = date2.getTime() - date1.getTime();

                    // To calculate the no. of days between two dates
                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    this.props.SqtReportStore.sqtData.HatralevoNapokSzama = Math.round(Difference_In_Days);
                    for (let i = 0; i < this.props.SqtReportStore.sqtData.Termekek.length; i++) {
                      const element = this.props.SqtReportStore.sqtData.Termekek[i];
                      this.props.SqtReportStore.onValueChanged('VasarlasDatuma', date, this.props.SqtReportStore.sqtData.Termekek[i]);

                    }

                  }}
                  formatDate={(date: Date) => { return date.toLocaleDateString('hu-HU') }}
                  //  textField={{maskFormat:{'/^\d{4}-\d{2}-\d{2}$/'}}}
                  firstDayOfWeek={DayOfWeek.Monday}
                  strings={DayPickerText}
                  isMonthPickerVisible={true}
                  maxDate={new Date()}
                  disabled={this.props.SqtReportStore.sqtData.Status > 100 || index > 0}
                  parseDateFromString={
                    (date: string) => {
                      let debug = 1;
                      return new Date(date)
                    }}

                />
              }
            </div>

            <div className="inputContainer half inline">
              {this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label={"Gyártási/IMEI szám"}
                value={item.GyartasiSzam}
                required={item.IsMobile}
                disabled={item.IsMobile || this.props.SqtReportStore.sqtData.Status > 100}
                errorMessage={item.IsMobile && this.props.SqtReportStore.getErrorMessage('GyartasiSzam', "IMEI szám", item)}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('GyartasiSzam', value, item);
                }}
              />}
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label={item.IsMobile ? "IMEI szám" : "Gyártási szám"}
                value={item.GyartasiSzam}
                required={item.IsMobile}
                disabled={item.IsMobile || this.props.SqtReportStore.sqtData.Status > 100}
                errorMessage={item.IsMobile && this.props.SqtReportStore.getErrorMessage('GyartasiSzam', "IMEI szám", item)}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('GyartasiSzam', value, item);
                }}
              />}
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Kiskereskedő"
                value={item.VasarlasHelye}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('VasarlasHelye', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label="Áruház azonosító"
                value={item.AruhazAzonosito}
                disabled={!this.props.SqtReportStore.RootStore.TelesalesMode || this.props.SqtReportStore.sqtData.Status > 100}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('AruhazAzonosito', value, item);
                }}
              />}
              {this.props.SqtReportStore.RootStore.TelesalesMode &&
                <Dropdown
                  key='SelectedStore'
                  label='Áruház kiválasztása'
                  options={this.props.SqtReportStore.RootStore.Stores

                    .map(s => {
                      return {
                        key: s.Code,
                        text: `(${s.SapCode}) ${s.Name}`
                      }
                    })}
                  disabled={this.props.SqtReportStore.sqtData.Status > 100}
                  selectedKey={item.AruhazAzonosito}
                  onChanged={(option: IDropdownOption) => {
                    this.props.SqtReportStore.onValueChanged('AruhazAzonosito', option.key, item);
                    // this.props.SqtReportStore.onValueChanged('SelectedStore', option.key);
                    // let store = this.props.SqtReportStore.RootStore.Stores.find(s => s.Code == option.key);
                    // if (store) {
                    //   this.props.SqtReportStore.SelectedStoreSapCode = store.SapCode;
                    // }
                  }}
                />

              }
            </div>

          </div>

          <div className="row">
            <div className="inputContainer half inline">
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label="Vásárlás dátuma"
                value={new Date(item.VasarlasDatuma).toLocaleDateString('hu-HU')}
                disabled={!this.props.SqtReportStore.RootStore.TelesalesMode}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('VasarlasDatuma', value, item);
                }}
              />}
              {this.props.SqtReportStore.RootStore.TelesalesMode &&

                <TextField
                  label="Gyártó, típus"
                  value={item.Gyarto_Tipus}
                  disabled={true}
                  onChange={(ev, value) => {
                    this.props.SqtReportStore.onValueChanged('Gyarto_Tipus', value, item);
                  }}
                />


              }
            </div>
            <div className="inputContainer half inline">
              {!this.props.SqtReportStore.RootStore.TelesalesMode && <TextField
                label="Biztosított eszköz vételára"
                value={item.Vetelar ? `${item.Vetelar.toString()} Ft` : ''}
                disabled={true}

                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('Vetelar', value, item);
                }}
              />}
              {this.props.SqtReportStore.RootStore.TelesalesMode &&
                <TextField
                  label="EAN"
                  value={item.EAN}
                  disabled={true}
                  onChange={(ev, value) => {
                    this.props.SqtReportStore.onValueChanged('EAN', value, item);
                  }}
                />
              }
            </div>


          </div>
          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Biztosított eszköz árkategóriája"
                value={item.Arkategoria}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtReportStore.onValueChanged('Arkategoria', value, item);
                }}
              />
              {this.props.SqtReportStore.RootStore.TelesalesMode &&
                <TextField
                  label="Ajánlat kötésére rendelkezésre álló idő"
                  value={this.props.SqtReportStore.sqtData.HatralevoNapokSzama?.toString()}
                  disabled={true}

                />}
            </div>

          </div>

          {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.sqtData.Status < 200 && <div style={{ margin: "20px" }}>
            <PrimaryButton
              className="ui button"
              style={{ backgroundColor: 'red', marginRight: '20px' }}
              text="Keresés"
              disabled={!item.Cikkszam || !item.VasarlasDatuma || !item.Vetelar}
              onClick={() => {
                this.props.SqtReportStore.onGetArticleDataTelesales(index);
              }}
            />


          </div>}
          {this.props.SqtReportStore.RootStore.TelesalesMode && this.props.SqtReportStore.sqtData.Status == 400 && !item.Inactive && <div style={{ margin: "20px" }}>
            <PrimaryButton
              className="ui button"
              style={{ backgroundColor: 'red', marginRight: '20px' }}
              text="Inaktiválás"
              onClick={() => {
                this.props.SqtReportStore.setInactive(index);
              }}
            />


          </div>}
          <div className='panel'>
            <div className='title' style={{ background: '#a51716' }} >
              {index + 1}. Ajánlattételi táblázat ({item.Gyarto_Tipus})
            </div>
            {item.Mennyiseg > 1 && !this.props.SqtReportStore.RootStore.TelesalesMode &&
              <div style={{ padding: '10px', textAlign: 'center' }}>
                A termék nem biztosítható, mert egynél több található belőle a bizonylaton.
              </div>}
            {(item.Mennyiseg == 1 || this.props.SqtReportStore.RootStore.TelesalesMode) &&

              <div>

                {this.props.SqtReportStore.isMobile() &&
                  <div style={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
                    {item.Insurances.map((s, insuranceIndex) =>
                      <div >
                        <table >
                          <tr>
                            <td>
                              <input
                                type="radio"
                                id={insuranceIndex.toString()}
                                value={s.Selected}
                                checked={
                                  s.Selected
                                }

                                onClick={() => this.props.SqtReportStore.setActiveInsurance(index, insuranceIndex)}
                              />
                            </td>
                            <td>
                              <div style={{ border: '1px solid #a51716' }}>
                                <div style={{ background: '#a51716', color: 'white', textAlign: 'center', padding: '4px' }}>
                                  {s.Name}
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Csomag ára"
                                    value={s.PriceString}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Jótállás tartama"
                                    value={s.GuaranteeTimeString}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Műszaki meghibásodás"
                                    value={s.TechnicalFailureString}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Baleseti meghibásodás"
                                    value={s.AccidentalFailureString}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Lopás/rablás"
                                    value={s.StoleString}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                                <div className="inputContainer half inline">
                                  <TextField
                                    label="Cikkszám"
                                    value={s.ArticleNumber}
                                    disabled={true}
                                    type="text"

                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>

                    )}
                  </div>



                }


                {!this.props.SqtReportStore.isMobile() && <table style={{ border: '1px solid grey', borderCollapse: 'collapse', width: '97%', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>
                  <tr>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Garanciacsomag neve
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Csomag ára
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Jótállás tartama
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Műszaki meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Baleseti meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Lopás/rablás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Cikkszám
                    </th>
                  </tr>
                  {item.Insurances.map((s, insuranceIndex) =>
                    <tr onClick={() => this.props.SqtReportStore.setActiveInsurance(index, insuranceIndex)} style={{ cursor: 'pointer' }}>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        <input
                          type="radio"
                          id={insuranceIndex.toString()}
                          value={s.Selected}
                          checked={
                            s.Selected
                          }

                          onClick={() => this.props.SqtReportStore.setActiveInsurance(index, insuranceIndex)}
                        />
                        {/* <Radio checked={s.Selected} style={{ paddingLeft: 5, paddingTop: 9, width: '20px' }} /> */}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.Name}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.PriceString}

                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.GuaranteeTimeString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.TechnicalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.AccidentalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.StoleString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.ArticleNumber}
                      </td>
                    </tr>

                  )}
                </table>}


              </div>


            }




          </div>
          {item.SelectedInsurace &&
            <div className='panel'>
              <div className='title' style={{ background: '#a51716' }} onClick={() => this.props.SqtReportStore.onClosePanelClicked(`BiztositottTermekAdatai-biztositas-${index}`)}>
                {index + 1}. Biztosítás részletei ({item.SelectedInsurace?.Name})
              </div>
              {this.props.SqtReportStore.closedPanels.indexOf(`BiztositottTermekAdatai-biztositas-${index}`) == -1 &&
                <div className="panelContainer">
                  <div className="row">
                    {/* <div className="inputContainer half inline">
                      <TextField
                        label="Fedezet (biztosítási csomag)"
                        value={item.SelectedInsurace?.Name}
                        disabled={true}
                        onChange={(ev, value) => {
                          this.props.SqtReportStore.onValueChanged('Fedezet_BiztositasiCsomag', value, item);
                        }}
                      />
                    </div> */}

                    <div className="inputContainer half inline">
                      <TextField
                        label="Ajánlatszám"
                        value={item.Ajanlatszam}
                        disabled={true}
                        onChange={(ev, value) => {
                          this.props.SqtReportStore.onValueChanged('Ajanlatszam', value, item);
                        }}
                      />
                    </div>
                    <div className="inputContainer half inline">
                      <TextField
                        label="Biztosítási Módozat díja"
                        value={item.SelectedInsurace?.PriceString}
                        disabled={true}
                        onChange={(ev, value) => {
                          this.props.SqtReportStore.onValueChanged('BiztositasiDij', value, item);
                        }}
                      />
                    </div>
                  </div>


                  <div className="row">
                    <div className="inputContainer half inline">
                      <TextField
                        label="Ajánlat dátuma"
                        value={item.AjanlatDatuma}
                        disabled={true}

                      />
                    </div>

                    {this.props.SqtReportStore.RootStore.TelesalesMode && <div className="inputContainer half inline">
                      <TextField
                        label="Áruház azonosító"
                        value={item.SelectedInsurace?.Store}
                        disabled={true}

                      />
                    </div>}
                  </div>
                  {this.props.SqtReportStore.RootStore.TelesalesMode &&
                    <div className="row">
                      <div className="inputContainer half inline">
                        <TextField
                          label="Értékesítő azonosító"
                          value={this.props.SqtReportStore.sqtData.SzaktanacsadoKodja}
                          disabled={true}

                        />
                      </div>


                    </div>
                  }

                </div>}



            </div>


          }

          {this.props.SqtReportStore.sqtData.Termekek && this.props.SqtReportStore.sqtData.Termekek.length - 1 == index && this.props.SqtReportStore.sqtData.Status < 200 && <div className='mainButtons' style={{ textAlign: 'center' }}>
            <PrimaryButton
              className="ui button"
              text='Új biztosítandó eszköz hozzáadása'
              onClick={() => this.props.SqtReportStore.addnewItem()} />
          </div>}
        </div>}
    </div>)
  }
}
export default withRouter(SqtReport)