import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../../core/stores/RootStore";
import { AdminStore } from "../core/AdminStore";
import { GPlusDocumentsStore } from "./core/GPlusDocumentsStore";
import { GPlusDocuments } from "./GPlusDocuments";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
    GPlusDocumentsStore: GPlusDocumentsStore;
}

@inject("RootStore")
@observer
export class GPlusDocumentsPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);

    let store = new GPlusDocumentsStore(props.RootStore);
    

    this.stores = {
        GPlusDocumentsStore: store
    };

  }

  async componentDidMount() {
    this.stores.GPlusDocumentsStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <GPlusDocuments />
      </Provider>
    );
  }
}
