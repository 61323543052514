import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mode } from "d3";
import { action, computed, flow, observable } from "mobx";
import { IDropdownOption, MessageBarType } from "office-ui-fabric-react";
import React = require("react");
import Cookies from "universal-cookie/es6";
import { LoginService } from "../../modules/Admin/Login/core/LoginService";
import { RoutesObj } from "../contstants/Routes";
import { IConfiguration } from "../models/IConfiguration";
import { IUser } from "../models/IUser";
import { IStore } from "../models/IStore";
import { StoreService } from "../service/StoreService";
import { UserService } from "../service/UserService";
import { RouterStore } from "./RouterStore";
import { faCar, faSignOutAlt, faStore, faUser } from "@fortawesome/free-solid-svg-icons";
import { SqtReportService } from "../service/sqtReportService";

export class RootStore {

  public UserService: UserService = null;
  public StoreService: StoreService = null;
  public SqtReportService: SqtReportService = null;

  public Stores: Array<IStore> = [];


  @observable Message: string;
  @observable ConfirmOk: Function;
  @observable ConfirmCancel: Function;

  RouterStore: RouterStore;
  @observable UserName: string = null;
  @observable SelectedStore: string = localStorage["sqtAppSelectedStore"];
  @observable SelectedStoreSapCode: string = localStorage["sqtAppSelectedStoreSAPCode"];
  @observable TelesalesMode: boolean = localStorage["sqtAppTelesalesMode"];
  @observable SelectedRole: number = 0;
  @observable SelectedOperatorNumber: string = '111';
  public adminPass: any;
  @observable showDialog: boolean = false;
  @observable loadingCount: number = 0;
  @observable CurrentUser: IUser = null;
  @observable isUserAuthed: boolean = false;
  MessageBarType: MessageBarType;
  @observable loadings: string[] = [];

  LoginService: LoginService;
  @observable loginFailure: boolean = false;
  @observable loginFailMessage: string;

  public GoToHomeFn: (boolean) => void;

  constructor() {
    this.RouterStore = new RouterStore(this);
    this.LoginService = new LoginService();
    this.UserService = new UserService();
    this.StoreService = new StoreService();
    this.SqtReportService = new SqtReportService();
    
  }

  removeFromArray = (array: any[], element: any) => {
    var index = array.indexOf(element);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  adminLogin= flow(function* (this: RootStore) {
  
    let result= yield this.UserService.adminLogin(this.adminPass);
  
    if(result){
      this.RouterStore.IsTelesalesAdmin=true;
      this.RouterStore.gotToRoute(RoutesObj.SqtSwitchPage.id, {}, null, false);
    }
    this.showDialog=false;
  
    //this.RootStore.IsLoading = false;
  });
  addloading = (loading: string) => {
    console.log("ADD: " + loading);
    this.loadings.push(loading);
    this.loadingCount++;
  }
  removeLoading = (loading: string) => {
    console.log("Remove: " + loading);
    this.removeFromArray(this.loadings, loading);
    this.loadingCount--;
  }


  Init = flow(function* (this: RootStore) {
    this.addloading('RootStore_init')

    yield this.RouterStore.Init();
    //yield this.initStores();

    // if (this.SelectedStore != null) {
    //   this.CurrentUser = yield this.UserService.GetCurrentUser();

    //   this.isUserAuthed = true;
    //   yield this.initResources();
    //   this.RouterStore.gotToRoute(RoutesObj.SqtSwitchPage.id, {}, null, false);

    // } else {
    //   let url = `${window.location.origin}${window.location.pathname}#/Login`

    //   let actRoute = this.RouterStore.getSelectedRoute;

    //   if (actRoute.id == RoutesObj.NoMatch.id) {
    //     window.location.href = url;
    //   }
    //   else {
    //     if (actRoute.id != RoutesObj.Login.id) {
    //       url += `/${btoa(window.location.href)}`;
    //       window.location.href = url;
    //     }
    //   }
    // }

    this.removeLoading('RootStore_init')
  });

  initStores = flow(function* (this: RootStore) {
    this.addloading('RootStore_initStores')

    const result = yield Promise.all([
      this.StoreService.GetStores(),
      // this.StoreService.GetMyStore()
    ])

    this.Stores = result[0];

    if (result[1]) {
      this.SelectedStore = result[1].Code;
      this.SelectedStoreSapCode = result[1].SapCode;
    }

    this.removeLoading('RootStore_initStores')
  });

  initResources = flow(function* (this: RootStore) {
    this.addloading('RootStore_initResources')

    // const result = yield Promise.all([
    //   this.StoreService.GetStores(),
    // ])

    //this.Stores = result[0];
    this.removeLoading('RootStore_initResources')
  });

  @action onValueChanged = (property: string, value: any) => {
    this[property] = value;
  }

  GoToHome(type) {
    this.ResetConfirm();
    this.RouterStore.gotToRoute(RoutesObj.Home.id, { type: type }, null, false);
  }


  public CreateConfirm(message: string, ok: Function, cancel: Function = this.ResetConfirm) {
    this.Message = message;
    this.MessageBarType = MessageBarType.warning;

    this.ConfirmOk = ok;
    this.ConfirmCancel = cancel;
  }


  public CreateError(message: string, ok: Function, cancel: Function = this.ResetConfirm) {
    this.Message = message;
    this.MessageBarType = MessageBarType.error;

    this.ConfirmOk = ok;
    this.ConfirmCancel = cancel;
  }

  ResetConfirm() {
    this.Message = ""
  }

  SetCookie(key: string, value: string) {
    const cookies = new Cookies();
    cookies.set(key, value, { path: '/' });
  }

  getCookie(key: string): string {
    const cookies = new Cookies();
    return cookies.get(key);
  }

  login = flow(function* (this: RootStore, returnUrl?: string) {
    if (this.SelectedStore) {
      this.CurrentUser = yield this.UserService.GetCurrentUser();

      this.isUserAuthed = true;
      localStorage["sqtAppSelectedStore"] = this.SelectedStore;
      localStorage["sqtAppSelectedStoreSAPCode"] = this.SelectedStoreSapCode;

      yield this.initResources();

      if (returnUrl) {
        window.location.href = atob(returnUrl);
      }
      else {
        this.RouterStore.gotToRoute(RoutesObj.SqtSwitchPage.id, {}, null, false);
      }
    }
    else {
      this.loginFailure = true;
      this.loginFailMessage = "Áruház kiválasztása kötelező!";
      console.log('failed to login')
    }
  });

  

  public isMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  @action logOut = () => {

    this.SelectedStore = "";
    this.CurrentUser = null;
    this.isUserAuthed = false;
    localStorage["sqtAppSelectedStore"] = "";
    localStorage["sqtAppSelectedStoreSAPCode"] = "";
    localStorage['hideWelcomeDialog'] = '';


    this.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
  }

  @action onChangeUserName = (event: any, data: any) => {
    this.UserName = data
    console.log(this.UserName)
  }

  @action getFormattedDate(d: Date): string {
    const dateString = d.toLocaleDateString("hu-HU", { year: "numeric", month: "numeric", day: "numeric" }).replace(/ /g, "");
    const datetimeString = `${dateString}`;

    return datetimeString;
  }

  GoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  CanGoBack() {
    return this.RouterStore.history.length > 1;
  }

  GoBack = () => {
    this.RouterStore.history.goBack();
    this.RouterStore.setCustomTitle(null);
  };

  // GoHome = (newTab: boolean = false, refresh: boolean = false) => {
  //   this.RouterStore.gotToRoute(RoutesObj.Home.id, null, null, newTab);

  //   if (newTab == false && refresh) {
  //     window.location.reload();
  //   }
  // };





  GetDDLValues(datasource: any[], key: string = "Id", value: string = "Title"): IDropdownOption[] {
    if (!datasource) {
      return [];
    }

    return datasource.map(s => {
      return { key: s[key], text: s[value] };
    });
  }

  public async GetUserByNameOrEmail(text) {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };

    const request = await fetch(`${process.env.SERVER_URL}/api/user/GetUserByQueryText?queryText=${encodeURI(text)}`, options);
    const result = await request.json();

    return result.map((user) => {
      return {
        ...user,
        primaryText: user.Name,
        secondaryText: user.LoginName,
      };
    });
  }

  SameDay(date1: Date, date2: Date) {
    if (!date1 || !date2) return null;
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
  }

  // @computed get IsAdmin() {
  //   if (!this.CurrentUser) return false;

  //   return this.CurrentUser.IsAdmin;
  // }

  @computed get IsLeader() {
    if (!this.CurrentUser) return false;

    return this.CurrentUser.IsLeader;
  }

  @action UpdateValue<T>(value: any, selectedField: T, selectedProperty: keyof T) {
    selectedField = {
      ...selectedField,
      [selectedProperty]: value
    }
  }

  @action LoadDataForPeoplePicker(data: any[]) {
    if (data) {
      const newData = data.filter(u => u != null).map((user) => {
        return {
          ...user,
          text: user.Name,
        };
      });

      return newData;
    }
  }

  public renderHeader() {
    let selectedStoreName = "";
    if (this.SelectedStore) {
      let store = this.Stores.find(s => s.Code == this.SelectedStore);

      if (store) {
        selectedStoreName = `(${store.SapCode}) ${store.Name}`;
      }
    }

    return (
      <table style={{ width: "100%", marginBottom: "10px" }}>
        <tbody>
          <tr>
            <td className="mobileUser" style={{ width: "30%", textAlign: "left", paddingTop: "6px", paddingRight: "8px" }}>
              <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", marginRight: "4px" }} icon={faStore} />
              {this.isMobile() ? null : <b>Áruház: </b>}
              {this.SelectedStore ? selectedStoreName : null}
            </td>
            <td className="mobileUser" style={{ width: "30%", textAlign: "right", paddingTop: "6px", paddingRight: "8px" }}>
              {/* <FontAwesomeIcon style={{ height: "16px", width: "16px", position: "relative", marginRight: "4px" }} icon={fa} />
              {this.isMobile() ? null : <b>Felhasználó: </b>}
              {this.CurrentUser ? this.CurrentUser.Name : null} */}
              <span onClick={() => this.logOut()} style={{ cursor: 'pointer' }}>
                Kijelentkezés
                <FontAwesomeIcon style={{ height: "16px", marginLeft: '3px', width: "16px", position: "relative", marginRight: "4px" }} icon={faSignOutAlt} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

