import * as React from "react";
import "./Home.scss";
import { observer, inject } from "mobx-react";
import "./Home.scss";
import { HomeStore } from "../core/stores/HomeStore";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { RoutesObj } from "../../../core/contstants/Routes";
import { Dialog, DialogType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { AgGridReact } from "@ag-grid-community/react";
import { AgGridText } from "../../../core/contstants/agGridText";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import OpenItem from "../../../core/components/OpenItem";

interface IHomeProps {
  HomeStore?: HomeStore;
}

@inject("HomeStore")
@observer
export class Home extends React.Component<IHomeProps, {}> {
  private stock1Src = String(require("../assets/stock1.png"));
  private stock2Src = String(require("../assets/stock2.png"));
  private transferSalesSrc = String(require("../assets/magnifying-glass.png"));
  private transferSalesSrc2 = String(require("../assets/writing.png"));


  constructor(props: IHomeProps) {
    super(props);
  }
  handleGridReady(event: GridReadyEvent) {
    event.api.sizeColumnsToFit();
  }
  public renderDialogs() {
    return (<div>
      <Dialog
        hidden={!this.props.HomeStore.showDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Admin",
          showCloseButton: false
        }}
      >
        <TextField
          label="Jelszó"
          value={this.props.HomeStore.adminPass}

          onChange={(ev, value) => {
            this.props.HomeStore.adminPass = value;
          }}
        />

        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Belépés"
            onClick={() => this.props.HomeStore.adminLogin()}
          />
        </div>
      </Dialog>

    </div>)
  }
  public render() {
    if (this.props.HomeStore.RootStore.loadingCount > 0) {
      return <LoaderOverlay />;
    }
    const getRowStyle = params => {
      if (params.data.HatralevoNapokSzama <=5) {
        return { background: 'red',color:'white' };
      }
    };

    return (
      <div className="commonComponent">
        <div className="mainHeader">Egyszeri díjas Garancia Plusz főoldal</div>
        <div className="darkerBcground">
          {/* Felhasználó */}
          {this.props.HomeStore.RootStore.renderHeader()}
          <div>
            <div style={{ display: 'flex', justifyContent: "center" }}>
              <div className={"tile"} style={{ background: 'rgb(219 219 219)' }} onClick={() => { this.props.HomeStore.RootStore.RouterStore.gotToRoute(RoutesObj.SqtOfferPage.id, {}, null, false); }} >
                <div className="titleText" style={{ fontWeight: 'bold', color: 'black' }}>
                  Garancia Plusz
                  <div>
                    ajánlatok
                  </div>
                </div>
                <img style={{ width: '70px', marginTop: '10px' }} src={this.transferSalesSrc} alt="" />
              </div>
              <div className={"tile"} style={{ background: 'rgb(219 219 219)' }} onClick={() => { this.props.HomeStore.RootStore.RouterStore.gotToRoute(RoutesObj.SqtReport.id, {}, null, false); }} >
                <div className="titleText" style={{ fontWeight: 'bold', color: 'black' }}>
                  Ajánlati dokumentum létrehozása
                </div>
                <img src={this.transferSalesSrc2} style={{ width: '70px', marginTop: '10px' }} alt="" />
              </div>
            </div>
          </div>
          {this.props.HomeStore.RootStore.TelesalesMode &&
            <div id="grid" className="ag-theme-alpine" style={{ width: '100%', height: '519px' }}>
              <AgGridReact
                rowData={this.props.HomeStore.offers}
                columnDefs={this.props.HomeStore.columnDef}
                localeText={AgGridText}
                frameworkComponents={{ btnCellRenderer: OpenItem }}

                getRowStyle={getRowStyle}
                onGridReady={this.handleGridReady}
                gridOptions={{
                  // paginationNumberFormatter: (params: any) => {
                  //     return params.value.toLocaleString();
                  // },
                }}
              >
              </AgGridReact>
            </div>
          }
          {this.renderDialogs()}
        </div>
      </div>
    );
  }
}
