import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { inject, observer } from 'mobx-react';
import { ButtonType, Dialog, DialogType, Dropdown, IDropdownOption, MessageBar, MessageBarType, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React = require('react');
import { RouteComponentProps } from 'react-router-dom';
// import React from 'react';
// import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import LoaderOverlay from '../../../components/controls/LoaderOverlay/LoaderOverlay';
import { AgGridText } from '../../../core/contstants/agGridText';
import { RootStore } from '../../../core/stores/RootStore';
import { AdminStore } from '../core/AdminStore';
import { ProductGroupStore } from './core/ProductGroupStore';
import "./ProductGroup.scss";

// import logo from '../../assets/logo.png';
// import withNavigation from '../../hocs/withNavigation';
// import { RootStore } from '../../stores/RootStore';

interface IProducGroupProps {
    ProductGroupStore?: ProductGroupStore;
}
@inject("ProductGroupStore")
@observer
export class ProductGroup extends React.Component<IProducGroupProps, {}> {
    private readonly returnUrl: string = "";
    private logo: string = String(require("../../../assets/mm-logo.svg"));
    constructor(props: IProducGroupProps) {
        super(props);
    }
    handleGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    }

    componentDidMount(): void {

        this.props.ProductGroupStore.getProductGroups()


    }
    public renderDialogs() {
        return (<div>
          <Dialog
            hidden={!this.props.ProductGroupStore.showUploadDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Árucsoportok betöltése",
              showCloseButton: false
            }}
          >
            <div style={{ maxWidth: '400px' }}>
                
            <Input  id={'upload'} onChange={(event) => {this.props.ProductGroupStore.setFile(event)}} type="file" />

            </div>
    
            <div style={{ margin: "20px", textAlign: 'center' }}>
              <PrimaryButton
                className="ui button"
                style={{ backgroundColor: 'red'  }}
                text="Betöltés"
                onClick={() => this.props.ProductGroupStore.confirmUpload()}
              />
              <PrimaryButton
                className="ui button"
                style={{ backgroundColor: 'grey', marginLeft:'10px'}}
                text="Mégse"
                onClick={() => this.props.ProductGroupStore.showUploadDialog = false}
              />
            </div>
          </Dialog>
         
        </div>)
      }
    public render() {
        if (this.props.ProductGroupStore.RootStore.loadingCount > 0) {
            return <LoaderOverlay />;
        }
 
        return (
            <div className="sqtReportComponent">
                <div className="mainHeader">Árucsoport – biztosítási kategória megfeleltetés</div>
                <div className="darkerBcground">
                    {/* Felhasználó */}
                    {this.props.ProductGroupStore.RootStore.renderHeader()}
                    <div className='container'>
                        <div className='mainPanel'>
                            {/* {this.props.SqtReportStore.sqtData ? this.renderSqtOffer() : this.renderQueryButton()} */}

                            <div className='mainButtons' style={{ textAlign: 'left', marginBottom: '20px' }}>
                                <PrimaryButton
                                    className="ui button"
                                    style={{ backgroundColor: 'red' }}
                                    text='Árucsoportok betöltése'
                                    onClick={() => this.props.ProductGroupStore.showdialog()} />
                                    <PrimaryButton
                                    className="ui button"
                                    style={{ backgroundColor: 'red',marginLeft:'10px'}}
                                    text='Árucsoportok lekérése'
                                    onClick={() => this.props.ProductGroupStore.GetAdminTablesExcel()} />
                                    
                            </div>
                            <div id="grid" className="ag-theme-alpine" style={{ width: '100%', height: '519px' }}>
                                <AgGridReact
                                    rowData={this.props.ProductGroupStore.productGroups}
                                    columnDefs={this.props.ProductGroupStore.columnDef}
                                    localeText={AgGridText}
                                   

                                    
                                    onGridReady={this.handleGridReady}
                                    gridOptions={{
                                        // paginationNumberFormatter: (params: any) => {
                                        //     return params.value.toLocaleString();
                                        // },
                                    }}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialogs()}
            </div>
        );
    }
}


