import * as React from "react";
import "./SqtOffer.scss";
import { observer, inject } from "mobx-react";
import { SqtOfferStore } from "../core/stores/SqtOfferStore";
import LoaderOverlay from "../../../components/controls/LoaderOverlay/LoaderOverlay";
import { Checkbox, DatePicker, DayOfWeek, DefaultButton, Dialog, DialogType, Dropdown, IDropdownOption, Label, MessageBar, MessageBarType, PrimaryButton, TextField } from "office-ui-fabric-react";
import { DayPickerText } from "../../../core/contstants/datePickerText";
import { ISqtItem } from "../../../core/models/ISqtItem";

interface ISqtOfferProps {
  SqtOfferStore?: SqtOfferStore;
}

@inject("SqtOfferStore")
@observer
export class SqtOffer extends React.Component<ISqtOfferProps, {}> {

  constructor(props: ISqtOfferProps) {
    super(props);
  }

  public render() {
    if (this.props.SqtOfferStore.RootStore.loadingCount > 0) {
      return <LoaderOverlay />;
    }

    return (
      <div className="SqtOfferComponent">
        <div className="mainHeader">Garancia Plusz ajánlatok </div>
        <div className="darkerBcground">
          {/* Felhasználó */}
          {this.props.SqtOfferStore.RootStore.renderHeader()}
          <div className='mainPanel'>
            {this.props.SqtOfferStore.sqtData ? this.renderSqtOffer() : this.renderQueryButton()}
          </div>
        </div>
        {this.renderDialogs()}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.SqtOfferStore.CodeInput) {
      this.props.SqtOfferStore.CodeInput.focus();
    }
  }

  public renderDialogs() {
    return (<div>
      {/* <Dialog
        hidden={!this.props.SqtOfferStore.showWelcomeDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Állandó, beégetett információ",
          showCloseButton: false
        }}
      >
        <div style={{ maxWidth: '400px' }}>A bejelentkezést követően a biztosítási ajánlat összeállításának felülete megjelenik, de a használat engedélyezése előtt egy felugró ablakban állandó, beégetett információ jelenik meg a felhasználók számára. Ennek jóváhagyása esetén válik elérhetővé használatra az összeállítás felülete.</div>

        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Jóváhagyás"
            onClick={() => this.props.SqtOfferStore.hideDialog('showWelcomeDialog')}
          />
        </div>
      </Dialog> */}
      <Dialog
        hidden={!this.props.SqtOfferStore.showMaganSzemelyDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Adószám",
          showCloseButton: false
        }}
      >
        <div>Biztosítási ajánlat csak magánszemély részére állítható ki!</div>

        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Magánszemély megadása"
            onClick={() => {
              this.props.SqtOfferStore.onValueChanged('Vezeteknev', '', this.props.SqtOfferStore.sqtData);
              this.props.SqtOfferStore.onValueChanged('Keresztnev', '', this.props.SqtOfferStore.sqtData);
              this.props.SqtOfferStore.onValueChanged('TeljesNev', '', this.props.SqtOfferStore.sqtData);
              this.props.SqtOfferStore.hideDialog('showMaganSzemelyDialog')
            }}
          />

          <DefaultButton
            className="ui button"
            text="A szerződő magánszemély"
            onClick={() => this.props.SqtOfferStore.hideDialog('showMaganSzemelyDialog')}
          />
        </div>
      </Dialog>
      <Dialog
        hidden={!this.props.SqtOfferStore.showGyartasiSzamDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Gyártási szám",
          showCloseButton: false
        }}
      >
        {this.props.SqtOfferStore.showGyartasiSzamDialog && <div>A {this.props.SqtOfferStore.sqtData.Termekek[this.props.SqtOfferStore.gyartasiSzamIndex].Gyarto_Tipus} terméknél a Gyártási szám nincs kitöltve!</div>}

        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Gyártási szám megadása"
            onClick={() => {
              this.props.SqtOfferStore.onValueChanged('gyartasiSzamIndex', null);
              this.props.SqtOfferStore.hideDialog('showGyartasiSzamDialog')
            }}
          />

          <DefaultButton
            className="ui button"
            text="Értékesítéskor NEM ismert"
            onClick={() => {
              this.props.SqtOfferStore.onValueChanged('GyartasiSzam', 'nem ismert', this.props.SqtOfferStore.sqtData.Termekek[this.props.SqtOfferStore.gyartasiSzamIndex])
              this.props.SqtOfferStore.onValueChanged('gyartasiSzamIndex', null);
              this.props.SqtOfferStore.hideDialog('showGyartasiSzamDialog');
            }}
          />
        </div>
      </Dialog>
      <Dialog
        hidden={!this.props.SqtOfferStore.showAjanlatGeneralasa}
        dialogContentProps={{
          type: DialogType.normal,
          title: "Teendők",
          showCloseButton: false
        }}
      >
        <div>Milyen nyelven szeretné a dokumentumot?</div>
        <div>
          <div className="inputContainer half inline">
            <TextField
              label=""
              value={'Magyar'}
              disabled={true}
              onChange={(ev, value) => {
              }}
            />
          </div>
        </div>

        <div style={{ margin: "20px 0" }}> <b>Ajánlati dokumentummal kapcsolatos teendők...</b></div>

        <div style={{ margin: "20px" }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red', marginRight: '20px' }}
            text="Ajánlati dokumentum létrehozása"
            onClick={() => {
              this.props.SqtOfferStore.onNewSubmit();
            }}
          />

          <DefaultButton
            className="ui button"
            text="Mégsem"
            onClick={() => {
              this.props.SqtOfferStore.hideDialog('showAjanlatGeneralasa');
            }}
          />
        </div>
      </Dialog>
      {/* <Dialog
        hidden={!this.props.SqtOfferStore.showWawiData}
        dialogContentProps={{
          type: DialogType.normal,
          title: "WAWI adatok",
          showCloseButton: false
        }}
      >
        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
          {(this.props.SqtOfferStore.sqtData && (this.props.SqtOfferStore.sqtData.OriginalQueryResult.length > 0)) && this.renderOriginalQueryTable()}
        </div>
        <div style={{ margin: "20px", textAlign: 'center' }}>
          <PrimaryButton
            className="ui button"
            style={{ backgroundColor: 'red' }}
            text="Bezárás"
            onClick={() => this.props.SqtOfferStore.hideDialog('showWawiData')}
          />
        </div>
      </Dialog> */}
    </div>)
  }

  public renderOriginalQueryTable() {
    const entries = Object.keys(this.props.SqtOfferStore.sqtData.OriginalQueryResult[0]);

    return (<table style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>
      <tr>{entries.map(s => <th style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s}</th>)}</tr>
      {this.props.SqtOfferStore.sqtData.OriginalQueryResult.map(s => <tr>{entries.map(z => <td style={{ border: '1px solid grey', borderCollapse: 'collapse' }}>{s[z]}</td>)}</tr>)}
    </table>)

  }

  public renderQueryButton() {
    return (<div className='container'>
      <div className='mainContainer'>
        <div className="inputContainer half inline">
          <TextField
            label="Biztosítandó termék cikkszáma"
            value={this.props.SqtOfferStore.Code}
            componentRef={(ref) => this.props.SqtOfferStore.CodeInput = ref}
            onKeyDown={(event) => {
              if (event.keyCode === 13 && this.props.SqtOfferStore.Code) {
                this.props.SqtOfferStore.onSubmit();
              }
            }}
            onChange={(ev, value) => {
              this.props.SqtOfferStore.onValueChanged('Code', value);
            }}
          />
          {this.props.SqtOfferStore.showBizonylatError && <MessageBar messageBarType={MessageBarType.error}>{this.props.SqtOfferStore.Code} számú bizonylat nem található!</MessageBar>}
        </div>
      </div>

      <div className='mainButtons' style={{ textAlign: 'center' }}>
        <PrimaryButton
          className="ui button"
          text='Keresés'
          onClick={() => this.props.SqtOfferStore.onSubmit()} />
      </div>
    </div>)
  }

  public renderSqtOffer() {
    return (<div className='container'>
      <div className='mainContainer'>


        <div className='panel'>
          <div className='title' onClick={() => this.props.SqtOfferStore.onClosePanelClicked("AllandoLakcim")}>
            Biztosítandó termék adatai
          </div>

          <div className="panelContainer">
            <div className="row">
              <div className="inputContainer half inline">
                <TextField
                  label="Cikkszám"
                  value={this.props.SqtOfferStore.sqtData.ArticleNumber}
                  disabled={true}
                  type="number"
                  onChange={(ev, value) => {
                    this.props.SqtOfferStore.onValueChanged('ArticleNumber', value, this.props.SqtOfferStore.sqtData);
                  }}
                />
              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Gyártó, típus"
                  value={this.props.SqtOfferStore.sqtData.ManufacturerAndName}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtOfferStore.onValueChanged('ManufacturerAndName', value, this.props.SqtOfferStore.sqtData);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputContainer half inline">
                <TextField
                  label="Áruházi készlet"
                  value={this.props.SqtOfferStore.sqtData.Quantity}
                  disabled={true}
                  type="number"
                  onChange={(ev, value) => {
                    this.props.SqtOfferStore.onValueChanged('Quantity', value, this.props.SqtOfferStore.sqtData);
                  }}
                />
              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Árucsoport"
                  value={this.props.SqtOfferStore.sqtData.ProductGroupNameAndId}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtOfferStore.onValueChanged('ProductGroupNameAndId', value, this.props.SqtOfferStore.sqtData);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="inputContainer half inline">
                <table style={{ width: '35%' }}>
                  <tr>
                    <td>
                      <TextField
                        label="Vételár"
                        value={this.props.SqtOfferStore.sqtData.GrossSalesPrice}
                        disabled={false}
                        type="number"
                        onChange={(ev, value) => {
                          this.props.SqtOfferStore.onValueChanged('GrossSalesPrice', value, this.props.SqtOfferStore.sqtData);
                        }}
                      />
                    </td>
                    <td style={{ paddingTop: '31px', fontSize: '14px' }}>
                      Ft
                    </td>
                  </tr>
                </table>

              </div>
              <div className="inputContainer half inline">
                <TextField
                  label="Jótállás tartama"
                  value={this.props.SqtOfferStore.sqtData.GuaranteeTimeString}
                  disabled={true}
                  type="text"
                  onChange={(ev, value) => {
                    this.props.SqtOfferStore.onValueChanged('GuaranteeTimeString', value, this.props.SqtOfferStore.sqtData);
                  }}
                />
              </div>
            </div>


            <div className='panel'>
              <div className='title' style={{ background: '#a51716' }} >
                Ajánlattételi táblázat
              </div>
              {this.props.SqtOfferStore.isMobile() &&
                <div style={{width:'97%',marginLeft:'auto',marginRight:'auto'}}>
                  {this.props.SqtOfferStore.sqtData.Insurances.map(s =>
                    <div style={{ border: '1px solid #a51716',marginTop:'10px' }}>
                      <div style={{ background: '#a51716', color: 'white',  textAlign:'center',padding:'4px'}}>
                        {s.Name}
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Csomag ára"
                          value={s.PriceString}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Jótállás tartama"
                          value={s.GuaranteeTimeString}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Műszaki meghibásodás"
                          value={s.TechnicalFailureString}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Baleseti meghibásodás"
                          value={s.AccidentalFailureString}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Lopás/rablás"
                          value={s.StoleString}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                      <div className="inputContainer half inline">
                        <TextField
                          label="Cikkszám"
                          value={s.ArticleNumber}
                          disabled={true}
                          type="text"
                          
                        />
                      </div>
                    </div>)}
                </div>



              }
              {!this.props.SqtOfferStore.isMobile() &&
                <table style={{ border: '1px solid grey', borderCollapse: 'collapse', width: '97%', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>
                  <tr>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Garanciacsomag neve
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Csomag ára
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Jótállás tartama
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Műszaki meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Baleseti meghibásodás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Lopás/rablás
                    </th>
                    <th style={{ border: '1px solid grey', borderCollapse: 'collapse', background: '#a51716', color: 'white', padding: '5px' }}>
                      Cikkszám
                    </th>
                  </tr>
                  {this.props.SqtOfferStore.sqtData.Insurances.map(s =>
                    <tr>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.Name}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.PriceString}

                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.GuaranteeTimeString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.TechnicalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.AccidentalFailureString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.StoleString}
                      </td>
                      <td style={{ border: '1px solid grey', borderCollapse: 'collapse', textAlign: 'center', padding: '5px' }}>
                        {s.ArticleNumber}
                      </td>
                    </tr>

                  )}
                </table>}



            </div>

          </div>
        </div>


      </div>

      {(this.props.SqtOfferStore.isSubmitted && (this.props.SqtOfferStore.errors.length > 0)) && <MessageBar className="errorMessageBar" messageBarType={MessageBarType.error}>
        <ul>
          {this.props.SqtOfferStore.errors.map(s => <li className="errorLi" key={s.key}>{s.value}</li>)}
        </ul>
      </MessageBar>}

      <div className='mainButtons'>
        <PrimaryButton
          className="ui button"
          style={{ backgroundColor: 'grey' }}
          text='Vissza a főoldalra'
          onClick={() => this.props.SqtOfferStore.RootStore.GoToHome(1)} />
        <PrimaryButton
          className="ui button"
          text='Új cikkszám keresése'
          onClick={() => this.props.SqtOfferStore.newArticleNumber()} />
        <PrimaryButton
          className="ui button"
          text='Újra kalkulálás'
          onClick={() => this.props.SqtOfferStore.onNewSubmit()} />
      </div>
    </div>)
  }

  public renderSqtItems(item: ISqtItem, index: number) {
    return (<div className='panel' key={index}>
      <div className='title' onClick={() => this.props.SqtOfferStore.onClosePanelClicked(`BiztositottTermekAdatai-${index}`)}>
        Biztosított termék adatai ({item.Gyarto_Tipus})
      </div>
      {this.props.SqtOfferStore.closedPanels.indexOf(`BiztositottTermekAdatai-${index}`) == -1 &&
        <div className="panelContainer">
          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Cikkszám"
                value={item.Cikkszam}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('Cikkszam', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Árucsoport"
                value={item.Arucsoport}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('Arucsoport', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Gyártó, típus"
                value={item.Gyarto_Tipus}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('Gyarto_Tipus', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label={item.IsMobile?"IMEI szám":"Gyártási szám"}
                value={item.GyartasiSzam}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('GyartasiSzam', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">
              <TextField
                label="Vásárlás helye"
                value={item.VasarlasHelye}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('VasarlasHelye', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Vásárlás dátuma"
                value={new Date(item.VasarlasDatuma).toLocaleDateString('hu-HU')}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('VasarlasDatuma', value, item);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="inputContainer half inline">

              <TextField
                label="Vételár"
                value={item.Vetelar ? `${item.Vetelar.toString()} Ft` : ''}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('Vetelar', value, item);
                }}
              />
            </div>

            <div className="inputContainer half inline">
              <TextField
                label="Gyári garancia tartama"
                value={`${item.GyartoiGaranciaTartama.toString()} hó`}
                disabled={true}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('GyartoiGaranciaTartama', value, item);
                }}
              />
              <Dropdown
                label="Gyári garancia tartama"
                selectedKey={item.GyartoiGaranciaTartama}
                options={this.props.SqtOfferStore.guaranteeTimes
                  .filter(s => s.GuaranteePriceMaxHUF > item.Vetelar)
                  .map(s => { return { key: s.GuaranteeMonths, text: `${s.GuaranteeMonths} hó` } })}
                onChange={(ev, value) => {
                  this.props.SqtOfferStore.onValueChanged('GyartoiGaranciaTartama', value, item);
                }}
              ></Dropdown>
            </div>
          </div>

          <div className='panel'>
            <div className='title' onClick={() => this.props.SqtOfferStore.onClosePanelClicked(`BiztositottTermekAdatai-biztositas-${index}`)}>
              Biztosítás adatai ({item.Gyarto_Tipus})
            </div>
            {this.props.SqtOfferStore.closedPanels.indexOf(`BiztositottTermekAdatai-biztositas-${index}`) == -1 &&
              <div className="panelContainer">
                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Fedezet (biztosítási csomag)"
                      value={item.Fedezet_BiztositasiCsomag}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtOfferStore.onValueChanged('Fedezet_BiztositasiCsomag', value, item);
                      }}
                    />
                  </div>

                  <div className="inputContainer half inline">
                    <TextField
                      label="Ajánlatszám"
                      value={item.Ajanlatszam}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtOfferStore.onValueChanged('Ajanlatszam', value, item);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Biztosítási díj"
                      value={item.BiztositasiDij ? `${item.BiztositasiDij.toString()} Ft` : ''}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtOfferStore.onValueChanged('BiztositasiDij', value, item);
                      }}
                    />
                  </div>

                  <div className="inputContainer half inline">
                    <TextField
                      label="Biztosítási garancia tartama"
                      value={item.BiztositasiGaranciaTartama ? `${item.BiztositasiGaranciaTartama.toString()} hó` : ''}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtOfferStore.onValueChanged('BiztositasiGaranciaTartama', value, item);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="inputContainer half inline">
                    <TextField
                      label="Szaktanácsadó kódja"
                      value={item.SzaktanacsadoKodja?.toString()}
                      disabled={true}
                      onChange={(ev, value) => {
                        this.props.SqtOfferStore.onValueChanged('SzaktanacsadoKodja', value, item);
                      }}
                    />
                  </div>
                </div>
              </div>}
          </div>
        </div>}
    </div>)
  }
}
