import { IUser } from "../models/IUser";
import { BaseService } from "./base.service";

export class UserService extends BaseService {
 
    constructor() {
        super("user");
      }

  public async GetCurrentUser(): Promise<any>{
    return await this.get("GetCurrentUser");
  }
  public async adminLogin(pass): Promise<any>{
    return await this.get("adminLogin?pass="+pass);
  }
  
}