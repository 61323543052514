import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { BaseService } from "./base.service";
// import { IResourceType } from "../../../../../core/models/IResourceType";

export class AdminService extends BaseService {
  constructor() {
    super("admin");
  }
  public async Login(UserName, Password): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ UserName, Password }),
    };

    let returnValue = await fetch(`${process.env.SERVER_URL}/api/user/AuthenticateUser`, options)
    return returnValue.json();
  }
  
  public async UploadGPlusDocuments(formdata: any): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: formdata
    };
    const requestHeaders: Headers = new Headers();
    const httpClientOptions: any = {
      headers: requestHeaders,
      credentials: 'include',
      method: 'POST',
      body: formdata
    };
    let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/UploadGPlusDocuments`, httpClientOptions)
    return returnValue.json();
  }
  public async UploadProductGoups(formdata: any): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: formdata
    };
    const requestHeaders: Headers = new Headers();
    const httpClientOptions: any = {
      headers: requestHeaders,
      credentials: 'include',
      method: 'POST',
      body: formdata
    };
    let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/UploadProductGoups`, httpClientOptions)
    return returnValue.json();
  }

  public async GetProductGroups(): Promise<any> {
    // const options: RequestInit = {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   credentials: "include",
    // };
    // let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/GetProductGroups`, options)
    // return returnValue;
    return this.get(`GetProductGroups`);
  }

  public async UploadInsurancePackagesDeductible(formdata: any): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: formdata
    };
    const requestHeaders: Headers = new Headers();
    const httpClientOptions: any = {
      headers: requestHeaders,
      credentials: 'include',
      method: 'POST',
      body: formdata
    };
    let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/UploadInsurancePackagesDeductible`, httpClientOptions)
    return returnValue.json();
  }

  public async GetInsurancePackagesDeductible(): Promise<any> {
    // const options: RequestInit = {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   credentials: "include",
    // };
    // let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/GetProductGroups`, options)
    // return returnValue;
    return this.get(`GetInsurancePackagesDeductible`);
  }
  public async UploadOfferTables(formdata: any): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: formdata
    };
    const requestHeaders: Headers = new Headers();
    const httpClientOptions: any = {
      headers: requestHeaders,
      credentials: 'include',
      method: 'POST',
      body: formdata
    };
    let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/UploadOfferTables`, httpClientOptions)
    return returnValue.json();
  }

  public async GetOfferTables(): Promise<any> {
    // const options: RequestInit = {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   credentials: "include",
    // };
    // let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/GetProductGroups`, options)
    // return returnValue;
    return this.get(`GetOfferTables`);
  }
  public async UploadInsurancePackage(formdata: any): Promise<any> {
    const options: RequestInit = {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      credentials: "include",
      body: formdata
    };
    const requestHeaders: Headers = new Headers();
    const httpClientOptions: any = {
      headers: requestHeaders,
      credentials: 'include',
      method: 'POST',
      body: formdata
    };
    let response: any = await fetch(`${process.env.SERVER_URL}/api/admin/UploadInsurancePackage`, httpClientOptions)
    // let debug=1;
    // let debug2=1;
    // let debug4=1;
    // let debug3=1;
    // return this.base64ToArrayBuffer(returnValue.Response.toString() );
    const blob = await response.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;

    var d = new Date();
    d = new Date(d.getTime() - 3000000);
    var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
    var d = new Date();
    d = new Date(d.getTime() - 3000000);
    var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
    link.setAttribute('download', 'InsurancePackage_Eredmeny_' + date_format_str + '.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
    return null;
  }

  public async GetInsurancePackages(): Promise<any> {
    // const options: RequestInit = {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   credentials: "include",
    // };
    // let returnValue = await fetch(`${process.env.SERVER_URL}/api/admin/GetProductGroups`, options)
    // return returnValue;
    return this.get(`GetInsurancePackages`);
  }
  public async GetUploadedDocuments(): Promise<any> {
    
    return await  this.get(`GetUploadedDocuments`);
  }
  public async GetUploadedDocumentByType(value:any): Promise<any>{
    const response: string = await this.get("GetUploadedDocumentByType?type="+value);

    return this.base64ToArrayBuffer(response);
  }
  public async GetAdminTablesExcel(type: any): Promise<any> {
    const response: string = await this.get("GetAdminTablesExcel?type=" + type);

    return this.base64ToArrayBuffer(response);
  }

  public async logOut(): Promise<any> {
    const options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    let returnValue = await fetch(`${process.env.SERVER_URL}/api/user/SignOut`, options)
    return returnValue.json();
  }


}
