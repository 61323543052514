import { inject, observer } from 'mobx-react';
import { ButtonType, Dialog, DialogType, Dropdown, IDropdownOption, MessageBar, MessageBarType, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React = require('react');
import { RouteComponentProps } from 'react-router-dom';
// import React from 'react';
// import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import { RootStore } from '../../../core/stores/RootStore';
// import logo from '../../assets/logo.png';
// import withNavigation from '../../hocs/withNavigation';
// import { RootStore } from '../../stores/RootStore';

interface ILoginPageProps extends RouteComponentProps<any> {
    RootStore?: RootStore;
}
@inject("RootStore")
@observer
export class LoginPage extends React.Component<ILoginPageProps> {
    private readonly returnUrl: string = "";
    private logo: string = String(require("../../../assets/mm-logo.svg"));
    constructor(props: ILoginPageProps) {
        super(props);

        let retUrl = (this.props.match.params as any).returnUrl;

        this.returnUrl = retUrl;
    }
    public renderDialogs() {
        return (<div>
          <Dialog
            hidden={!this.props.RootStore.showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Admin",
              showCloseButton: false
            }}
          >
            <TextField
                label="Jelszó"
                value={this.props.RootStore.adminPass}
                
                onChange={(ev, value) => {
                  this.props.RootStore.adminPass = value;
                }}
              />
    
            <div style={{ margin: "20px", textAlign: 'center' }}>
              <PrimaryButton
                className="ui button"
                style={{ backgroundColor: 'red' }}
                text="Belépés"
                onClick={() => this.props.RootStore.adminLogin()}
              />
            </div>
          </Dialog>
          
        </div>)
      }


    public render() {
        const handleKeypress = e => {
            //it triggers by pressing the enter key
            console.log(e.key);
            if (e.key === 'Enter') {
                this.props.RootStore.login(this.returnUrl)
            }
        };

        return (
            <div>
                <div style={{ marginTop: '100px' }}>
                    <table style={{ width: '50%', marginRight: 'auto', marginLeft: 'auto' }}>
                        <tr style={{ marginTop: 100 }}>
                            <td >
                                <div>
                                    <Image src={this.logo} size="medium" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            {!this.props.RootStore.loadingCount && <table style={{ marginRight: 'auto', marginLeft: 'auto', width: '80vw', maxWidth: "700px" }}>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ marginTop: "15px", marginBottom: "15px", fontSize: "26px", fontWeight: "bold" }}>
                                            SqT biztosítási ajanlát generálás
                                        </div>
                                        <div className="inputContainer">
                                            <Dropdown
                                                key='SelectedStore'
                                                label='Áruház kiválasztása'
                                                options={this.props.RootStore.Stores.map(s => {
                                                    return {
                                                        key: s.Code,
                                                        text: `(${s.SapCode}) ${s.Name}`
                                                    }
                                                })}
                                                selectedKey={this.props.RootStore.SelectedStore}
                                                onChanged={(option: IDropdownOption) => {
                                                    this.props.RootStore.onValueChanged('SelectedStore', option.key);

                                                    let store = this.props.RootStore.Stores.find(s => s.Code == option.key);
                                                    if (store) {
                                                        this.props.RootStore.SelectedStoreSapCode = store.SapCode;
                                                        if (store.Code == '726') {
                                                            this.props.RootStore.TelesalesMode = true;
                                                        } else {
                                                            this.props.RootStore.TelesalesMode = false;

                                                        }
                                                    }


                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>

                                {this.props.RootStore.TelesalesMode && <tr>
                                    <td style={{ textAlign: 'center' }}>

                                        <div className="inputContainer">
                                            <Dropdown
                                                key='SelectedRole'
                                                label='Szerepkör kiválasztása'
                                                options={[{ key: 1, text: 'Operátor' }, { key: 2, text: 'Adminisztrátor' }]}
                                                selectedKey={this.props.RootStore.SelectedRole}
                                                onChanged={(option: IDropdownOption) => {
                                                    this.props.RootStore.onValueChanged('SelectedRole', option.key);
                                                    if(option.key == 2){
                                                        this.props.RootStore.showDialog=true;
                                                    }



                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                }
                                {
                                    this.props.RootStore.TelesalesMode && this.props.RootStore.SelectedRole == 1 && <tr>
                                        <td style={{ textAlign: 'center' }}>

                                            <div className="inputContainer">
                                                <TextField
                                                    label="Operátor azonosító"
                                                    value={this.props.RootStore.SelectedOperatorNumber}
                                                   
                                                    onChange={(ev, value) => {
                                                        this.props.RootStore.onValueChanged('SelectedOperatorNumber', value);
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td style={{ textAlign: 'center' }}>

                                        {this.props.RootStore.loginFailure &&
                                            // <div style={{ color: 'rgb(255,40,0)' }}>
                                            <MessageBar messageBarType={MessageBarType.error}>{this.props.RootStore.loginFailMessage}</MessageBar>
                                            // </div>
                                        }

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>
                                        <PrimaryButton style={{ marginRight: "4px" }} className="ui button" text="Belépés"
                                            // onKeyDown={handleKeypress}
                                            autoFocus={true}
                                            onClick={() => this.props.RootStore.login(this.returnUrl)} />

                                    </td>
                                </tr>
                            </table>}
                        </tr>
                    </table>
                    {this.renderDialogs()}
                </div>
            </div>
        );
    }
}


