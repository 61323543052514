import * as React from "react";
import { RootStore } from '../stores/RootStore';
import { Icon } from 'semantic-ui-react';


import { inject, observer } from 'mobx-react';
import { withRouter } from "./withRouter";
import { PrimaryButton } from "office-ui-fabric-react";

interface IProps {
    RootStore?: RootStore;

    clicked: any;
    data: any;
}

class OpenItem extends React.Component<IProps> {
    constructor(props: any) {
        super(props);
    }
    btnClickedHandler() {
        this.props.clicked();
    }
    public render() {
        return (
            // <div>
            //     <Icon
            //         name="eye"
            //         color="red"
            //         size="small"
            //         style={{ cursor: 'pointer', color: '#df0000' }}
            //         onClick={() => {
            //             this.props.clicked(this.props.data);
            //         }}
            //     ></Icon>
            // </div>
            <div>

                <div>
                    <PrimaryButton
                        className="ui button"
                        style={{
                            backgroundColor: '#21ba45', height: '20px',
                            padding: '0px',
                            fontSize: '10px',
                            width: '58px',
                            minWidth: '20px',
                            color: 'white',
                        }}
                        text="Megnyitás"
                        onClick={() => this.props.clicked(this.props.data)}
                    />
                </div>
                <div style={{marginTop:'5px'}}>
                    <PrimaryButton
                        className="ui button"
                        style={{
                            backgroundColor: '#21ba45', height: '20px',
                            padding: '0px',
                            fontSize: '10px',
                            width: '92px',
                            minWidth: '20px'
                        }}
                        text="E-mail újraküldése"
                        onClick={() => alert("email")}
                    />
                </div>

            </div>

            // <div onClick={() => {
            //      		this.props.clicked(this.props.data);
            //      	}}>
            //     asd
            // </div>
        );
    }
}

export default withRouter(OpenItem)