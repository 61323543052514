import { IStore } from "../models/IStore";
import { BaseService } from "./base.service";

export class StoreService extends BaseService {
 
    constructor() {
        super("store");
      }

  public async GetStores(): Promise<IStore[]> {
     return await this.get("GetStores");
  }  

  public async GetMyStore(): Promise<IStore[]> {
    return await this.get("GetMyStore");
 }  
}