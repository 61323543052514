import { Location } from 'history';
import React = require('react');
import { useParams } from 'react-router-dom';




export interface WithRouter {
	
	params?: Readonly<any>;
	
}

export const removeWithRouterFromProps = (props: any) => {
	const { location, params, searchParams, setSearchParams, navigate, ...rest } = props;
	return rest;
};

export const withRouter =
	<P extends object>(Component: React.ComponentType<P>): React.FC<P & WithRouter> =>
	(props) => {
		const params = useParams();
		

		return (
			<Component
				params={params}
				
				{...(props as P)}
			/>
		);
	};

