
export class BaseService {
    public baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }


    public get<T>(actionUrl: string) {
        return this.fetch<T>('GET', actionUrl, null, 'json');
    }

    public post<T>(actionUrl: string, postData?: any, type: 'json' | 'form-urlencoded' | 'form-data' = 'json') {
        return this.fetch<T>('POST', actionUrl, postData, type);
    }
    public postVoid<T>(actionUrl: string, postData?: any) {
        return this.fetch<T>('POST', actionUrl, postData, 'json',true);
    }

    downloadFile = (data: any, fileName: string) => {
        var blob = new Blob([data], { type: 'application/octet-binary' });

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);

    };

    base64ToArrayBuffer = (base64: string): ArrayBufferLike => {
        var binary_string = window.atob(base64);
        return this.string2Bin(binary_string);
    };

    string2Bin = (str: string): ArrayBufferLike => {
        var len = str.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        return bytes.buffer;
    };

    protected async fetch<T>(
        method: 'GET' | 'POST',
        actionUrl: string,
        postData?: any,
        type: 'json' | 'form-urlencoded' | 'form-data' = 'json',
        void2:boolean=false
    ): Promise<T> {
        let optionalHeaders: HeadersInit = {};

        let optionalOptions: RequestInit = {};

        // ADFS
        optionalOptions.credentials = 'include';

        let url = `${process.env.SERVER_URL}api/${this.baseUrl}/${actionUrl}`;

        let headers: HeadersInit = {
            Accept: 'application/json'
        };

        let contentType = 'application/json';

        let body = null;
        if (method === 'POST') {
            switch (type) {
                case 'json': {
                    contentType = 'application/json';
                    body = JSON.stringify(postData);

                    break;
                }
                case 'form-data': {
                    let formData = new FormData();
                    for (let key in postData) {
                        formData.append(key, postData[key]);
                    }

                    contentType = undefined; //'multipart/form-data boundary=----WebKitFormBoundaryG7dRBZmhmK5OG3Il';
                    body = formData;

                    break;
                }
                case 'form-urlencoded': {
                    const formData = [];

                    for (var property in postData) {
                        const encodedKey = encodeURIComponent(property);
                        const encodedValue = encodeURIComponent(postData[property]);
                        formData.push(encodedKey + '=' + encodedValue);
                    }

                    contentType = 'application/x-www-form-urlencoded';
                    body = formData.join('&');

                    break;
                }
            }
        }

        if (contentType) {
            headers['Content-Type'] = contentType;
        }

        let options: RequestInit = {
            method: method,
            headers: headers,
            body: body,
            credentials: 'include'
        };
        const response = await fetch(url, options);
        let data = null;
        if(void2){

        }else{
          data=  await response.json();
        }
        return data;
    }

    protected addQueryStringParam(url: string, paramName: string, value: any): string {
        if (!value)
            return url;

        let isQuestionMarkPresent = url && url.indexOf('?') !== -1;
        let separator = isQuestionMarkPresent ? '&' : '?';

        return url + separator + paramName + "=" + value;
    }
}
