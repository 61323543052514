import { inject, observer, Provider } from "mobx-react";
import React = require("react");
import { RouteComponentProps } from "react-router-dom";
import { RootStore } from "../../core/stores/RootStore";
import  SqtReport  from "./components/SqtReport";
import { SqtReportStore } from "./core/stores/SqtReportStore";

interface MatchParams {
  id: string;
}

interface IProps extends RouteComponentProps<MatchParams> {
  RootStore?: RootStore;
}

interface Stores {
  SqtReportStore: SqtReportStore;
}

@inject("RootStore")
@observer
export class SqtReportPage extends React.Component<IProps> {
  private stores: Stores;

  constructor(props: IProps) {
    super(props);

    let store = new SqtReportStore(props.RootStore);
    store.Code = (this.props.match.params as any).code;

    this.stores = {
      SqtReportStore: store
    };

  }

  async componentDidMount() {
    this.stores.SqtReportStore.Init();
  }

  public render() {
    return (
      <Provider {...this.stores}>
        <SqtReport />
      </Provider>
    );
  }
}
