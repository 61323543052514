import { ISqtData } from "../models/ISqtData";
import { BaseService } from "./base.service";

export class SqtOfferService extends BaseService {
 
    constructor() {
        super("SqtReport");
      }

  public async GetOfferData(storeCode: string, code: string): Promise<ISqtData>{
    return await this.get(`GetOfferData?storeCode=${storeCode}&code=${code}`);
  }

  public async GetOfferPdf(sqtData: ISqtData): Promise<any>{
    const response: string = await this.post("GetOfferPdf", sqtData);

    return this.base64ToArrayBuffer(response);
  }
  public async GetArticleData(storeCode: string, code: string): Promise<ISqtData>{
    return await this.get(`GetArticleData?storeCode=${storeCode}&code=${code}`);
  }
  public async GetRecalculatedInsurances(sqtData: any,storeCode): Promise<any>{
    return  await this.post("GetRecalculatedInsurances?storeCode="+storeCode, sqtData);

  }
  
 
}