import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { IDropdownOption } from "office-ui-fabric-react";
import Cookies from "universal-cookie/es6/Cookies";
import { UserService } from "../../../../core/service/UserService";
import { RootStore } from "../../../../core/stores/RootStore";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { AdminService } from "../../../../core/service/AdminService";


export class GPlusDocumentsStore {
    public UserService: UserService = null;
    public AdminService: AdminService = null;

    RootStore: RootStore = null;
    @observable errorMessage: any = null;
    @observable file: any = null;
    @observable uploadedDocuments: Array<any> ;
    @observable columnDef: Array<any>;
    @observable showUploadDialog: boolean = false;
    @observable options: Array<any> = [
        { key: 1, text: 'árlista a havidíjas Garancia Plusz biztosításokról' },
        { key: 2, text: 'GOLD Smart Garancia Plusz előzetes dokumentumok' },
        { key: 3, text: 'PLATINA Smart Garancia Plusz előzetes dokumentumok' },
    ];
    @observable SelectedDocumentType: any = null;




    constructor(rootStore: RootStore) {
        this.UserService = new UserService();
        this.AdminService = new AdminService();
        this.RootStore = rootStore;

        this.RootStore.GoToHomeFn = () => {
            if (window.location.href.toLowerCase().indexOf("changelog") == -1) {
                console.log("nogo")
            }
            else {
                this.RootStore.GoToHome(1);
            }
        };



    }
    getUploadedDocuments = flow(function* (this: GPlusDocumentsStore) {
        this.RootStore.addloading('getProductGroups')

        let temp = yield this.AdminService.GetUploadedDocuments();
        let debug = 1;
        this.uploadedDocuments = temp;
        this.RootStore.removeLoading('getProductGroups')

        //this.RootStore.IsLoading = false;
    });
    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById('upload')).files[0];
        console.log('Kiválasztott file:', this.file)
    }
    showdialog() {
        this.showUploadDialog = true;
    }
    hideDialog() {
        this.showUploadDialog = false;
    }
    GetUploadedDocumentByType = flow(function* (this: GPlusDocumentsStore, value: any) {


        this.RootStore.addloading('sqtReportStore_GetETDocument')

        let file = yield this.AdminService.GetUploadedDocumentByType(value);

        let fileName = '';
        if(value==1){
            fileName="árlista a havidíjas Garancia Plusz biztosításokról.pdf";
        }else if(value==2){
            fileName="GOLD Smart Garancia Plusz előzetes dokumentumok.pdf";
        }else if(value==3){
            fileName="PLATINA Smart Garancia Plusz előzetes dokumentumok.pdf";

        }
        this.AdminService.downloadFile(file, fileName)



        this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
    });
    confirmUpload = flow(function* (this: GPlusDocumentsStore) {
        this.RootStore.addloading('uploadProductGroups')
        let formData: FormData = new FormData();
        this.file = (document.getElementById('upload') as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        formData.append('type', this.SelectedDocumentType);
        let retVAl: any = null;
        retVAl = yield this.AdminService.UploadGPlusDocuments(formData);
        // this.getProductGroups();
        this.showUploadDialog = false;
        this.RootStore.removeLoading('uploadProductGroups')

        this.getUploadedDocuments();
        //     if(retVAl.result){
        //        this.getProductGroups();
        //    }
        //    else{
        //        alert('Hibás Excel fájl!');
        //    }

        //this.RootStore.IsLoading = false;
    });
    GetAdminTablesExcel = flow(function* (this: GPlusDocumentsStore) {

        this.RootStore.addloading('GetAdminTablesExcel')

        let file = yield this.AdminService.GetAdminTablesExcel(4);

        const fileName = `GPlusDocuments-${+new Date()}.xlsx`;
        this.AdminService.downloadFile(file, fileName)



        this.RootStore.removeLoading('GetAdminTablesExcel')
    });
    Init = flow(function* (this: GPlusDocumentsStore) {
        // if (!this.RootStore.SelectedStore) {
        //     this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
        // }

        //this.RootStore.IsLoading = false;
    });
}
