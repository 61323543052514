import { ISqtData } from "../models/ISqtData";
import { ISqtItem } from "../models/ISqtItem";
import { BaseService } from "./base.service";

export class SqtReportService extends BaseService {
 
    constructor() {
        super("SqtReport");
      }

  public async GetOfferData(storeCode: string, code: string): Promise<ISqtData>{
    return await this.get(`GetOfferData?storeCode=${storeCode}&code=${code}`);
  }
  public async GetWawiUsers(storeCode: string): Promise<ISqtData>{
    return await this.get(`GetWawiUsers?storeCode=${storeCode}`);
  }
  public async getOfferById(id: number): Promise<ISqtData>{
    return await this.get(`getOfferById?id=${id}`);
  }
  public async GetOffers(): Promise<ISqtData>{
    return await this.get(`GetOffers`);
  }
  public async GetofferDocuments(id:number): Promise<ISqtData>{
    return await this.get(`GetofferDocuments?id=${id}`);
  }
  public async setInactive(sqtData: ISqtItem): Promise<any>{
    return await this.post("setInactive", sqtData);
  }
  
  public async sendNextStep(sqtData: ISqtData): Promise<any>{
    return await this.post("sendNextStep", sqtData);
  }
  public async saveOffer(sqtData: ISqtData): Promise<ISqtData>{
    return await this.post("saveOffer", sqtData);
  }
  public async GetArticleDataTelesales(code: string,vetalar:number): Promise<ISqtData>{
    return await this.get(`GetArticleDataTelesales?code=${code}&vetalar=${vetalar}`);
  }
  public async GetOfferPdf(sqtData: ISqtData): Promise<any>{
    const response: string = await this.post("GetOfferPdf", sqtData);

    return this.base64ToArrayBuffer(response);
  }
  public async getIpidDocument(value:any): Promise<any>{
    const response: string = await this.get("getIpidDocument?ipidType="+value);

    return this.base64ToArrayBuffer(response);
  }
  
  
  
  public async GetOfferDocumentById(value:any): Promise<any>{
    const response: string = await this.get("GetOfferDocumentById?id="+value);

    return this.base64ToArrayBuffer(response);
  }
  public async GetETDocument(value:any): Promise<any>{
    const response: string = await this.get("GetETDocument?type="+value);

    return this.base64ToArrayBuffer(response);
  }
  public async GetASZFDocument(value:any): Promise<any>{
    const response: string = await this.get("GetASZFDocument?type="+value);

    return this.base64ToArrayBuffer(response);
  }
  

  
 
}