import { observable, action, computed, flow, toJS } from "mobx";

import "isomorphic-fetch";
import "url-search-params-polyfill";
import "url-polyfill";
import { RootStore } from "../../../../core/stores/RootStore";
import { StoreService } from "../../../../core/service/StoreService";
import { IDropdownOption, ITextField } from "office-ui-fabric-react";
import { UserService } from "../../../../core/service/UserService";
import Cookies from "universal-cookie/es6/Cookies";
import { RoutesObj } from "../../../../core/contstants/Routes";
import { ColDef } from "@ag-grid-enterprise/all-modules";
import { SqtReportService } from "../../../../core/service/sqtReportService";
import { ISqtData } from "../../../../core/models/ISqtData";
import { IErrorModel } from "../../../../core/models/IErrorModel";
import { GuaranteeService } from "../../../../core/service/GuaranteeService";
import { IGuarantee } from "../../../../core/models/IGuarantee";

export class SqtReportStore {
  public StoreService: StoreService = null;
  public UserService: UserService = null;
  public SqtReportService: SqtReportService = null;
  public GuaranteeService: GuaranteeService = null;

  public Code: string = null;
  public CodeInput: ITextField = undefined;

  RootStore: RootStore = null;

  public guaranteeTimes: Array<IGuarantee> = [];
  @observable purchaseOptions: Array<any> = [];
  @observable wawiUsers: Array<any> = [];
  @observable ipidDocuments: Array<any> = [];
  @observable etDocuments: Array<any> = [];
  @observable aszfDocuments: Array<any> = [];
  @observable id: number;
  @observable documents: Array<any> = [];


  @observable selectedUserEdit: boolean = false;

  @observable PurchaseOption: any = null;
  @observable selectedUser: any = null;

  @observable errorMessage: any = null;
  @observable errors: Array<IErrorModel> = [];

  @observable sqtData: ISqtData = null;

  @observable isSubmitted: boolean = false;
  @observable showBizonylatError: boolean = false;
  @observable SelectedInsurace: any = null;

  @observable closedPanels: Array<string> = [];

  @observable showWelcomeDialog: boolean = false;
  @observable showMaganSzemelyDialog: boolean = false;
  @observable showGyartasiSzamDialog: boolean = false;
  @observable showAjanlatGeneralasa: boolean = false;
  @observable showWawiData: boolean = false;
  @observable showAjanlatGeneralasaVege: boolean = false;

  @observable gyartasiSzamIndex: number = null;
  @observable SelectedStore: any = null;
  @observable SelectedStoreSapCode: any = null;

  constructor(rootStore: RootStore) {
    this.StoreService = new StoreService();
    this.GuaranteeService = new GuaranteeService();
    this.UserService = new UserService();
    this.SqtReportService = new SqtReportService();
    this.RootStore = rootStore;
    this.showWelcomeDialog = !this.RootStore.TelesalesMode;
  }

  Init = flow(function* (this: SqtReportStore) {
    if (!this.RootStore.CurrentUser) {
      // this.RootStore.RouterStore.gotToRoute(RoutesObj.Login.id, {}, null, false);
    } else {
      this.guaranteeTimes = yield this.GuaranteeService.GetGuaranteeTimes();
      if (this.RootStore.TelesalesMode) {
        this.sqtData = {
          etDocuments: [],
          aszfDocuments: [],
          ipidDocuments: [],
          Id: 0,
          OperatorAzonositoja: '',
          Bizonylatszam: '',
          StatusString: 'Új ajánlat - 100',
          Vezeteknev: '',
          Keresztnev: '',
          TeljesNev: '',
          Adoszam: '',
          SzuletesiHely: '',
          SzuletesiIdo: null,
          AnyjaNeve: '',

          Allando_Iranyitoszam: '',
          Allando_Varos: '',
          Allando_KozteruletNeve: '',
          Allando_KozteruletJellege: '',
          Allando_Hazszam: '',
          Allando_TeljesCim: '',

          Levelezesi_Iranyitoszam: '',
          Levelezesi_Varos: '',
          Levelezesi_KozteruletNeve: '',
          Levelezesi_KozteruletJellege: '',
          Levelezesi_Hazszam: '',
          Levelezesi_TeljesCim: '',

          AllandoCim_LevelezesiCim_Megegyezik: false,

          Telefonszam: '',
          MobilTelefonszam: '',
          Email: '',

          Keltezes: '',
          Status: 0,
          OfferNumber: '',
          Date: null,
          HatralevoNapokSzama: null,
          Termekek: [

            {
              Id: 0,
              Cikkszam: '',
              Inactive: false,
              Osztaly: '',
              Arucsoport: '',
              Gyarto_Tipus: '',
              GyartasiSzam: '',
              VasarlasHelye: 'Media Markt Magyarország Kft.',
              VasarlasDatuma: null,
              Vetelar: null,
              GyartoiGaranciaTartama: null,

              Fedezet_BiztositasiCsomag: '',
              Ajanlatszam: '',
              BiztositasiDij: null,
              BiztositasiGaranciaTartama: null,
              SzaktanacsadoKodja: null,
              Insurances: [],
              SelectedInsurace: null,
              Mennyiseg: null,
              ArucsoportKod: null,
              IsMobile: false,
              Sorszam: null,
              EAN: null,
              AruhazAzonosito: '726',
              Arkategoria: '',
              AjanlatDatuma: '',
              OfferNumberNumber: 1,
            }
          ],

          OriginalQueryResult: [],
          SzaktanacsadoKodja: '',
          InvoiceSalutation: '',
          Betoltott18: false

        };
        this.sqtData.SzaktanacsadoKodja = this.RootStore.SelectedOperatorNumber;
        this.sqtData.OperatorAzonositoja = this.RootStore.SelectedOperatorNumber;

      } else {
        this.sqtData = yield this.SqtReportService.GetOfferData(this.RootStore.SelectedStore, this.Code);
        this.purchaseOptions = [
          {
            value: 1,
            text: 'Saját áruházban vásárolt termék'
          },
          {
            value: 2,
            text: 'Másik áruházban vásárolt termék'
          },
          {
            value: 3,
            text: 'Online vásárolt termék saját áruházi átvétel'
          }
        ];
        if (this.Code) {
          this.sqtData = yield this.onSubmit();
        }
      }


    }
  });

  onSubmit = flow(function* (this: SqtReportStore) {
    if (!this.PurchaseOption) {
      alert('Termék származásának beazonosítása választása kötelező!');
      return;
    }
    if (this.PurchaseOption == 2 && !this.SelectedStore) {
      alert('Áruház választása kötelező!');
      return;
    }
    this.RootStore.addloading('sqtReportStore_onSubmit')

    this.isSubmitted = false;
    let store = '';

    if (this.SelectedStore && this.SelectedStore != this.RootStore.SelectedStore) {
      store = this.SelectedStore;
    } else {
      store = this.RootStore.SelectedStore;

    }
    let noGPlus = false;
    let old = false;
    let ceges = false;
    try {
      this.sqtData = yield this.SqtReportService.GetOfferData(store, this.Code);
      if (!this.sqtData.Termekek || this.sqtData.Termekek.length == 0) {
        this.sqtData = null;
        noGPlus = true;
        alert('A termékre Garancia Plusz nem igényelhető!');
      } else {

        if (this.sqtData.InvoiceSalutation == "CÉGES VÁSÁRLÓ") {
          ceges = true;
          this.sqtData = null;
          alert("Media Markt Garancia Plusz biztosítás csak fogyasztó által vásárolt készülékre, magánszemély szerződőként vehető igénybe.")
        } else
          if (this.sqtData.Termekek && this.sqtData.Termekek[0].VasarlasDatuma) {
            let date = new Date();
            // date.setDate(date.getDate() - 30)
            let Difference_In_Time =
              date.getTime() - new Date(this.sqtData.Termekek[0].VasarlasDatuma).getTime();

            // Calculating the no. of days between
            // two dates
            let Difference_In_Days =
              Math.round
                (Difference_In_Time / (1000 * 3600 * 24));
            if (Difference_In_Days > 30) {
              old = true;
              this.sqtData = null;
              alert("Az Összesítő 30 napnál régebbi, így nem lehet rá biztosítást kötni!")
            }

          }
        // if (this.sqtData.Adoszam) {
        //   this.showMaganSzemelyDialog = true;
        // }
      }
    }
    catch (ex) {
      this.showBizonylatError = true;
    }
    if (!this.sqtData && !noGPlus && !old && !ceges) {
      this.showBizonylatError = true;
    } else {
      this.showBizonylatError = false;
      this.wawiUsers = yield this.SqtReportService.GetWawiUsers(this.RootStore.SelectedStore);

      //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    }

    this.RootStore.removeLoading('sqtReportStore_onSubmit')
  });
  addnewItem() {
    if (this.sqtData.Termekek.length > 0) {

      this.sqtData.Termekek.push(
        {
          Cikkszam: '',
          Id: 0,
          Osztaly: '',
          Arucsoport: '',
          Gyarto_Tipus: '',
          GyartasiSzam: '',
          VasarlasHelye: 'Media Markt Magyarország Kft.',
          VasarlasDatuma: this.sqtData.Termekek[0].VasarlasDatuma,
          Vetelar: null,
          GyartoiGaranciaTartama: null,

          Fedezet_BiztositasiCsomag: '',
          Ajanlatszam: '',
          BiztositasiDij: null,
          BiztositasiGaranciaTartama: null,
          SzaktanacsadoKodja: null,
          Insurances: [],
          SelectedInsurace: null,
          Mennyiseg: null,
          ArucsoportKod: null,
          IsMobile: false,
          Sorszam: null,
          EAN: null,
          AruhazAzonosito: '726',
          Arkategoria: '',
          AjanlatDatuma: '',
          OfferNumberNumber: null,
          Inactive: false,

        }



      );
    } else {
      this.sqtData.Termekek.push(
        {
          Cikkszam: '',
          Id: 0,
          Inactive: false,
          Osztaly: '',
          Arucsoport: '',
          Gyarto_Tipus: '',
          GyartasiSzam: '',
          VasarlasHelye: 'Media Markt Magyarország Kft.',
          VasarlasDatuma: null,
          Vetelar: null,
          GyartoiGaranciaTartama: null,

          Fedezet_BiztositasiCsomag: '',
          Ajanlatszam: '',
          BiztositasiDij: null,
          BiztositasiGaranciaTartama: null,
          SzaktanacsadoKodja: null,
          Insurances: [],
          SelectedInsurace: null,
          Mennyiseg: null,
          ArucsoportKod: null,
          IsMobile: false,
          Sorszam: null,
          EAN: null,
          AruhazAzonosito: '726',
          Arkategoria: '',
          AjanlatDatuma: '',
          OfferNumberNumber: null
        }



      );
    }
  }
  @action setActiveInsurance = (termekIndex, insuranceIndex) => {
    for (let i = 0; i < this.sqtData.Termekek[termekIndex].Insurances.length; i++) {
      if (i == insuranceIndex) {
        this.sqtData.Termekek[termekIndex].Insurances[i].Selected = !this.sqtData.Termekek[termekIndex].Insurances[i].Selected;
        if (this.sqtData.Termekek[termekIndex].Insurances[i].Selected) {
          this.sqtData.Termekek[termekIndex].SelectedInsurace = this.sqtData.Termekek[termekIndex].Insurances[i];
          this.generateOfferNumber();
        } else {
          this.sqtData.Termekek[termekIndex].SelectedInsurace = null;
          this.sqtData.Termekek[termekIndex].Ajanlatszam = null;
          this.generateOfferNumber();

        }
      } else
        this.sqtData.Termekek[termekIndex].Insurances[i].Selected = false;

    }


    this.sqtData = {
      ...this.sqtData
    }
  }
  get getSelectedInsurance() {
    let retVal = false;
    if (this.sqtData && this.sqtData.Termekek) {
      for (let i = 0; i < this.sqtData.Termekek.length; i++) {

        retVal = this.sqtData.Termekek[i].SelectedInsurace;
        if (retVal) {
          break;
        }
      }
    }



    return retVal;
  }
  getRandomCHar(length) {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  getRandomNumber(length) {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  generateOfferNumber() {

    let nowDate = new Date();
    let index = 1;
    if (this.RootStore.TelesalesMode) {
      for (let i = 0; i < this.sqtData.Termekek.length; i++) {


        if (this.sqtData.Termekek[i].SelectedInsurace) {
          let termekDate = new Date(this.sqtData.Termekek[i].VasarlasDatuma)
          let number = null;
          //this.sqtData.Termekek[i].Sorszam.length>1?this.sqtData.Termekek[i].Sorszam.toString(): '0' + (this.sqtData.Termekek[i].Sorszam).toString();
          number = nowDate.getFullYear().toString().slice(nowDate.getFullYear().toString().length - 2, nowDate.getFullYear().toString().length) +
            String(nowDate.getMonth() + 1).padStart(2, '0') + String(nowDate.getDate()).padStart(2, '0') + '/' + String(this.sqtData.Termekek[i].OfferNumberNumber).padStart(3, '0') + '/' +
            this.getRandomNumber(2) + this.getRandomCHar(2);
          this.sqtData.Termekek[i].Ajanlatszam = number;
          index++;
        }

      }
    } else {
      for (let i = 0; i < this.sqtData.Termekek.length; i++) {


        if (this.sqtData.Termekek[i].SelectedInsurace) {
          let termekDate = new Date(this.sqtData.Termekek[i].VasarlasDatuma)
          let number = null;
          if (this.PurchaseOption == 1 && (nowDate.getDate() == termekDate.getDate() && termekDate.getMonth() == nowDate.getMonth() && nowDate.getFullYear() == termekDate.getFullYear())) {
            number = '5';
          } else if (this.PurchaseOption == 1) {
            number = '6';
          } else if (this.PurchaseOption == 2) {
            number = '7';
          } else if (this.PurchaseOption == 3) {
            number = '8';
          }
          number += this.SelectedStoreSapCode != "" && this.SelectedStoreSapCode != null ? this.SelectedStoreSapCode.slice(1) :
            this.RootStore.SelectedStoreSapCode.slice(1);
          number += this.sqtData.Bizonylatszam;
          number += this.sqtData.Termekek[i].Cikkszam.toString();
          if (this.sqtData.SzaktanacsadoKodja)
            number += String(this.sqtData.SzaktanacsadoKodja).padStart(5, '0')
          number += String(this.sqtData.Termekek[i].Sorszam).padStart(2, '0');//this.sqtData.Termekek[i].Sorszam.length>1?this.sqtData.Termekek[i].Sorszam.toString(): '0' + (this.sqtData.Termekek[i].Sorszam).toString();
          this.sqtData.Termekek[i].Ajanlatszam = number;
          index++;
        }

      }
    }

    // let number = null;
    // if (this.PurchaseOption == 1) {
    //   number = '5';
    // } else if (this.PurchaseOption == 1) {
    //   number = '6';
    // } else if (this.PurchaseOption == 2) {
    //   number = '7';
    // } else if (this.PurchaseOption == 3) {
    //   number = '8';
    // }
    // number += this.RootStore.SelectedStoreSapCode.slice(1);
    // number += this.sqtData.Bizonylatszam;
    // number += this.sqtData.Termekek[termekIndex].Cikkszam.toString();


    // this.sqtData.Termekek[termekIndex].Ajanlatszam = number;



  }
  getOfferById = flow(function* (this: SqtReportStore, id: number) {
    this.RootStore.addloading('getOfferById')

    this.isSubmitted = false;

    try {
      let result = yield this.SqtReportService.getOfferById(id);

      if (result.Termekek && result.Termekek.length > 0) {
        for (let index = 0; index < result.Termekek.length; index++) {
          const element = result.Termekek[index];
          if (result.Termekek[index].VasarlasDatuma) {
            result.Termekek[index].VasarlasDatuma = new Date(result.Termekek[index].VasarlasDatuma);
          }

        }
        this.documents = yield this.SqtReportService.GetofferDocuments(id);
      }
      this.sqtData = result;
      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('saveOffer')
  });
  saveOffer = flow(function* (this: SqtReportStore) {
    this.RootStore.addloading('saveOffer')

    this.isSubmitted = false;

    try {
      let retVal = yield this.SqtReportService.saveOffer(this.sqtData);



      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('saveOffer')
  });
  GetOfferDocumentById = flow(function* (this: SqtReportStore, id: number) {
    this.RootStore.addloading('sendNextStep')

    this.isSubmitted = false;

    try {

      let retVal = yield this.SqtReportService.GetOfferDocumentById(id);

      const fileName = `Ajánlat_${this.sqtData.OfferNumber}.pdf`;
      this.SqtReportService.downloadFile(retVal, fileName)


      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('saveOffer')
  });
  sendNextStep = flow(function* (this: SqtReportStore, status: number, download: boolean) {
    if (this.errors.length) {
      this.isSubmitted = true;
      return;
    }
    const gyartasiSzamIndex = this.sqtData.Termekek.findIndex(s => s.SelectedInsurace && !s.GyartasiSzam && !s.IsMobile);

    if (gyartasiSzamIndex > -1) {
      this.gyartasiSzamIndex = gyartasiSzamIndex;
      this.showGyartasiSzamDialog = true;
      return;
    }

    let azon = null;
    for (let i = 0; i < this.sqtData.Termekek.length; i++) {
      if (i == 0) {
        azon = this.sqtData.Termekek[i].AruhazAzonosito;

      } else {
        if (azon != this.sqtData.Termekek[i].AruhazAzonosito) {
          alert('Az összes terméknél csak ugyanaz az áruház lehet kiválasztva!');
          return;
        }
      }
    }





    this.ipidDocuments = [];
    this.etDocuments = [];
    this.aszfDocuments = [];
    let hasSilverEt = false;
    let hasGoldEt = false;
    let hasSilverAszf = false;
    let hasGoldAszf = false;
    for (let i = 0; i < this.sqtData.Termekek.length; i++) {
      if (this.sqtData.Termekek[i].SelectedInsurace) {
        if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER" && !hasSilverEt && !hasSilverAszf) {
          this.etDocuments.push({
            Value: 1,
            Name: "SILVER ES"
          })
          this.aszfDocuments.push({
            Value: 1,
            Name: "SILVER ASZF-S"
          })
          hasSilverEt = true;
          hasSilverAszf = true;
        }
        if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD" && !hasGoldEt && !hasGoldAszf) {
          this.etDocuments.push({
            Value: 2,
            Name: "GOLD EG"
          })
          this.aszfDocuments.push({
            Value: 2,
            Name: "GOLD ASZF-G"
          })
          hasGoldEt = true;
          hasGoldAszf = true;

        }
      }
      if (this.sqtData.Termekek[i].SelectedInsurace &&
        (
          (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER" && (this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 1 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 2 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 3 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 4)

          ) ||
          (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD" && (this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure == 1 || this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure == 5)

          )
        )

      ) {
        let index = this.ipidDocuments.findIndex(s => s.Period == this.sqtData.Termekek[i].SelectedInsurace.Period && s.InsuranceType == this.sqtData.Termekek[i].SelectedInsurace.InsuranceType);
        if (index == -1) {
          let period = 0;
          if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER")
            period = this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure;
          else if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD")
            period = this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure;

          this.ipidDocuments.push(
            {
              Period: period,
              InsuranceType: this.sqtData.Termekek[i].SelectedInsurace.InsuranceType,
              Name: (i + 1).toString() + '. ' + this.sqtData.Termekek[i].Gyarto_Tipus + ' - ' + this.sqtData.Termekek[i].SelectedInsurace.InsuranceType + " " + period + " év" + " " + this.sqtData.Termekek[i].SelectedInsurace.InsuranceType.charAt(0) + " " + period,
              Value: this.sqtData.Termekek[i].SelectedInsurace.InsuranceType.charAt(0) + "+" + period
            }
          )
        }
      }

    }
    this.sqtData.etDocuments=this.etDocuments;
    this.sqtData.aszfDocuments=this.aszfDocuments;
    this.sqtData.ipidDocuments=this.ipidDocuments;




    if (!download) {
      this.showAjanlatGeneralasa = true;
      return;
    } else {
      this.showAjanlatGeneralasa = false;
    }







    try {
      this.RootStore.addloading('sendNextStep')

      if (!this.sqtData || !this.sqtData.Termekek) {
        this.showBizonylatError = true;
      } else {
        this.showBizonylatError = false;
        this.sqtData.Status = status;
        let retVal = yield this.SqtReportService.sendNextStep(this.sqtData);
        this.documents = yield this.SqtReportService.GetofferDocuments(retVal);

        // this.sqtData= yield this.SqtReportService.getOfferById()
        this.RootStore.removeLoading('sendNextStep')

      }




      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
      this.RootStore.removeLoading('sendNextStep')

    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

  });
  setInactive = flow(function* (this: SqtReportStore, index: number) {

    this.isSubmitted = false;

    if (confirm("Biztosan inaktiválni akarod ezt a terméket?")) {



      try {
        this.RootStore.addloading('onGetArticleDataTelesales')



        let retVal = yield this.SqtReportService.setInactive(this.sqtData.Termekek[index]);

        this.sqtData = yield this.SqtReportService.getOfferById(this.sqtData.Id);

        // if (this.sqtData.Adoszam) {
        //   this.showMaganSzemelyDialog = true;
        // }
      }
      catch (ex) {
        console.log(ex)
      }
      // if (!this.sqtData) {
      //   this.showBizonylatError = true;
      // } else {
      //   this.showBizonylatError = false;

      //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
      // }

      this.RootStore.removeLoading('onGetArticleDataTelesales')
    }
  });
  onGetArticleDataTelesales = flow(function* (this: SqtReportStore, index: number) {

    this.isSubmitted = false;


    if (this.sqtData.Termekek[index].VasarlasDatuma) {
      let date = new Date();
      // date.setDate(date.getDate() - 30)
      let Difference_In_Time =
        date.getTime() - new Date(this.sqtData.Termekek[index].VasarlasDatuma).getTime();

      // Calculating the no. of days between
      // two dates
      let Difference_In_Days =
        Math.round
          (Difference_In_Time / (1000 * 3600 * 24));
      if (Difference_In_Days > 30) {
        alert("A Vásárlás dátuma nem lehet 30 napnál régebbi!");
        return;
      }
    }



    try {
      this.RootStore.addloading('onGetArticleDataTelesales')



      let retVal = yield this.SqtReportService.GetArticleDataTelesales(this.sqtData.Termekek[index].Cikkszam, Number(this.sqtData.Termekek[index].Vetelar));

      if (retVal != null) {
        let date = this.sqtData.Termekek[index].VasarlasDatuma;
        let azon = this.sqtData.Termekek[index].AruhazAzonosito;
        this.sqtData.Termekek[index] = retVal;
        this.sqtData.Termekek[index].VasarlasDatuma = date;
        this.sqtData.Termekek[index].VasarlasHelye = 'Media Markt Magyarország Kft.';
        this.sqtData.Termekek[index].AruhazAzonosito = azon;

      }

      // if (this.sqtData.Adoszam) {
      //   this.showMaganSzemelyDialog = true;
      // }
    }
    catch (ex) {
      console.log(ex)
    }
    // if (!this.sqtData) {
    //   this.showBizonylatError = true;
    // } else {
    //   this.showBizonylatError = false;

    //   //this.RootStore.RouterStore.history.push(`${this.RootStore.RouterStore.selectedRouteId}/${this.Code}`);
    // }

    this.RootStore.removeLoading('onGetArticleDataTelesales')
  });
  onReportSubmit = flow(function* (this: SqtReportStore, download: boolean) {
    if (this.errors.length) {
      this.isSubmitted = true;
      return;
    }

    if (new Date(this.sqtData.SzuletesiIdo.getFullYear() + 18, this.sqtData.SzuletesiIdo.getMonth(), this.sqtData.SzuletesiIdo.getDate()) >= new Date()) {
      alert("Legalább 18 éves kor megléte kötelező!");
      return;
    }

    const gyartasiSzamIndex = this.sqtData.Termekek.findIndex(s => s.SelectedInsurace && !s.GyartasiSzam && !s.IsMobile);

    if (gyartasiSzamIndex > -1) {
      this.gyartasiSzamIndex = gyartasiSzamIndex;
      this.showGyartasiSzamDialog = true;
      return;
    }
    this.ipidDocuments = [];
    this.etDocuments = [];
    this.aszfDocuments = [];
    let hasSilverEt = false;
    let hasGoldEt = false;
    let hasSilverAszf = false;
    let hasGoldAszf = false;
    for (let i = 0; i < this.sqtData.Termekek.length; i++) {
      if (this.sqtData.Termekek[i].SelectedInsurace) {
        if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER" && !hasSilverEt && !hasSilverAszf) {
          this.etDocuments.push({
            Value: 1,
            Name: "SILVER ES"
          })
          this.aszfDocuments.push({
            Value: 1,
            Name: "SILVER ASZF-S"
          })
          hasSilverEt = true;
          hasSilverAszf = true;
        }
        if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD" && !hasGoldEt && !hasGoldAszf) {
          this.etDocuments.push({
            Value: 2,
            Name: "GOLD EG"
          })
          this.aszfDocuments.push({
            Value: 2,
            Name: "GOLD ASZF-G"
          })
          hasGoldEt = true;
          hasGoldAszf = true;

        }
      }
      if (this.sqtData.Termekek[i].SelectedInsurace &&
        (
          (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER" && (this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 1 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 2 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 3 || this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure == 4)

          ) ||
          (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD" && (this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure == 1 || this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure == 5)

          )
        )

      ) {
        let index = this.ipidDocuments.findIndex(s => s.Period == this.sqtData.Termekek[i].SelectedInsurace.Period && s.InsuranceType == this.sqtData.Termekek[i].SelectedInsurace.InsuranceType);
        if (index == -1) {
          let period = 0;
          if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "SILVER")
            period = this.sqtData.Termekek[i].SelectedInsurace.TechnicalFailure;
          else if (this.sqtData.Termekek[i].SelectedInsurace.InsuranceType == "GOLD")
            period = this.sqtData.Termekek[i].SelectedInsurace.AccidentalFailure;

          this.ipidDocuments.push(
            {
              Period: period,
              InsuranceType: this.sqtData.Termekek[i].SelectedInsurace.InsuranceType,
              Name: (i + 1).toString() + '. ' + this.sqtData.Termekek[i].Gyarto_Tipus + ' - ' + this.sqtData.Termekek[i].SelectedInsurace.InsuranceType + " " + period + " év" + " " + this.sqtData.Termekek[i].SelectedInsurace.InsuranceType.charAt(0) + " " + period,
              Value: this.sqtData.Termekek[i].SelectedInsurace.InsuranceType.charAt(0) + "+" + period
            }
          )
        }
      }

    }





    if (!download) {
      this.showAjanlatGeneralasa = true;
      return;
    } else {
      this.showAjanlatGeneralasa = false;
    }

    this.RootStore.addloading('sqtReportStore_onReportSubmit')

    let file = yield this.SqtReportService.GetOfferPdf(this.sqtData);

    const fileName = `SqT_ajánlat-${+new Date()}.pdf`;
    this.SqtReportService.downloadFile(file, fileName)

    this.isSubmitted = false;
    // this.showAjanlatGeneralasaVege=!this.showAjanlatGeneralasaVege;

    if (!this.showAjanlatGeneralasaVege) {
      this.showAjanlatGeneralasaVege = true;
    }

    this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
  });


  isMobile() {
    // let routeParts = this.router.url.split('/');
    // let routeUrl = routeParts[1] + '/' + routeParts[2];
    // let routerConfig = _.find(this.router.config, (conf: any) => { return conf.path == routeUrl });
    // let isMobile = routerConfig != null ? routerConfig.data.isMobileCompatible : false;
    //
    return (document && document.body && document.body.offsetWidth < 900);
  }

  getAllDocuments = flow(function* (this: SqtReportStore,) {


    this.RootStore.addloading('sqtReportStore_getAllDocuments')

    for (let index = 0; index < this.ipidDocuments.length; index++) {
      const element = this.ipidDocuments[index];
      this.ipidDocuments[index].Downloaded = true;
      let file = yield this.SqtReportService.getIpidDocument(element.Value);

      const fileName = `${element.Name}.pdf`;
      this.SqtReportService.downloadFile(file, fileName)

    }
    for (let index = 0; index < this.etDocuments.length; index++) {
      const element = this.etDocuments[index];
      this.etDocuments[index].Downloaded = true;

      let file = yield this.SqtReportService.GetETDocument(element.Value);

      const fileName = `${element.Name}.pdf`;
      this.SqtReportService.downloadFile(file, fileName)

    }

    for (let index = 0; index < this.aszfDocuments.length; index++) {
      const element = this.aszfDocuments[index];
      this.aszfDocuments[index].Downloaded = true;

      let file = yield this.SqtReportService.GetASZFDocument(element.Value);

      const fileName = `${element.Name}.pdf`;
      this.SqtReportService.downloadFile(file, fileName)

    }


    this.RootStore.removeLoading('sqtReportStore_getAllDocuments')
  });


  getIpidDocument = flow(function* (this: SqtReportStore, value: any, name: any) {


    this.RootStore.addloading('sqtReportStore_getIpidDocument')

    let file = yield this.SqtReportService.getIpidDocument(value);

    const fileName = `${name}.pdf`;
    this.SqtReportService.downloadFile(file, fileName)

    this.isSubmitted = false;


    this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
  });
  GetETDocument = flow(function* (this: SqtReportStore, value: any, name: any) {


    this.RootStore.addloading('sqtReportStore_GetETDocument')

    let file = yield this.SqtReportService.GetETDocument(value);

    const fileName = `${name}.pdf`;
    this.SqtReportService.downloadFile(file, fileName)

    this.isSubmitted = false;


    this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
  });
  GetASZFDocument = flow(function* (this: SqtReportStore, value: any, name: any) {


    this.RootStore.addloading('sqtReportStore_GetASZFDocument')

    let file = yield this.SqtReportService.GetASZFDocument(value);

    const fileName = `${name}.pdf`;
    this.SqtReportService.downloadFile(file, fileName)

    this.isSubmitted = false;


    this.RootStore.removeLoading('sqtReportStore_onReportSubmit')
  });
  GetOfferPdf

  public getFullAddress(isAllando: boolean) {
    const addressParts = ['Iranyitoszam', 'Varos', 'KozteruletNeve', 'KozteruletJellege', 'Hazszam'];

    let address = '';
    addressParts.forEach(element => {
      const property = (isAllando ? 'Allando_' : 'Levelezesi_') + element;
      if (this.sqtData[property]) {
        address += this.sqtData[property] + " ";
      }
    });

    return address.trim();
  }

  @action hideDialog = (dialog: string) => {
    this[dialog] = false;

    // if (dialog == 'showWelcomeDialog') {
    //   localStorage['hideWelcomeDialog'] = 'true';
    // }
  }

  @action onNewQuery = () => {
    this.Code = '';
    this.sqtData = null;
    this.errors = [];
    this.PurchaseOption = null;
    this.SelectedStore = null;
    this.SelectedStoreSapCode = "";
    this.showWelcomeDialog = true;
  }

  @action getErrorMessage = (property: string, label: string, object?: any) => {

    const index = this.errors.findIndex(p => p.key == property);
    let message = `Hiányzó: ${label}`;

    switch (property) {
      case 'Email':
        if (!object[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (!object[property]
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            message = `${label} formátuma nem megfelelő!`;
            if (index == -1) {
              this.errors.push({ key: property, value: message });
            } else {
              this.errors[index].value = message;
            }

            return this.isSubmitted ? message : null;
          } else {
            if (index > -1) {
              let errors = [...this.errors];
              errors.splice(index, 1);

              this.errors = errors;

              return null;
            }
          }
        }
        break;
      default:
        if (!object[property]) {
          if (index == -1) {
            this.errors.push({ key: property, value: message });
          }

          return this.isSubmitted ? message : null;
        } else {
          if (index > -1) {
            let errors = [...this.errors];
            errors.splice(index, 1);

            this.errors = errors;

            return null;
          }
        }
        break;
    }
  }

  @action onClosePanelClicked = (panelId: string) => {
    const index = this.closedPanels.indexOf(panelId);
    if (index == -1) {
      this.closedPanels.push(panelId);
    } else {
      const closedPanels = [...this.closedPanels];
      closedPanels.splice(index, 1);
      this.closedPanels = closedPanels;
    }
  }

  @action onValueChanged = (property: string, value: any, object?: any) => {
    if (object) {
      object[property] = value;
    } else {
      this[property] = value;
    }
  }

  @action onSelectChanged = (property: string, isChecked: boolean, object?: any) => {
    if (object) {
      object[property] = isChecked;
    } else {
      this[property] = isChecked;
    }
  }

  @action onCheckBoxSelectChanged = (property: string, code: string, isChecked: boolean) => {
    const index = this[property].indexOf(code);

    if (isChecked) {
      if (index == -1) {
        this[property].push(code);
      }
    } else {
      if (index > -1) {
        this[property].splice(index, 1);
      }
    }
  }

  @action onSelectAll = (property, values, key) => {
    if (this[property].length) {
      this[property] = [];
    } else {
      this[property] = values.map(s => s[key]);
    }
  }
}
