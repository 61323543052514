import * as React from "react";
import { render } from "react-dom";
import { Provider } from "mobx-react";
import "./index.scss";

import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { RootStore } from "./core/stores/RootStore";
import { Layout } from "./components/Layout/Layout";
// import { IconName } from "react-icons/fa";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry, SetFilterModule } from "@ag-grid-enterprise/all-modules";


LicenseManager.setLicenseKey(

  "CompanyName=ShareLine Informatikai Szolgltat Korltolt Felelssg Tr,LicensedGroup=Shareline,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-024512,ExpiryDate=5_March_2023_[v2]_MTY3Nzk3NDQwMDAwMA==ebc757d4d7be09c71b2e72cdb2c2eb9e"

);

ModuleRegistry.registerModules([ClientSideRowModelModule, SetFilterModule, InfiniteRowModelModule]);

const rootEl = document.getElementById("root");

const stores = {
  RootStore: new RootStore(),
  /*   appStore: new AppStore(),
  userInRolesStore: new UserInRolesStore(),
  resourceStore: resourceStore,
  departmentStore: new DepartmentStore(), */
};

const element = (
  <Provider {...stores}>
    <Layout />
  </Provider>
);

initializeIcons();

render(element, rootEl);
